import React from 'react'
import NotificatonSettings from '../../../../../components/user/profile/link-pages-account/settings-page/notification/NotificatonSettings'

const NotificationSettingsPage = () => {
    return (
        <div id='notofocation-settings-page'>
            <NotificatonSettings />
        </div>
    )
}

export default NotificationSettingsPage
