import React from 'react'
import { Form } from 'react-bootstrap'
import "./disability-status-form.scss"
const DisabilityStatusForm = ({ info, setInfo }) => {


    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }



    return (
        <div id='disability-status-form'>
            <Form>
                <Form.Group>
                    <Form.Label>Disability Status</Form.Label>
                    <Form.Select
                        name="status"
                        onChange={handleChange}
                        value={info?.status}
                    >
                        <option value="Hearing">Hearing</option>
                        <option value="Vision">Vision</option>
                        <option value="Intellectual">Intellectual</option>
                        <option value="Physical">Physical</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group >
                    <Form.Label>Percentage</Form.Label>
                    <div className='form-control-item'>
                        <Form.Control
                            type='number'
                            onChange={handleChange}
                            name="percentage"
                            value={info?.percentage}
                        />
                        <span>%</span>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default DisabilityStatusForm
