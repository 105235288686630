import React from 'react'
import MainDoctorCard from '../../../components/user/main-doctor-card/MainDoctorCard'
import { useParams } from 'react-router-dom'

const DocDetailPage = () => {

    const { id } = useParams()


    return (
        <div id='doc-detail-page'>
            <MainDoctorCard id={id} />
        </div>
    )
}

export default DocDetailPage
