import React, { useEffect, useRef, useState } from 'react'
import "./chat-box.scss"

import { IoIosSend } from "react-icons/io";
import { BiSolidPlusCircle } from "react-icons/bi";
import { SlCamera } from "react-icons/sl";
import { HiOutlinePhoto } from "react-icons/hi2";
import { IoDocumentTextOutline } from "react-icons/io5";


import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
const ChatBox = () => {

    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);


    const handleSendMessage = (e) => {
        e.preventDefault();
        if (inputMessage === "") return;
        setMessages([...messages, inputMessage]);
        setInputMessage("");
    };

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div id='chat-box' className='box-style'>
            <div className="left">Merhaba, Zupsie'ye hos geldiniz </div>
            <div className="right">Merhaba harikasiniz!</div>
            <div className="left">Tesekkur Ederiz </div>
            {messages.map((message, index) => (
                <div key={index} className="right">{message}</div>
            ))}
            <div className="bottom">
                <BiSolidPlusCircle onClick={handleDropdownToggle} />
                <DropdownButton id="dropdown-button-drop-up"
                    className='dropdown-button'
                    show={showDropdown}
                    drop='up-centered'
                    ref={dropdownRef}
                >
                    <Dropdown.Item as="button"><SlCamera /> Camera</Dropdown.Item>
                    <Dropdown.Item as="button"><HiOutlinePhoto /> Photo</Dropdown.Item>
                    <Dropdown.Item as="button"><IoDocumentTextOutline /> Document</Dropdown.Item>
                </DropdownButton>
                <form onSubmit={handleSendMessage}>
                    <Form.Control
                        type='text'
                        placeholder='Type your message here...'
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                    />
                </form>
                <Button type='submit' onClick={handleSendMessage}><IoIosSend /></Button>
            </div>
        </div>
    )
}

export default ChatBox
