import React, { useEffect, useState } from 'react'
import Prescriptions from '../../../../../components/user/profile/link-pages/health-profile/prescriptions/Prescriptions'
import { getPrescriptionsInfo } from '../../../../../api/user-services/healt-info-services'

const PrescriptionsPage = () => {


    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getPrescriptionsInfo()
            setData(resp.data)
        } catch (error) {
            console.log("prescriptions cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);


    return (
        <div>
            <Prescriptions data={data} />
        </div>
    )
}

export default PrescriptionsPage
