import React from 'react'
import "./discover-unit-card.scss"
import { IoNewspaperOutline } from 'react-icons/io5'
const DiscoverUnitCard = ({ item }) => {

    console.log(item);
    return (
        <div id='discover-unit-card' className='box-style'>
            <div className="discover-unit-card-img">
                <img src={item?.categoryItemImage} alt="" />
            </div>
            <div className="discover-unit-card-content">
                <span>{item?.categoryName}</span>
            </div>
        </div>

    )
}

export default DiscoverUnitCard
