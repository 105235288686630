import React from 'react'
import ProfileContainer from '../../../components/containers/profile/ProfileContainer'

const ProfilePage = () => {
    return (
        <div id='profile-page'>
            <ProfileContainer />
        </div>
    )
}

export default ProfilePage
