import React from 'react'
import Plans from '../../../../components/user/profile/plans/Plans'

const PlansPage = () => {
    return (
        <div>
            <Plans />
        </div>
    )
}

export default PlansPage
