import React, { useEffect, useState } from 'react'
import "../habits-forms.scss"
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'
import { postExercise, updateExercise } from '../../../../../../../api/user-services/healt-info-services'
const ExerciseForm = ({ data }) => {

    const navigate = useNavigate()
    const [info, setInfo] = useState(

        {
            id: "",
            exerciseType: "",
            frequency: "",
            duration: "",
            username: JSON.parse(sessionStorage.getItem("user")),
            regular: true
        }

    )

    const handleChance = (e) => {
        const { name, checked, value } = e.target;
        const newValue = name === 'regular' ? checked : value;

        setInfo({ ...info, [name]: newValue });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {

            if (data?.[0]?.id) {
                await updateExercise(info)
            } else {
                await postExercise(info)

            }

            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }



    useEffect(() => {
        if (data) {
            setInfo({
                ...info,
                id: data?.[0]?.id,
                exerciseType: data?.[0]?.exerciseType,
                frequency: data?.[0]?.frequency,
                duration: data?.[0]?.duration,
                regular: data?.[0]?.regular
            });
        }
    }, [data]);


    return (
        <div id='exercise-form' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group className='d-flex align-items-center justify-content-between'>
                    <Form.Label>Regular</Form.Label>
                    <Form.Switch
                        name='regular'
                        onChange={handleChance}
                        checked={info?.regular || false}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Exercise Type</Form.Label>
                    <Form.Control
                        type='text'
                        onChange={handleChance}
                        name='exerciseType'
                        placeholder={data?.[0]?.exerciseType || "exercise type"}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Frequency</Form.Label>
                    <Form.Control
                        type='text'
                        onChange={handleChance}
                        name='frequency'
                        placeholder={data?.[0]?.frequency || "frequency"}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Duration</Form.Label>
                    <Form.Control
                        type='text'
                        onChange={handleChance}
                        name='duration'
                        placeholder={data?.[0]?.duration || "type a minute or hour"}
                    />
                </Form.Group>

                <Button className='button' type='submit' >Save</Button>
            </Form>
        </div>
    )
}

export default ExerciseForm
