import React from 'react'
import "./support-email.scss"
import { Form } from 'react-bootstrap'
const SupportEmail = () => {
    return (
        <div id='support-email'>
            <Form>
                <Form.Group>
                    <Form.Control type='text' as={"textarea"} placeholder='Write your message here...' />
                </Form.Group>
                <button className="button w-100">Send</button>
            </Form>
        </div>
    )
}

export default SupportEmail
