import React from 'react'
import AddPrescriptions from '../../../../../components/user/profile/link-pages/health-profile/prescriptions/add-prescriptions/AddPrescriptions'

const AddPrescriptionsPage = () => {
    return (
        <div>
            <AddPrescriptions />
        </div>
    )
}

export default AddPrescriptionsPage
