
import "./doctors.scss"
import DoctorCardMobile from '../../user/doctor-card-mobile/DoctorCardMobile'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setSelectedDoctor } from "../../../store/doctor-slice/doctor-slice"
const Doctors = ({ popularDocs }) => {

    const dispatch = useDispatch()

    const selectDoctor = (docname) => {
        dispatch(setSelectedDoctor(docname))
    }


    return (
        <div id='doctors'>
            <span>Popular Doctors</span>
            <div className="doctors-content">
                {
                    popularDocs?.map((docs) => (

                        <Link to={`/user/doctor/${docs?.firstname + " " + docs?.lastname}`} key={docs.id}  >
                            <div onClick={() => selectDoctor(docs?.firstname + " " + docs?.lastname)}>
                                <DoctorCardMobile docs={docs} />
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default Doctors
