import React, { useEffect, useState } from 'react'
import AddLaboratoryResults from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/laboratory-results/add-laboratory-results/AddLaboratoryResults'
import { getLaboratoryResults } from '../../../../../api/user-services/healt-info-services'

const AddLaboratoryResultsPage = () => {
    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getLaboratoryResults()
            setData(resp.data)
        } catch (error) {
            console.log("laboratory cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);
    return (
        <div>
            <AddLaboratoryResults data={data} />
        </div>
    )
}

export default AddLaboratoryResultsPage
