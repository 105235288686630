import Swal from "sweetalert2";

export const swalToast = (title, text, icon = "success", timer = 4000) => {
    //icon "success,error,info,warning,question" olabilir
    Swal.fire({
        position: 'center',
        icon,
        title,
        text,
        showConfirmButton: false,
        timer,
    })
}

export const swalToastConfirm = () => {
    Swal.fire({
        title: "Are you sure?",
        text: "The conversation will be terminated.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2097f3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: "",
                text: "The conversation has been terminated.",
                icon: "success"
            });
        }
    });
}