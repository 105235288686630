import React from 'react'

const UserAgreement = () => {
    return (
        <div id='user-agreement' className='box-style'>

        </div>
    )
}

export default UserAgreement
