import React from 'react'
import "./habits.scss"
import { Link } from 'react-router-dom'

import { FaPlus } from "react-icons/fa6";
const Habits = () => {
    return (
        <div id='habits' className='box-style'>
            <Link to="tabacco">
                <span>Tobacco Use</span>
                <FaPlus />
            </Link>
            <Link to="alcohol">
                <span>Alcohol Use</span>
                <FaPlus />
            </Link>
            <Link to="drugs">
                <span>Drugs Use</span>
                <FaPlus />
            </Link>
            <Link to="exercise">
                <span>Exercise</span>
                <FaPlus />
            </Link>
            <Link to="nutrition">
                <span>Nutrition</span>
                <FaPlus />
            </Link>
            <Link to="sleep">
                <span>Sleep</span>
                <FaPlus />
            </Link>
            <Link to="sexual-activity">
                <span>Sexual activity </span>
                <FaPlus />
            </Link>
        </div>
    )
}

export default Habits
