import React from 'react'
import "./single-message-box.scss"

import { BiSupport } from "react-icons/bi";
import { Link } from 'react-router-dom';

const SingleMessageBox = () => {
    return (
        <Link to="4">
            <div id='single-message-box'>
                <div className="left">
                    <div className="profile-image">
                        <BiSupport />
                    </div>
                    <span className="name">Zupsie Support</span>
                </div>
            </div>
        </Link>
    )
}

export default SingleMessageBox
