import React, { useEffect, useState } from 'react'
import NoRecord from '../../../../../no-record/NoRecord'
import { Link } from 'react-router-dom'
import "./surgeries.scss"
import { getSurgeries } from '../../../../../../api/user-services/healt-info-services'
const Surgeries = () => {


    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getSurgeries()
            setData(resp.data)
        } catch (error) {
            console.log("surgeries cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    return (
        <div id='surgeries' className='box-style'>
            {
                (data && data?.length > 0) ? (
                    data?.map((item) => (
                        <div className="surgeries" key={item.uuid}>
                            <div>
                                <span>Surgerie</span>
                                <p>{item?.surgerie}</p>
                            </div>
                            <div>
                                <span>Date</span>
                                <p>{item?.date || "This part was not filled in."}</p>
                            </div>
                            <div>
                                <span>Reason</span>
                                <p>{item?.reason}</p>
                            </div>
                            <div>
                                <span>Hospital</span>
                                <p>{item?.hospital}</p>
                            </div>
                        </div>
                    ))
                ) : (<NoRecord title={"Surgeries"} />)
            }
            <Link className='button' to="add-surgeries">
                Add Surgeries
            </Link>
        </div>
    )
}

export default Surgeries
