import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;

//HEALTH PROFILE

//! health info
export const getHealthInfo = () => {
    return axios.get(`${API_URL}healthinfo`);
}
export const postHealthInfo = (payload) => {
    return axios.post(`${API_URL}healthinfo/healthinfo`, payload);
}

//! commitment status


export const getCommitmentStatus = () => {
    return axios.get(`${API_URL}commitment/all/commitmentstatuslist`);
}
export const postCommitmentStatus = (payload) => {
    return axios.post(`${API_URL}healthinfo/healthinfo`, payload);
}

//! disability situations


export const getDisabilitySituations = () => {
    return axios.get(`${API_URL}disabilitysituation/all/disabilitysituation`);
}
export const postDisabilitySituations = (username, payload) => {
    return axios.post(`${API_URL}disabilitysituation/create/disabilitysituation?username=${username}`, payload);
}



//HEALTH HISTORY

//! health history

export const getFamilyHistory = () => {
    return axios.get(`${API_URL}healthistory/retrieve/familyhistory`);
}
export const postFamilyHistory = (payload) => {
    return axios.post(`${API_URL}healthistory/create/familyhistory`, payload);
}
export const updateFamilyHistory = (payload) => {
    return axios.put(`${API_URL}healthistory/update/familyhistory`, payload);
}

//!diagnosis

export const getDiagnosis = () => {
    return axios.get(`${API_URL}healthistory/retrieve/diagnosis`);
}
export const postDiagnosis = (payload) => {
    return axios.post(`${API_URL}healthistory/create/diagnosis`, payload);
}

//!commitmentstatus

export const getCommitmentStatusList = () => {
    return axios.get(`${API_URL}commitment/all/commitmentstatuslist`);
}
export const postCommitmentStatusList = (username, payload) => {
    return axios.post(`${API_URL}commitment/create/commitmentstatus?username=${username}`, payload);
}



//! habits 
//! tabacco - alchol - drugs

export const getTabaccoAlcholDrugs = () => {
    return axios.get(`${API_URL}healthistory/retrieve/tobaccoalcoholdrug`);
}
export const postTabaccoAlcholDrugs = (payload) => {
    return axios.post(`${API_URL}healthistory/create/tobaccoalcoholdrug`, payload);
}
export const updateTabaccoAlcholDrugs = (payload) => {
    return axios.put(`${API_URL}healthistory/update/tobaccoalcoholdrug`, payload);
}

//! exercise
export const getExercise = () => {
    return axios.get(`${API_URL}healthistory/retrieve/exercise`);
}
export const postExercise = (payload) => {
    return axios.post(`${API_URL}healthistory/create/exercise`, payload);
}

export const updateExercise = (payload) => {
    return axios.put(`${API_URL}healthistory/update/exercise`, payload);
}

//! nutrition
export const getNutrition = () => {
    return axios.get(`${API_URL}healthistory/retrieve/nutrition`);
}
export const postNutrition = (payload) => {
    return axios.post(`${API_URL}healthistory/create/nutrition`, payload);
}
export const updateNutrition = (payload) => {
    return axios.put(`${API_URL}healthistory/update/nutrition`, payload);
}
//! sleep
export const getSleepHabit = () => {
    return axios.get(`${API_URL}healthistory/retrieve/sleephabit`);
}
export const postSleepHabit = (payload) => {
    return axios.post(`${API_URL}healthistory/create/sleephabit`, payload);
}
export const updateSleepHabit = (payload) => {
    return axios.put(`${API_URL}healthistory/update/sleephabit`, payload);
}

//! sexual activity
export const getSexualActivity = () => {
    return axios.get(`${API_URL}healthistory/retrieve/sexualactivityhabit`);
}
export const postSexualActivity = (payload) => {
    return axios.post(`${API_URL}healthistory/create/sexualactivityhabit`, payload);
}
export const updateSexualActivity = (payload) => {
    return axios.put(`${API_URL}healthistory/update/sexualactivityhabit`, payload);
}


//! allergies
export const getAllergies = () => {
    return axios.get(`${API_URL}healthistory/retrieve/allergies`);
}
export const postAllergies = (payload) => {
    return axios.post(`${API_URL}healthistory/create/allergies`, payload);
}

//! surgeries
export const getSurgeries = () => {
    return axios.get(`${API_URL}healthistory/retrieve/surgeries`);
}
export const postSurgeries = (payload) => {
    return axios.post(`${API_URL}healthistory/create/surgeries`, payload);
}

//! vaccinations
export const getVaccinations = () => {
    return axios.get(`${API_URL}healthistory/retrieve/vaccinations`);
}
export const getVaccinationItems = () => {
    return axios.get(`${API_URL}healthistory/all/vaccinationitems`);
}
export const postVaccinations = (payload) => {
    return axios.post(`${API_URL}healthistory/create/vaccinations`, payload);
}
export const updateVaccinations = (payload) => {
    return axios.put(`${API_URL}healthistory/update/vaccinations`, payload);
}


//MEDICINES

export const getMedicinesInfo = () => {
    return axios.get(`${API_URL}medicinehistory/retrieve/usermedications`);
}
export const addMedicineImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}medicinehistory/medicineimage?imagename=${imagename}&username=${username}`, payload);
}
export const addMedicineInfo = (payload) => {
    return axios.post(`${API_URL}medicinehistory/create/usermedication`, payload);
}

//PRESCRIPTIONS

export const getPrescriptionsInfo = () => {
    return axios.get(`${API_URL}prescriptionhistory/retrieve/userprescription`);
}
export const addPrescriptionsImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}prescriptionhistory/prescriptionimage?imagename=${imagename}&username=${username}`, payload);
}
export const addPrescriptionsInfo = (payload) => {
    return axios.post(`${API_URL}prescriptionhistory/create/userprescription`, payload);
}
export const updatePrescriptionsInfo = (payload) => {
    return axios.put(`${API_URL}prescriptionhistory/update/userprescription`, payload);
}


//LABORATORY DOCUMENTS

//! radiology

export const getRadiologyResults = () => {
    return axios.get(`${API_URL}laboratorydocuments/retrieve/radiologyresult`);
}
export const postRadiologyResults = (payload) => {
    return axios.post(`${API_URL}laboratorydocuments/create/radiologyresult`, payload);
}
export const updateRadiologyResults = (payload) => {
    return axios.put(`${API_URL}laboratorydocuments/update/radiologyresult`, payload);
}

export const addRadiologyImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}laboratorydocuments/radiologyimage?imagename=${imagename}&username=${username}`, payload);
}


//! cardiology
export const getCardiologyResults = () => {
    return axios.get(`${API_URL}laboratorydocuments/retrieve/cardiologyresult`);
}
export const postCardiologyResults = (payload) => {
    return axios.post(`${API_URL}laboratorydocuments/create/cardiologyresult`, payload);
}
export const updateCardiologyResults = (payload) => {
    return axios.put(`${API_URL}laboratorydocuments/update/cardiologyresult`, payload);
}

export const addCardiologyResultsImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}laboratorydocuments/cardiologyimage?imagename=${imagename}&username=${username}`, payload);
}


//! other
export const getOtherResults = () => {
    return axios.get(`${API_URL}laboratorydocuments/retrieve/otherlabresult`);
}
export const postOtherResults = (payload) => {
    return axios.post(`${API_URL}laboratorydocuments/create/otherlabresult`, payload);
}
export const updateOtherResults = (payload) => {
    return axios.put(`${API_URL}laboratorydocuments/update/otherlabresult`, payload);
}

export const addOtherResultsImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}laboratorydocuments/otherresultimage?imagename=${imagename}&username=${username}`, payload);
}


//! medical reports
export const getMedicalResults = () => {
    return axios.get(`${API_URL}laboratorydocuments/retrieve/medicalresult`);
}
export const postMedicalResults = (payload) => {
    return axios.post(`${API_URL}laboratorydocuments/create/medicalresult`, payload);
}
export const updateMedicalResults = (payload) => {
    return axios.put(`${API_URL}laboratorydocuments/update/medicalresult`, payload);
}

export const addMedicalResultsImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}laboratorydocuments/medicalimage?imagename=${imagename}&username=${username}`, payload);
}


//! laboratory results
export const getLaboratoryResults = () => {
    return axios.get(`${API_URL}laboratorydocuments/retrieve/laboratoryresult`);
}
export const postLaboratoryResults = (payload) => {
    return axios.post(`${API_URL}laboratorydocuments/create/laboratoryresult`, payload);
}
export const updateLaboratoryResults = (payload) => {
    return axios.put(`${API_URL}laboratorydocuments/update/laboratoryresult`, payload);
}

export const addLaboratoryResultsImage = (imagename, username, payload) => {
    return axios.post(`${API_URL}laboratorydocuments/laboratoryimage?imagename=${imagename}&username=${username}`, payload);
}