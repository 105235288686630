import React from 'react'
import { useSelector } from 'react-redux';
import "./hero-sec.scss"
import CreateAccInput from '../create-acc-input/CreateAccInput'
import { Container } from 'react-bootstrap';
const HeroSec = () => {

    const isUserLogin = useSelector(state => state.auth.isAuthenticated)

    return (
        <Container>
            <div id='hero-sec'>
                <div className="left">
                    <h1>Navigating Cancer  <br />
                        Together, with Expert <br />
                        Guidance
                    </h1>

                    <div className='hero-sec-p'>
                        <p className='p-lg'>Together, we navigate the challenging battle against cancer, prioritizing accurate diagnoses and careful evaluation of treatment options. The Zupsie Health Platform offers a valuable second opinion, enhancing the safety of this process.</p>
                    </div>
                    {
                        isUserLogin ? <></> : <CreateAccInput />
                    }
                </div>
                <div className="right"></div>


            </div>
        </Container>
    )
}

export default HeroSec
