// import React, { useState } from 'react'
// import NoRecord from '../../../../no-record/NoRecord'
// import "./medicines.scss"

// import { FaPlus } from "react-icons/fa6";
// import { Link } from 'react-router-dom';

// const Medicines = ({ data }) => {

//     const [isCurrentBtn, setIsCurrentBtn] = useState(true)
//     const [isHistoryBtn, setIsHistoryBtn] = useState(false)

//     const bgStyle = "#041734"

//     const handleCurrentBtn = () => {
//         setIsCurrentBtn(true)
//         setIsHistoryBtn(false)
//     }
//     const handleHistoryBtn = () => {
//         setIsHistoryBtn(true)
//         setIsCurrentBtn(false)
//     }

//     console.log(data);

//     return (
//         <div id='medicines' className='box-style'>
//             <div className="top d-flex align-items-center justify-content-between gap-5">
//                 <button style={{ backgroundColor: isCurrentBtn ? bgStyle : "" }} className="button w-100" onClick={handleCurrentBtn}>Current</button>
//                 <button style={{ backgroundColor: isHistoryBtn ? bgStyle : "" }} className="button w-100" onClick={handleHistoryBtn}>Medication History</button>
//             </div>
//             {
//                 isCurrentBtn && <div className=' my-5 '>

//                     {
//                         data && data?.length > 0 ? data.map((item) => {
//                             if (item.currentlyUsed === true) {
//                                 return (
//                                     <div className="medicines mb-2" key={item?.uuid}>
//                                         <div>
//                                             <span>Medicine</span>
//                                             <p>{item.medicine}</p>
//                                         </div>
//                                         <div>
//                                             <span>Usagemethod</span>
//                                             <p>{item.usagemethod}</p>
//                                         </div>
//                                         <div>
//                                             <span>Period</span>
//                                             <p>{item.period || "This part was not filled in."}</p>
//                                         </div>
//                                         <div>
//                                             <span>Box Amount</span>
//                                             <p>{data?.[0]?.boxAmount}</p>
//                                         </div>
//                                     </div>
//                                 )
//                             }
//                         }) : <NoRecord />
//                     }

//                 </div>
//             }
//             {
//                 isHistoryBtn && <div className='d-flex flex-column '>
//                     {
//                         !isCurrentBtn && <div className=' my-5'>

//                             {
//                                 data ? data?.map((item) => {
//                                     if (item.currentlyUsed === false) {
//                                         return (
//                                             <div className="medicines mb-4" key={item.uuid} >
//                                                 <div>
//                                                     <span>Medicine</span>
//                                                     <p>{item.medicine}</p>
//                                                 </div>
//                                                 <div>
//                                                     <span>Usagemethod</span>
//                                                     <p>{item.usagemethod}</p>
//                                                 </div>
//                                                 <div>
//                                                     <span>Period</span>
//                                                     <p>{item.period || "This part was not filled in."}</p>
//                                                 </div>
//                                                 <div>
//                                                     <span>Box Amount</span>
//                                                     <p>{item.boxAmount}</p>
//                                                 </div>
//                                             </div>
//                                         )
//                                     }
//                                 }
//                                 ) : <NoRecord />

//                             }

//                         </div>
//                     }
//                 </div>
//             }

//             <Link className="addBtn" to="add-medicines">
//                 <FaPlus />
//             </Link>
//         </div>
//     )
// }

// export default Medicines


import React, { useState } from 'react';
import NoRecord from '../../../../no-record/NoRecord';
import "./medicines.scss";
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Medicines = ({ data }) => {
    const [isCurrentBtn, setIsCurrentBtn] = useState(true);
    const [isHistoryBtn, setIsHistoryBtn] = useState(false);

    const bgStyle = "#041734";

    const handleCurrentBtn = () => {
        setIsCurrentBtn(true);
        setIsHistoryBtn(false);
    };
    const handleHistoryBtn = () => {
        setIsHistoryBtn(true);
        setIsCurrentBtn(false);
    };

    console.log(data);

    const currentMedicines = data?.filter(item => item.currentlyUsed) || [];
    const historyMedicines = data?.filter(item => !item.currentlyUsed) || [];

    return (
        <div id='medicines' className='box-style'>
            <div className="top d-flex align-items-center justify-content-between gap-5">
                <button style={{ backgroundColor: isCurrentBtn ? bgStyle : "" }} className="button w-100" onClick={handleCurrentBtn}>Current</button>
                <button style={{ backgroundColor: isHistoryBtn ? bgStyle : "" }} className="button w-100" onClick={handleHistoryBtn}>Medication History</button>
            </div>
            {isCurrentBtn && (
                <div className=' my-5 '>
                    {currentMedicines.length > 0 ? currentMedicines.map((item) => (
                        <div className="medicines mb-2" key={item?.uuid}>
                            <div>
                                <span>Medicine</span>
                                <p>{item.medicine}</p>
                            </div>
                            <div>
                                <span>Usagemethod</span>
                                <p>{item.usagemethod}</p>
                            </div>
                            <div>
                                <span>Period</span>
                                <p>{item.period || "This part was not filled in."}</p>
                            </div>
                            <div>
                                <span>Box Amount</span>
                                <p>{item.boxAmount}</p>
                            </div>
                        </div>
                    )) : <NoRecord />}
                </div>
            )}
            {isHistoryBtn && (
                <div className='d-flex flex-column '>
                    {!isCurrentBtn && (
                        <div className=' my-5'>
                            {historyMedicines.length > 0 ? historyMedicines.map((item) => (
                                <div className="medicines mb-4" key={item.uuid} >
                                    <div>
                                        <span>Medicine</span>
                                        <p>{item.medicine}</p>
                                    </div>
                                    <div>
                                        <span>Usagemethod</span>
                                        <p>{item.usagemethod}</p>
                                    </div>
                                    <div>
                                        <span>Period</span>
                                        <p>{item.period || "This part was not filled in."}</p>
                                    </div>
                                    <div>
                                        <span>Box Amount</span>
                                        <p>{item.boxAmount}</p>
                                    </div>
                                </div>
                            )) : <NoRecord />}
                        </div>
                    )}
                </div>
            )}
            <Link className="addBtn" to="add-medicines">
                <FaPlus />
            </Link>
        </div>
    );
}

export default Medicines;
