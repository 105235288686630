import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;

export const radiologyImage = (formData, imagename, username) => {


    return axios.post(`${API_URL}laboratorydocuments/radiologyimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export const medicalImage = (formData, imagename, username) => {


    return axios.post(`${API_URL}laboratorydocuments/medicalimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export const laboratoryImage = (formData, imagename, username) => {


    return axios.post(`${API_URL}laboratorydocuments/laboratoryimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export const cardiologyImage = (formData, imagename, username) => {


    return axios.post(`${API_URL}laboratorydocuments/cardiologyimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export const otherResultImage = (formData, imagename, username) => {


    return axios.post(`${API_URL}laboratorydocuments/otherresultimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}
export const medicineImage = (formData, imagename, username) => {


    return axios.post(`${API_URL}medicinehistory/medicineimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export const prescriptionImage = (formData, imagename, username) => {

    return axios.post(`${API_URL}prescriptionhistory/prescriptionimage?imagename=${imagename}&username=${username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}
