
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectIsAuthenticated } from "../store/auth-slice/authSlice";

export const ProtectedRoute = ({ element }) => {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    console.log(isAuthenticated + "protected");

    //bu sadece tasarim yaparken kullanicam 
    //const isAuthenticated = true;


    return isAuthenticated ? element : <Navigate to="/" replace />;
};
