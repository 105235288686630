import React, { useEffect, useState } from 'react'
import AddDiagnosis from '../../../../../../components/user/profile/link-pages/health-history/diagnosis/add-diagnosis/AddDiagnosis'
import { getDiagnosis } from '../../../../../../api/user-services/healt-info-services'


const AddDiagnosisPage = () => {

    const [data, setData] = useState()


    const loadData = async () => {
        try {
            const resp = await getDiagnosis()
            setData(resp.data)
        } catch (error) {
            console.log("diagnosis getirilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div>
            <AddDiagnosis />
        </div>
    )
}

export default AddDiagnosisPage
