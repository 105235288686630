import React, { useEffect, useState } from 'react'
import "./meeting-main-doc-card.scss"
import DoctorCardHead from '../../doctor-info/head-info/DoctorCardHead'
import { FaUserDoctor } from "react-icons/fa6";
import { GiSandsOfTime } from "react-icons/gi";
import { IoCalendarOutline } from "react-icons/io5";
import { LuStethoscope } from "react-icons/lu";
import { FaRegCreditCard } from "react-icons/fa";
import { Link, useNavigate, useParams } from 'react-router-dom';
import MeetingsMainModal from '../../../modals/meetings-main-modal/MeetingsMainModal';
import MeetingsMainModalCancel from '../../../modals/meetings-main-modal/MeetingsMainModalCancel';
import { useSelector } from 'react-redux';


import { FaCheck } from "react-icons/fa6";
import { PiTimer } from "react-icons/pi";
import { FiRefreshCcw } from "react-icons/fi";
import { CgMediaLive } from "react-icons/cg";
import { swalToast } from '../../../../swalMessages/swalMessages';
import { AgoraAvaibleLiveRoom } from '../../../../api/user-services/agora-services';

const MeetingMainDocCard = () => {


    const appointmenOnlyDate = useSelector(state => state.appointmentDate.appointmentOnlyDate)
    const appointmentOnlyHour = useSelector(state => state.appointmentDate.appointmentOnlyHour)
    const navigate = useNavigate()
    const { id } = useParams()
    console.log(id);

    const [day, month, year] = appointmenOnlyDate.split("-").map(Number);
    const [startTime] = appointmentOnlyHour.split("-");
    const [hour, minute] = startTime.split(":").map(str => parseInt(str.trim(), 10));

    const appointmentDate = new Date(year, month - 1, day, hour, minute);
    const currentDate = new Date();

    console.log(day, month, year);
    console.log(hour, minute);

    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();


    const handleMeeting = () => {
        // if (lessThanMinute) {
        //     navigate(`/user/meetings-main/${id}/meeting-start`)
        // } else {
        //     swalToast("You can't start meeting at this time", `The meeting will commence on ${appointmenOnlyDate} - ${appointmentOnlyHour}`, "info")
        // }
        navigate(`/user/meetings-main/${id}/meeting-start`)
    }



    const appointmentIsToday = (day === currentDay && month === currentMonth && year === currentYear) && (hour < currentHour || (currentHour === hour - 1 && minute === 0 && 60 - currentMinute > 15));

    //approaching
    const approaching = appointmentDate > currentDate;


    //lessThanMinute
    const lessThanMinute = (day === currentDay && month === currentMonth && year === currentYear) && (hour < currentHour || (currentHour === hour - 1 && minute === 0 && 60 - currentMinute <= 15));


    return (
        <div id='meeting-main-doc-card' className='box-style'>
            <div className="top">
                <DoctorCardHead />



                <button onClick={handleMeeting} style={{ backgroundColor: lessThanMinute ? "red" : "" }}  >


                    {
                        lessThanMinute && <div >
                            <FiRefreshCcw /> Ready To Meet / Start
                        </div>
                    }
                    {
                        appointmentIsToday && <div >
                            <FiRefreshCcw /> Appointment is Today
                        </div>
                    }


                    {
                        approaching && <div>
                            <FiRefreshCcw /> Approaching
                        </div>
                    }




                </button>
                {
                    lessThanMinute && <span className='about-start'>Less than 15 minutes left for the appointment to start.</span>
                }
            </div >
            <div className="mid">
                <FaUserDoctor />
                <div>
                    <span>Meeting</span>
                    <span><GiSandsOfTime /> <span>30 min.</span></span>
                </div>
            </div>
            <div className="line"></div>
            <div className="bottom">
                <span>
                    <IoCalendarOutline />
                    <span>
                        <span>
                            {appointmenOnlyDate} -
                            {appointmentOnlyHour}
                        </span>
                    </span>
                </span>
                <span>
                    <LuStethoscope />
                    <span>
                        <span>Meeting</span>
                        <span>30 minute</span>
                    </span>
                </span>
                <span>
                    <FaRegCreditCard />
                    <span>
                        <span>Payment Options</span>
                        <span>Credit Card</span>
                    </span>
                </span>

                {/* <Link>
                    <button className="button w-100">Send Message</button>
                </Link> */}
                {/* <Link onClick={() => setModalShow(true)} className='update-appointment'>update appointment</Link> */}

            </div>
            {/* <MeetingsMainModal
                show={modalShow}
                onHide={() => setModalShow(false)} /> */}



        </div >
    )
}

export default MeetingMainDocCard
