// // Import necessary components and hooks from Agora SDK and React
// import { configType } from "./config";
// import "./agora-manager.scss"
// import { IMicrophoneAudioTrack, ICameraVideoTrack } from "agora-rtc-sdk-ng";
// import React, { useState } from "react";
// import {
//   LocalVideoTrack,
//   RemoteUser,
//   useJoin,
//   useLocalCameraTrack,
//   useLocalMicrophoneTrack,
//   usePublish,
//   useRTCClient,
//   useRemoteUsers,
//   useClientEvent,
// } from "agora-rtc-react";

// //icons
// import { FaVolumeHigh } from "react-icons/fa6";
// import { FaVolumeXmark } from "react-icons/fa6";
// import { TbArrowsDiagonal2 } from "react-icons/tb";
// import { TbArrowsDiagonalMinimize } from "react-icons/tb";
// import { useNavigate } from "react-router-dom";
// import Loading from "../loading/Loading";
// import { swalToast } from "../../swalMessages/swalMessages";
// import Swal from "sweetalert2";


// // Define the shape of the Agora context
// const AgoraContext = React.createContext(null);

// // AgoraProvider component to provide the Agora context to its children
// const AgoraProvider = ({ children, localCameraTrack, localMicrophoneTrack }) => (
//   <AgoraContext.Provider value={{ localCameraTrack, localMicrophoneTrack, children }}>
//     {children}
//   </AgoraContext.Provider>
// );

// // Custom hook to access the Agora context
// const useAgoraContext = () => {
//   const context = React.useContext(AgoraContext);
//   if (!context) throw new Error("useAgoraContext must be used within an AgoraProvider");
//   return context;
// };



// // AgoraManager component responsible for handling Agora-related logic and rendering UI
// const AgoraManager = ({ userLiveToken, config, children }) => {


//   const [micOn, setMic] = useState(true);
//   const [cameraOn, setCamera] = useState(true);
//   const [activeConnection, setActiveConnection] = useState(true);
//   // Retrieve local camera and microphone tracks and remote users
//   const agoraEngine = useRTCClient();
//   const navigate = useNavigate();
//   const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack(cameraOn);
//   const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
//   const remoteUsers = useRemoteUsers();
//   const [role, setRole] = React.useState("host"); // Default role is host
//   // const [smToLg, setSmToLg] = React.useState(false);
//   const [countdown, setCountdown] = useState(30 * 60);

//   const hours = Math.floor(countdown / 3600);
//   const minutes = Math.floor((countdown % 3600) / 60);
//   const seconds = countdown % 60;





//   const agoraContextValue = {
//     localMicrophoneTrack,
//     remoteUsers
//   };

//   // console.log(smToLg);
//   console.log(config.rtcToken);
//   console.log(agoraEngine);
//   console.log(userLiveToken);

//   // Publish local tracks

//   // Join the Agora channel with the specified configuration
//   useJoin({
//     appid: userLiveToken?.appId,
//     channel: userLiveToken?.channelName,
//     token: userLiveToken?.token,
//     uid: userLiveToken?.userId, //bunu userLiveToken icinden alicam gelecek
//   }, activeConnection);

//   usePublish([localMicrophoneTrack, localCameraTrack]);



//   useClientEvent(agoraEngine, "user-joined", (user) => {
//     console.log("The user", user.uid, " has joined the channel");
//   });

//   useClientEvent(agoraEngine, "user-left", (user) => {
//     console.log("The user", user.uid, " has left the channel");
//   });

//   useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
//     console.log("The user", user.uid, " has published media in the channel");
//   });

//   const handleRoleChange = (event) => {
//     setRole(event.target.value);
//     if (event.target.value === "host") {
//       agoraEngine.setClientRole("host").then(() => {
//         // Your code to handle the resolution of the promise
//         console.log("Client role set to host successfully");
//       }).catch((error) => {
//         // Your code to handle any errors
//         console.error("Error setting client role:", error);
//       });
//     } else {
//       agoraEngine.setClientRole("audience").then(() => {
//         // Your code to handle the resolution of the promise
//         console.log("Client role set to host successfully");
//       }).catch((error) => {
//         // Your code to handle any errors
//         console.error("Error setting client role:", error);
//       });
//     }
//   };

//   // const handleVideoSizeChange = () => {
//   //   setSmToLg((prevSmToLg) => !prevSmToLg);
//   // };

//   React.useEffect(() => {

//     const timer = setInterval(() => {
//       setCountdown(prevCountdown => prevCountdown - 1);
//     }, 1000);

//     if (countdown <= 0) {
//       navigate('/user');
//       swalToast("Great", "The conversation time has expired", "success")
//     }

//     return () => {
//       localCameraTrack?.close();
//       localMicrophoneTrack?.close();
//       clearInterval(timer);
//     };
//   }, []);

//   // Check if devices are still loading
//   const deviceLoading = isLoadingMic || isLoadingCam;
//   if (deviceLoading) return <div className="loading-devices">
//     <Loading />
//     <span>Loading devices... Please Wait</span>
//   </div>;


//   const handleLeaveClick = () => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "The conversation will be terminated.",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#2097f3",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "End The Conversation"
//     }).then((result) => {
//       if (result.isConfirmed) {
//         Swal.fire({
//           title: "",
//           text: "The conversation has been terminated.",
//           icon: "success"
//         });
//         navigate("/user")
//       }
//     });
//   };



//   // Render the AgoraProvider and associated UI components
//   return (
//     <div id="live">
//       <button className='button w-100 mb-3' onClick={handleLeaveClick}>Leave</button>
//       <AgoraProvider localCameraTrack={localCameraTrack} localMicrophoneTrack={localMicrophoneTrack}>
//         {children}
//         <>
//           {config.selectedProduct === "live" &&
//             (<div >
//               <label>
//                 <input
//                   type="radio"
//                   value="host"
//                   checked={role === "host"}
//                   onChange={handleRoleChange}
//                 />
//                 Host
//               </label>
//               <label>
//                 <input
//                   type="radio"
//                   value="audience"
//                   checked={role === "audience"}
//                   onChange={handleRoleChange}
//                 />
//                 Audience
//               </label>
//             </div>)}
//         </>

//         <div className="timer">
//           <h1>Remaining Time</h1>
//           <p>{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</p>
//         </div>

//         <div id="videos">

//           <div className={`vid  ${remoteUsers?.length > 0 ? "vid-loc" : ""}`}>
//             <LocalVideoTrack
//               track={localCameraTrack}
//               play={true}
//               audioTrack={localMicrophoneTrack}
//               videoTrack={localCameraTrack}
//               cameraOn={cameraOn}
//               micOn={micOn}
//               playAudio={micOn}
//               playVideo={cameraOn}
//             />
//           </div>


//           {remoteUsers?.map((remoteUser) => (
//             <div className={`vid vid-remote`} key={remoteUser.uid}>
//               <RemoteUser
//                 user={remoteUser}
//                 playVideo={true}

//               />
//             </div>
//           ))}



//         </div>

//       </AgoraProvider>
//       <div>
//         {/* media-controls toolbar component - UI controling mic, camera, & connection state  */}
//         <div id="controlsToolbar">
//           <div id="mediaControls">
//             <button className="btn" onClick={() => setMic(a => !a)}>
//               Mic
//             </button>
//             <button className="btn" onClick={() => setCamera(a => !a)}>
//               Camera
//             </button>
//           </div>
//           <button id="endConnection"
//             onClick={() => {
//               setActiveConnection(false)
//               navigate('/user')
//             }}> Disconnect
//           </button>
//         </div>
//       </div>

//     </div>
//   );
// };

// // Export the AgoraManager component as the default export
// export { AgoraManager };

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./agora-manager.scss"

import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";
import { IMicrophoneAudioTrack, ICameraVideoTrack } from "agora-rtc-sdk-ng"

//icons
import { FaMicrophoneAlt } from "react-icons/fa"; //micon
import { FaMicrophoneAltSlash } from "react-icons/fa"; //micoff
import { HiVideoCamera } from "react-icons/hi2"; //cameraon
import { HiVideoCameraSlash } from "react-icons/hi2"; //cameraoff
import { AiOutlineDisconnect } from "react-icons/ai"; //disconnect
import { FaPhoneSlash } from "react-icons/fa6"; //disconnect2
import { swalToast } from "../../swalMessages/swalMessages";
import Swal from "sweetalert2";
import Loading from "../loading/Loading";
import { useSelector } from "react-redux";

export const AgoraManager = ({ userLiveToken, config, children, }) => {

  const isMicOn = useSelector(state => state.mediaController.isMicOn)
  const isCamOn = useSelector(state => state.mediaController.isCamOn)

  const [activeConnection, setActiveConnection] = useState(true);
  const [micOn, setMic] = useState(isMicOn);
  const [cameraOn, setCamera] = useState(isCamOn);
  const navigate = useNavigate()
  const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack(cameraOn);


  useJoin({
    appid: userLiveToken?.appId,
    channel: userLiveToken?.channelName,
    token: userLiveToken?.token,
    uid: userLiveToken?.userId,
  }, activeConnection);

  usePublish([localMicrophoneTrack, localCameraTrack]);


  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  // play the remote user audio tracks
  audioTracks.forEach((track) => track.play());

  console.log(userLiveToken);
  console.log(remoteUsers);

  const deviceLoading = isLoadingMic || isLoadingCam;
  if (deviceLoading) return <div className="loading-devices">
    <Loading />
    <span>Loading devices... Please Wait.</span>
  </div>;

  return (
    <div id="live">
      <div id="videos">

        {
          remoteUsers.map((user) => (
            <div className={`vid vid-remote`} key={user.uid}>
              <RemoteUser user={user} />
            </div>
          ))
        }

        <div id='localVideo' className={`vid  ${remoteUsers?.length > 0 ? "vid-loc" : ""}`}>
          <LocalUser
            audioTrack={localMicrophoneTrack}
            videoTrack={localCameraTrack}
            cameraOn={cameraOn}
            micOn={micOn}
            playAudio={micOn}
            playVideo={cameraOn}
          />
        </div>


        <div id="controlsToolbar">
          <div id="mediaControls">
            <button className="btn" onClick={() => setMic(a => !a)}>
              {
                micOn ? <FaMicrophoneAlt /> : <FaMicrophoneAltSlash />
              }
            </button>
            <button className="btn" onClick={() => setCamera(a => !a)}>
              {
                cameraOn ? <HiVideoCamera /> : <HiVideoCameraSlash />
              }
            </button>
            <button id="endConnection"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "The conversation will be terminated.",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#2097f3",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "End The Conversation"
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "",
                      text: "The meeting has been concluded.",
                      icon: "success"
                    });
                    navigate("/user")
                    setActiveConnection(false)
                    localCameraTrack?.close();
                    localMicrophoneTrack?.close()
                  }
                });
              }}> <FaPhoneSlash />
            </button>
          </div>

        </div>

      </div>
    </div>
  )

}