



import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    doctor: sessionStorage.getItem("doctor") ? JSON.parse(sessionStorage.getItem("doctor")) : [],
    selectedDoc: sessionStorage.getItem("selectedDoc") ? JSON.parse(sessionStorage.getItem("selectedDoc")) : []
}

export const doctorSlice = createSlice({
    name: "doctor",
    initialState,
    reducers: {
        setDoctor: (state, action) => {
            state.doctor = action.payload;
            sessionStorage.setItem("doctor", JSON.stringify(action.payload));
        },
        setSelectedDoctor: (state, action) => {
            state.selectedDoc = action.payload;
            sessionStorage.setItem("selectedDoc", JSON.stringify(action.payload));
        }
    }
})

export const { setDoctor, setSelectedDoctor } = doctorSlice.actions;
export default doctorSlice.reducer;
