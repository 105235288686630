import React from 'react'
import PackageWhitePaperDetail from '../../packages-page/package-white-paper/PackageWhitePaperDetail'

const PackageWhitePaperCont = () => {
    return (
        <div id='package-white-paper-cont' className='box-style'>
            <PackageWhitePaperDetail />
        </div>
    )
}

export default PackageWhitePaperCont
