import React from 'react'
import "./health-profile.scss"

//icons
import { LuStethoscope } from "react-icons/lu";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
const HealthProfile = () => {
    return (
        <div id='health-profile' className='box-style'>
            <Link className="links" to="health-info">
                <div>
                    <LuStethoscope />
                    <span>Health Info</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="links" to="commitment-status">
                <div>
                    <LuStethoscope />
                    <span>Commitment Status</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="links" to="disability-situation">
                <div>
                    <LuStethoscope />
                    <span>Disability situation </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
        </div>
    )
}

export default HealthProfile
