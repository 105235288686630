import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSignUpModal: false
}


export const signUpSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showModalSignUp: (state) => {
            state.isSignUpModal = true;
        },
        hideModalSignUp: (state) => {
            state.isSignUpModal = false;
        }
    }
})

export const { showModalSignUp, hideModalSignUp } = signUpSlice.actions;
export default signUpSlice.reducer;