import React from 'react'
import PackagesDetail from '../../../components/packages-page/package-card/packages-detail/PackagesDetail'

const PackagesPageDetailPage = () => {
    return (
        <div id='packages-page-detail' className='box-style'>
            <PackagesDetail />
        </div>
    )
}

export default PackagesPageDetailPage
