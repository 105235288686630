import React from 'react'
import "./servies-doc.scss"

//icon
import { BsFillCameraVideoFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
const ServicesDoc = ({ id }) => {
    return (
        <div id='services-doc' className='box-style'>
            <h3>Services</h3>
            <div className="services-detail">
                <div>
                    <span>Service Description</span>
                    <span>Online Meeting</span>
                </div>
                <div>
                    <span>Meeting Location</span>
                    <span><BsFillCameraVideoFill /></span>
                </div>
                <div>
                    <span>Duration</span>
                    <span>30 min.</span>
                </div>
                <div>
                    <span>Fee</span>
                    <span>100$</span>
                </div>
                {/* <Link to={`/user/doctor/${id}/meetings/${id}`} className='button'>Schedule <br /> an <br /> Appointment</Link> */}
            </div>
        </div>
    )
}

export default ServicesDoc 
