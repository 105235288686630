import React, { useEffect, useState } from 'react'
import "./Pacages-detail.scss"
import Loading from '../../../loading/Loading';
import PackageSummary from '../../package-summary/PackageSummary';
import PackageReportForm from '../packages-report-form/PackageReportForm';
import PackagePaymentForm from '../package-payment-form/PackagePaymentForm';
import { useParams } from 'react-router-dom';
const PackagesDetail = () => {
    const { id } = useParams()
    const username = JSON.parse(sessionStorage.getItem("user"));
    const [loading, setLoading] = useState(false);
    const [userLocaleInfo, setUserLocaleInfo] = useState()
    const [packageReportForm, setPackageReportForm] = useState({
        uuid: "string",
        reportuuidlist: [
            "string"
        ],
        serviceType: "string",
        appointmentPurpose: "string",
        packageName: id,
        userName: username,
        date: "string"
    })


    const loadData = async () => {
        try {
            const resp = await fetch("https://ipapi.co/json");
            const data = await resp.json();
            setUserLocaleInfo(data)
        } catch (error) {
            console.log("IP bilgileri alınırken hata oluştu:", error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);


    console.log(packageReportForm);

    console.log(id);






    return (
        <div id='packages-detail'>
            <div className='p-summary'>
                <PackageSummary id={id} />
            </div>

            <div className='p-report'>
                <PackageReportForm
                    packageReportForm={packageReportForm}
                    setPackageReportForm={setPackageReportForm}
                />
            </div>

            <div className='p-payment'>
                <PackagePaymentForm
                    userLocaleInfo={userLocaleInfo}
                    packageReportForm={packageReportForm}
                    id={id}
                />
            </div>



        </div>
    )
}

export default PackagesDetail


