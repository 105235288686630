import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./category-card.scss";
import { encryptedSessionStorage } from '../../../helpers/functions/encrypt-storage';
import Loading from '../../loading/Loading';

const CategoryCard = ({ category }) => {
    const imgUrl = category.imgurl;
    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        const accessToken = encryptedSessionStorage.getItem("accessToken");
        setLoading(true)
        const fetchImage = async () => {
            try {
                const response = await axios.get(imgUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, 'Access-Control-Allow-Origin': '*'
                    },
                    responseType: 'blob'
                });

                if (response.status === 200) {
                    const data = `data:${response.headers['content-type']};base64,${await convertBlobToBase64(response.data)}`;
                    setImgSrc(data);
                    setLoading(false)
                } else {
                    console.error('Image fetch failed:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching image:', error);
                setLoading(false)
            }
        };

        if (accessToken) {
            fetchImage();
        }
    }, [imgUrl]);



    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
        });
    };


    return (
        <div id='category-card'>
            <div className="card-content">
                {
                    loading ? <Loading /> : <img src={imgSrc} alt={category?.title} />
                }

            </div>
            <span className='category-title'>{category?.title}</span>
        </div>
    );
}

export default CategoryCard;
