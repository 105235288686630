import React, { useEffect, useState } from 'react'
import "./meeting-services.scss"


import { PiSealWarningThin } from "react-icons/pi";
import { FaUserDoctor } from "react-icons/fa6";
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorImage } from '../../../../api/user-services/doctor-services';
import { encryptedSessionStorage } from '../../../../helpers/functions/encrypt-storage';
import axios from 'axios';
import { setService } from '../../../../store/service-date-slice/service-date-slice';

const MeetingServices = ({ meetingInfo, setMeetingInfo, reportTypes, }) => {

    const navigate = useNavigate()
    const dispath = useDispatch()
    const [imgSrc, setImgSrc] = useState('');
    const [isRequireText, setIsRequireText] = useState(true)
    const [loading, setLoading] = useState(false)

    const handleChangeInfo = (e) => {
        setMeetingInfo({
            ...meetingInfo,
            [e.target.name]: e.target.value
        })

        if (e.target.name == "Online" || "In-Person") {
            dispath(setService(true))
            setIsRequireText(false)
        }
    }


    const doctorname = useParams().id
    const username = JSON.parse(sessionStorage.getItem("user"))

    const loadData = async () => {
        setLoading(true)
        try {

            if (!doctorname) {
                console.error('Doctor name is undefined.');
                setLoading(false);
                return;
            }


            const resp = await getDoctorImage(doctorname);
            const imageUrl = resp.config.url;
            const accessToken = encryptedSessionStorage.getItem("accessToken");
            const response = await axios.get(imageUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                const data = `data:${response.headers['content-type']};base64,${await convertBlobToBase64(response.data)}`;
                setImgSrc(data);
                setLoading(false)
            } else {
                console.error('Image fetch failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        loadData();
    }, [imgSrc]);


    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
        });
    };


    return (
        <div id='meeting-services' className='box-style'>
            <div className="info">
                <PiSealWarningThin />
                <span>Please choose the type of service you would like to receive.</span>
            </div>
            <div className="cards">
                <div className="doc-card box-style">
                    <div className="image-box">
                        <img src={imgSrc} alt="" />
                    </div>
                    <span>{doctorname}</span>
                </div>
                <div className="doc-card box-style">
                    <div className="image-box">{username.slice(0, 1)}</div>
                    <span>{username}</span>
                </div>
            </div>
            <div className="service-type box-style">
                <span>Service Type </span>
                {
                    isRequireText && <span className='require'>Please Select Service Type</span>
                }
                <Form>
                    <Form.Group>
                        <Form.Check
                            type="radio"
                            name="serviceType"
                            value="Online"
                            label="Online"
                            onChange={handleChangeInfo}
                            checked={meetingInfo.serviceType === 'Online'}
                        />
                        <Form.Check
                            type="radio"
                            name="serviceType"
                            value="In-Person"
                            label="In-Person"
                            onChange={handleChangeInfo}
                            checked={meetingInfo.serviceType === 'In-Person'}
                        />
                    </Form.Group>
                </Form>
            </div>


            <div className="meeting-info-card box-style" >
                <FaUserDoctor />
                <div>
                    <span>Online Meeting</span>
                    <span>Earliest appointment: March 27, 2024</span>
                </div>
                <div>
                    <span>30 min.</span>
                    <span>100$</span>
                </div>
            </div>


        </div>
    )
}

export default MeetingServices
