import React from 'react'
import "./title.scss"
const Title = ({ title, titleColourful }) => {
    return (
        <div id='title'>
            <h1>
                <span>{title} <span>{titleColourful}</span></span>

            </h1>
        </div>
    )
}

export default Title
