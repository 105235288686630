import React from 'react'
import { Link } from 'react-router-dom'

import { CiUser } from "react-icons/ci";
import { IoChevronForwardSharp } from 'react-icons/io5';
const ProfileInfo = () => {
    return (
        <div id='profile-info' className='box-style'>
            <Link className="single-list-btn" to="personal-info">
                <div>
                    <CiUser />
                    <span>Personal Information</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="contact-info">
                <div>
                    <CiUser />
                    <span>Contact Information</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="emergency-info">
                <div>
                    <CiUser />
                    <span>Emergency Information </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="general-info">
                <div>
                    <CiUser />
                    <span>General Information </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
        </div>
    )
}

export default ProfileInfo
