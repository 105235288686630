import React from 'react'
import NoRecord from '../../../../no-record/NoRecord'

const PaymentHistory = () => {
    return (
        <div id='payment-history' className='box-style'>
            <NoRecord title={"Account Transactions"} />
        </div>
    )
}

export default PaymentHistory
