import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;

//refreshToken
export const refreshToken = (payload) => {
    return axios.post(`${API_URL}auth/refreshtoken`, payload);
}



export const generateOTP = (payload, phoneNumber) => {
    const encodedPhoneNumber = phoneNumber.startsWith('+')
        ? `%2B${encodeURIComponent(phoneNumber.substring(1))}`
        : encodeURIComponent(phoneNumber);

    return axios.post(`${API_URL}phoneNumber/generateOTP?phoneNumber=${encodedPhoneNumber}`, payload);
};

export const verifyOTPService = (payload, phoneNumber) => {
    const encodedPhoneNumber = phoneNumber.startsWith('+')
        ? `%2B${encodeURIComponent(phoneNumber.substring(1))}`
        : encodeURIComponent(phoneNumber);
    return axios.post(`${API_URL}phoneNumber/verifyOTP?phoneNumber=${encodedPhoneNumber}`, payload);
}

export const signUp = (payload) => {
    return axios.post(`${API_URL}auth/signup`, payload);
}
export const signIn = (payload) => {
    return axios.post(`${API_URL}auth/signin`, payload);
}
export const signOut = () => {
    return axios.post(`${API_URL}auth/signout`, {});

}

export const resetPasswordServ = (payload) => {
    return axios.post(`${API_URL}password/reset`, payload);
}

