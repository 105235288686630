import React, { useEffect, useState } from 'react'
import "../habits-forms.scss"
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'
import { postTabaccoAlcholDrugs, updateTabaccoAlcholDrugs } from '../../../../../../../api/user-services/healt-info-services'
const SubtanceForm = ({ data }) => {

    const navigate = useNavigate()
    const [currentInfo, setCurrentInfo] = useState()
    const [info, setInfo] = useState(
        {
            name: "Drugs",
            habitUsage: "",
            whatKindHabit: "",
            beginDate: "",
            quitDate: "",
            howmany: "",
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target

        setInfo({ ...info, [name]: value })
    }




    const handleSubmit = async (e) => {
        e.preventDefault()

        try {

            if (currentInfo?.id) {
                await updateTabaccoAlcholDrugs(info)
            } else {
                await postTabaccoAlcholDrugs(info)
            }

            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }

    useEffect(() => {
        const currentCategory = data?.find((item) => item.name === "Drugs");
        setCurrentInfo(currentCategory);
        if (currentCategory) {
            setInfo({
                ...info,
                habitUsage: currentCategory.habitUsage,
                whatKindHabit: currentCategory.whatKindHabit,
                beginDate: currentCategory.beginDate,
                quitDate: currentCategory.quitDate,
                howmany: currentCategory.howmany
            });
        }
    }, [data]);



    return (
        <div id='subtance-form' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Drug Use</Form.Label>
                    <Form.Select
                        name='habitUsage'
                        onChange={handleChange}
                        value={info?.habitUsage}
                    >
                        <option value="none">none</option>
                        <option value="sometimes">sometimes</option>
                        <option value="regular">regular</option>
                        <option value="quit">quit</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>What</Form.Label>
                    <Form.Control type='text'
                        name='whatKindHabit'
                        onChange={handleChange}
                        value={info?.whatKindHabit}
                    />

                </Form.Group>
                <Form.Group>
                    <Form.Label>How many drugs do you use per week?</Form.Label>
                    <Form.Control
                        type='number'
                        name='howmany'
                        onChange={handleChange}
                        value={info?.howmany}
                    />
                </Form.Group>

                <Button className='button' type='submit'>Save</Button>
            </Form>
        </div>
    )
}

export default SubtanceForm
