import React from 'react'
import "./password-main.scss"
import { Col, Container, Row } from 'react-bootstrap'
import { GrClose } from "react-icons/gr"
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../main-page/common/logo/Logo'
import PasswordForm from '../password-form/PasswordForm'
import { hideModalPassword } from '../../../store/password/passwordSlice'
const PasswordMain = ({ isForgotPasswordClicked, setIsForgotPasswordClicked }) => {


    const isPasswordModal = useSelector(state => state.passwordModal.isPasswordModal);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(hideModalPassword())
        setIsForgotPasswordClicked(false)
    }

    return (
        <>

            <div id='password-main' style={{ display: isForgotPasswordClicked || isPasswordModal ? "flex" : "none" }}>
                <Container>
                    <Row>
                        <Col>
                            <div className="left">
                                <div>
                                    <Logo />
                                    {/* <div className="line"></div>
                                <h6>Şimdi Al Sonra Öde</h6> */}
                                </div>
                                <div className="login-form">
                                    <PasswordForm
                                        isForgotPasswordClicked={isForgotPasswordClicked}
                                        setIsForgotPasswordClicked={setIsForgotPasswordClicked}
                                    />
                                </div>
                                <div>

                                    <GrClose onClick={closeModal} />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="right">
                                <img src="" alt="" />
                                <GrClose onClick={closeModal} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default PasswordMain
