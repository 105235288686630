import React from 'react'
import LaboratoryDocuments from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/LaboratoryDocuments'

const LaboratoryDocumentsPage = () => {
    return (
        <div>
            <LaboratoryDocuments />
        </div>
    )
}

export default LaboratoryDocumentsPage
