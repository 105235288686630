import React, { useEffect, useState } from 'react'
import Discover from '../../../components/containers/discover/Discover'
import { getDiscoverCategories } from '../../../api/user-services/discover-services'

const DiscoverPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getDiscoverCategories()
            setData(resp.data)
        } catch (error) {
            console.log("discover cekilemedi");
        }
    }




    useEffect(() => {
        window.scrollTo(0, 0)
        loadData()
    }, [])

    console.log(data);

    return (
        <div id='discover-page'>
            <Discover data={data} />
        </div>
    )
}

export default DiscoverPage
