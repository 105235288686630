//5400360000000003

import React, { useEffect, useRef, useState } from 'react'
import "./package-payment-form.scss"
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { encryptedSessionStorage } from '../../../../helpers/functions/encrypt-storage';
import { useSelector } from 'react-redux';
import { addPaymentInfo, getCallbackId, permissions } from '../../../../api/user-services/iyzico-services';
import { swalToast } from '../../../../swalMessages/swalMessages';
import Loading from '../../../loading/Loading';
import { packageInfo } from '../../../../api/user-services/package-services';
const PackagePaymentForm = ({ userLocaleInfo, packageReportForm, id }) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const iframeRef = useRef();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState(null);
    const [statusInfo, setStatusInfo] = useState({})
    const accessToken = encryptedSessionStorage.getItem("accessToken");
    const username = sessionStorage.getItem("user").replace(/^"(.*)"$/, '$1')
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;








    const [agreements, setAgreements] = useState({
        uuid: "",
        preliminary: false,
        distanceSales: false,
        videoCall: false,
        username: username
    });

    const handleChangeAgreements = (e) => {
        const { name, checked } = e.target;
        setAgreements(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const checkAgreements = () => {
        return agreements.preliminary && agreements.distanceSales && agreements.videoCall;
    };



    const months = Array.from({ length: 12 }, (_, i) => ({
        value: (i + 1).toString().padStart(2, '0'),
        label: (i + 1).toString().padStart(2, '0')
    }));

    const years = Array.from({ length: 21 }, (_, i) => ({
        value: (currentYear + i).toString(),
        label: (currentYear + i).toString()
    }));



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);


        if (!checkAgreements()) {
            swalToast("Check Again!", "You must agree to all the terms", "info");
            setLoading(false);
            return;
        }



        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);

        }

        setValidated(true);
        const formData = new FormData(e.target);
        const formObject = {};
        formData.forEach((value, key) => {
            formObject[key] = value;
        });



        const paymentData = {
            createPaymentRequest: {
                locale: "string",
                conversationId: "string",
                price: 13.5,
                paidPrice: 13.5,
                installment: 1,
                paymentChannel: "string",
                basketId: "B67832",
                paymentGroup: "string",
                paymentCard: {
                    cardHolderName: formObject.cardHolderName || "",
                    cardNumber: formObject.cardNumber || "",
                    expireYear: formObject.expireYear || "",
                    expireMonth: formObject.expireMonth || "",
                    cvc: formObject.cvc || "",
                    registerCard: 0,
                    cardAlias: "string",
                    cardToken: "string",
                    cardUserKey: "string",
                    metadata: {
                        additionalProp1: "string",
                        additionalProp2: "string",
                        additionalProp3: "string"
                    },
                    consumerToken: "string",
                    registerConsumerCard: true,
                    ucsToken: "string"
                },
                buyer: {
                    id: "string",
                    name: "string",
                    surname: "string",
                    identityNumber: "string",
                    email: "",
                    gsmNumber: "string",
                    registrationDate: "string",
                    lastLoginDate: "string",
                    registrationAddress: "Nidakule Goztepe, Merdivenkoy Mah. Bora Sok. No:1",
                    city: userLocaleInfo?.city || "string",
                    country: userLocaleInfo?.country_name || "string",
                    zipCode: userLocaleInfo?.postal || "string",
                    ip: userLocaleInfo?.ip || "string"
                },
                shippingAddress: {
                    address: "string",
                    zipCode: "string",
                    contactName: "string",
                    city: "string",
                    country: "string"
                },
                billingAddress: {
                    address: "string",
                    zipCode: "string",
                    contactName: "string",
                    city: "string",
                    country: "string"
                },
                basketItems: [
                    {
                        id: "BI101",
                        price: 13.5,
                        name: "Binocular",
                        category1: "Collectibles",
                        category2: "Accessories",
                        itemType: "string",
                        subMerchantKey: "string",
                        subMerchantPrice: 12.5,
                        chargedFromMerchant: false
                    }
                ],
                paymentSource: "string",
                currency: "string",
                posOrderId: "string",
                connectorName: "string",
                callbackUrl: "string",
                gsmNumber: "string",
                reward: {
                    rewardAmount: 0,
                    rewardUsage: 0
                }
            }
        };


        if (!formObject.cardNumber || !formObject.cardHolderName || !formObject.expireMonth || !formObject.expireYear || !formObject.cvc) {
            swalToast("Check Again!", "Please fill in all fields", "info")
            return;
        }
        if (formObject.cvc.length > 3) {
            swalToast("Check Again!", "CVC must be 3 digits", "info")
            return;
        }
        if (formObject.cardNumber.length > 16) {
            swalToast("Check Again!", "Card number must be 16 digits", "info")
            return;
        }

        try {
            const resp = await addPaymentInfo("Zupsie", "Asistant", paymentData);
            console.log(resp);
            setPaymentResponse(resp.data);

            const callbackID = await getCallbackId()
            console.log(accessToken);
            const webhookResponse = await fetch('https://apizupsie.site:9090/webhook/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    callbackId: callbackID.data,
                }),
            });
            setPaymentResponse(null);
            const webhookResponseBody = await webhookResponse.json();
            console.log(webhookResponseBody);
            setStatusInfo(webhookResponseBody.status);


            const intervalId = setInterval(async () => {
                setIsProcessing(true);
                if (webhookResponseBody.status === "success") {
                    setLoading(false);
                    setStatusInfo(webhookResponseBody.status);
                    clearInterval(intervalId);

                    try {
                        await packageInfo(packageReportForm);
                        await permissions(agreements)
                        navigate("/user");
                        swalToast("Congratulations", "Payment transaction succeeded", "success");
                        console.log("basarili islem oldu");
                        setPaymentResponse(null);
                        setIsProcessing(false);
                    } catch (error) {
                        console.error('Webhook error:', error);
                        swalToast("Sorry", `Payment transaction failed. ${error.message}`, "error");
                        setStatusInfo(null);
                        setPaymentResponse(null);
                        setLoading(false);
                        setIsProcessing(false);
                    }
                } else {
                    console.error('Webhook error:');
                    setIsProcessing(false);
                }
            }, 2000);

        } catch (error) {
            console.log(error);
            swalToast("Sorry", "Payment transaction failed", "error");
            setPaymentResponse(null);
            setLoading(false);
        }

    };


    console.log(agreements);


    return (
        <div id='package-payment-form'>
            <div id="payment-info" className='box-style'>
                <span className='top-title'>Payment Informations</span>
                <div className="payment-summary">
                    <span>Payment Summary</span>
                    <span>Advenced Plan</span>
                </div>
                <div className="sub-total">
                    <div>
                        <span>Subtotal</span>
                        <span>
                            {
                                id === "buy-main-plan" && "199$"
                            }
                            {
                                id === "buy-advanced-plan" && "299$"
                            }
                        </span>
                    </div>
                    <div>
                        <span>VAT and Fees</span>
                        <span>0$</span>
                    </div>
                </div>

                <div className="total">
                    <span>Total</span>
                    <span>
                        {
                            id === "buy-main-plan" && "199$"
                        }
                        {
                            id === "buy-advanced-plan" && "299$"
                        }
                    </span>
                </div>
                <div className="payment-form box-style">

                    {
                        !paymentResponse && <div id='payment-form'>

                            <Form noValidate validated={validated} onSubmit={handleSubmit} className='d-flex flex-column gap-4'>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter Name on Card'
                                        name='cardHolderName'
                                        required
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>You must enter Card Holder Name!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Card Number</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter Card Number'
                                        name='cardNumber'
                                        required
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>You must enter Card Number!</Form.Control.Feedback>
                                </Form.Group>
                                <div className='d-flex gap-3'>
                                    <Form.Group>
                                        <Form.Label>Month</Form.Label>
                                        <Form.Control as="select" name="expireMonth" required>
                                            <option value="">Select Month</option>
                                            {months.map((month, index) => (
                                                <option key={index} value={month.value}>{month.label}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control as="select" name="expireYear" required>
                                            <option value="">Select Year</option>
                                            {years.map((year, index) => (
                                                <option key={index} value={year.value}>{year.label}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>CVC</Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder='Enter CVC'
                                            name='cvc'
                                            required
                                        />
                                    </Form.Group>
                                </div>
                                <Form.Group className='d-flex align-items-center gap-2 '>
                                    <Form.Check
                                        className='mb-1'
                                        type='checkbox'
                                        name='preliminary'
                                        checked={agreements.preliminary}
                                        onChange={handleChangeAgreements}
                                    />
                                    <Form.Label className='mb-0'>I have read the <span className='color-text'>preliminary information form</span>. I approve.</Form.Label>
                                </Form.Group>
                                <Form.Group className='d-flex align-items-center gap-2 '>
                                    <Form.Check
                                        className='mb-1'
                                        type='checkbox'
                                        name='distanceSales'
                                        checked={agreements.distanceSales}
                                        onChange={handleChangeAgreements}
                                    />
                                    <Form.Label className='mb-0'>I have read the <span className="color-text">distance sales agreement</span>. I approve.</Form.Label>
                                </Form.Group>
                                <Form.Group className='d-flex align-items-center gap-2 '>
                                    <Form.Check
                                        className='mb-1'
                                        type='checkbox'
                                        name='videoCall'
                                        checked={agreements.videoCall}
                                        onChange={handleChangeAgreements}
                                    />
                                    <Form.Label className='mb-0'>I have read the <span className="color-text">video call agreement</span>. I approve."</Form.Label>
                                </Form.Group>
                                <div className="amount">
                                    <span>Total Amount :</span>
                                    <span>
                                        {
                                            id === "buy-main-plan" && "199$"
                                        }
                                        {
                                            id === "buy-advanced-plan" && "299$"
                                        }
                                    </span>
                                </div>
                                <Button type='submit' className="button">Complete</Button>

                            </Form>

                        </div >
                    }

                    {
                        paymentResponse && !isProcessing && <iframe
                            title='payment'
                            srcDoc={paymentResponse}
                            width="100%"
                            height="500px"
                            ref={iframeRef}
                        />
                    }
                    {isProcessing && <div><Loading /> Processing... Please Wait...</div>}
                </div>
            </div>
        </div>
    )
}

export default PackagePaymentForm
