



import React, { useEffect, useState } from 'react'
import "./meeting-card.scss"
import { FaCheck } from "react-icons/fa6";
import { PiTimer } from "react-icons/pi";
import { FiRefreshCcw } from "react-icons/fi";
import { CgMediaLive } from "react-icons/cg";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDoctorImage } from '../../../api/user-services/doctor-services';
import { encryptedSessionStorage } from '../../../helpers/functions/encrypt-storage';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSelectedDoctor } from '../../../store/doctor-slice/doctor-slice';
import { swalToast } from '../../../swalMessages/swalMessages';
import { setAppointmentDate, setAppointmentOnlyDate, setAppointmentOnlyHour } from '../../../store/appointment-slice/appointment-slice';
import Loading from '../../loading/Loading';
const MeetingCard = ({ item }) => {
    const [isMeetingReady, setIsMeetingReady] = useState(true)
    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const pathname = useLocation().pathname
    // console.log(pathname);

    const loadData = async () => {
        setLoading(true)
        try {

            if (!item?.doctornamesurname) {
                console.error('Doctor name is undefined.');
                setLoading(false);
                return;
            }


            const resp = await getDoctorImage(item?.doctornamesurname);
            const imageUrl = resp.config.url;
            const accessToken = encryptedSessionStorage.getItem("accessToken");
            const response = await axios.get(imageUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                const data = `data:${response.headers['content-type']};base64,${await convertBlobToBase64(response.data)}`;
                setImgSrc(data);
                setLoading(false)
            } else {
                console.error('Image fetch failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        loadData();
    }, [imgSrc]);

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
        });
    };



    const [day, month, year] = item.bookdate.split("-").map(Number);
    const [hour, minute] = item.bookedTimeslot.split(":").map(str => parseInt(str.trim(), 10));

    const appointmentDate = new Date(year, month - 1, day, hour, minute);
    const currentDate = new Date();

    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    console.log(appointmentDate);

    const handleDoctorName = () => {
        dispatch(setSelectedDoctor(item?.doctornamesurname))
        dispatch(setAppointmentOnlyDate(item?.bookdate))
        dispatch(setAppointmentDate(`${item?.bookdate} ${item?.bookedTimeslot}`))
        dispatch(setAppointmentOnlyHour(item?.bookedTimeslot))

        // if (lessThanMinute) {
        //     setIsMeetingReady(false)
        //     navigate(`/user/meetings-main/${item?.doctoruuid}/meeting-start`)
        // } else {
        //     if (approaching || appointmentIsToday) {
        //         navigate(`/user/meetings-main/${item?.doctoruuid}`)
        //     }
        // }

        navigate(`/user/meetings-main/${item?.doctoruuid}`)
    }


    //appointment is today
    const appointmentIsToday = (day === currentDay && month === currentMonth && year === currentYear) && (hour < currentHour || (currentHour === hour - 1 && minute === 0 && 60 - currentMinute > 15));

    //approaching
    const approaching = appointmentDate > currentDate;

    //past appointments
    const pastAppointments = appointmentDate < currentDate;

    //lessThanMinute
    const lessThanMinute = (day === currentDay && month === currentMonth && year === currentYear) && (hour < currentHour || (currentHour === hour - 1 && minute === 0 && 60 - currentMinute <= 15));






    return (
        <div id='meeting-card' className='box-style'>
            <div className="top">
                <div className="doc-info">
                    <div className="doc-img">
                        {
                            loading ? <Loading size={"sm"} /> : <img src={imgSrc} alt="" />
                        }
                    </div>
                    <span>{item?.doctornamesurname}</span>
                </div>
                <div className="meeting-statu" style={{ backgroundColor: pastAppointments ? "grey" : "#2097f3" }}>
                    {
                        pastAppointments ? <FaCheck /> : <FiRefreshCcw />
                    }

                </div>
            </div>
            <div className="bottom">
                <span>Meeting</span>
                <div className="meeting-detail">
                    <div>
                        <span>{item?.bookdate}</span>
                        <span>{item?.bookedTimeslot}</span>
                    </div>
                    <div>
                        <PiTimer />
                        <span>30 Minute</span>
                    </div>
                </div>
            </div>
            <button onClick={handleDoctorName} style={{ backgroundColor: lessThanMinute ? "red" : pastAppointments ? "grey" : "" }} disabled={pastAppointments} >

                {
                    lessThanMinute && <div >
                        <FiRefreshCcw /> Ready To Meet / Start
                    </div>
                }
                {
                    appointmentIsToday && <div >
                        <FiRefreshCcw /> Appointment is Today
                    </div>
                }


                {
                    approaching && <div>
                        <FiRefreshCcw /> Approaching
                    </div>
                }
                {
                    pastAppointments && <div>
                        <FiRefreshCcw /> Completed
                    </div>
                }




            </button>
        </div>
    )
}

export default MeetingCard
