import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/style.scss';
import { Provider as StoreProvider } from 'react-redux';
import App from './App';
import store from './store/store';
import axios from 'axios';
import AxiosInstance from './helpers/functions/index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>
);

