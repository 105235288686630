import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;

//personal info

export const addPersonalInfos = (payload) => {
    return axios.post(`${API_URL}profileinfo/mypersonelinformation`, payload);
}
export const getPersonalInfos = () => {
    return axios.get(`${API_URL}profileinfo/mypersonelinformation`);
}
export const updatePersonalInfos = (payload) => {
    return axios.put(`${API_URL}profileinfo/update/mypersonelinformation`, payload);
}

//contact info

export const getContactInfos = () => {
    return axios.get(`${API_URL}profileinfo/retrieve/mycontactinformation`);
}
export const addContactInfos = (payload) => {
    return axios.post(`${API_URL}profileinfo/create/mycontactinformation`, payload);
}
export const updateContactInfos = (payload) => {
    return axios.put(`${API_URL}profileinfo/update/mycontactformation`, payload);
}

//emergency

export const getEmergencyInfos = () => {
    return axios.get(`${API_URL}profileinfo/myemergencycontactinformation`);
}
export const addEmergencyInfos = (payload) => {
    return axios.post(`${API_URL}profileinfo/myemergencycontactinformation`, payload);
}
export const updateEmergencyInfos = (payload) => {
    return axios.put(`${API_URL}profileinfo/update/myemergencycontactformation`, payload);
}

//general'info

export const getGeneralInfos = () => {
    return axios.get(`${API_URL}generalinfo`);
}
export const addGeneralInfos = (payload) => {
    return axios.post(`${API_URL}generalinfo/information`, payload);
}
export const updateGeneralInfos = (payload) => {
    return axios.put(`${API_URL}generalinfo/update/information`, payload);
}

//summary profile info

export const getSummaryProfileInfo = () => {
    return axios.get(`${API_URL}profileinfo/summaryprofileinfo`);
}