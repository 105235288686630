import React from 'react'
import Vaccinations from '../../../../../../components/user/profile/link-pages/health-history/vaccinations/Vaccinations'

const VaccinationsPage = () => {
    return (
        <div>
            <Vaccinations />
        </div>
    )
}

export default VaccinationsPage
