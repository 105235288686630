import React from 'react'
import "./plans.scss"


//icons
import { IoWalletOutline } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { CiVideoOn } from "react-icons/ci";
import { RiMentalHealthFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
const Plans = () => {
    return (
        <div id='plans' className='box-style'>
            <div className="balance-box">
                <IoWalletOutline />
                <div>
                    <span>Zupsie Balance</span>
                    <span>You can bu credit for meeting. </span>
                    <Link to="credit-options"><span>Buy Credit <CiCirclePlus /></span></Link>
                </div>
                <span>0 Credit</span>
            </div>
            <div className="line"></div>
            <div className="singular-box">
                <CiVideoOn />
                <span>My meeting rights</span>
                <span>1 meeting</span>
            </div>
            <div className="line"></div>
            <div className="credit-info">
                <h5>How many credits are expert opinions on Zupsie?</h5>
                <div className="list">
                    <div className="list-item">
                        <RiMentalHealthFill />
                        <div>
                            <span>Mental Health</span>
                            <div>
                                <span>Psychological Counselor</span>
                                <span> <span>1 Credit</span> / meeting</span>
                            </div>
                            <div>
                                <span>Clinical Psychologist</span>
                                <span><span>2 Credit</span> / meeting</span>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <RiMentalHealthFill />
                        <div>
                            <span>Mental Health</span>
                            <div>
                                <span>Psychological Counselor</span>
                                <span> <span>1 Credit</span> / meeting</span>
                            </div>
                            <div>
                                <span>Clinical Psychologist</span>
                                <span><span>2 Credit</span> / meeting</span>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <RiMentalHealthFill />
                        <div>
                            <span>Mental Health</span>
                            <div>
                                <span>Psychological Counselor</span>
                                <span> <span>1 Credit</span> / meeting</span>
                            </div>
                            <div>
                                <span>Clinical Psychologist</span>
                                <span><span>2 Credit</span> / meeting</span>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <RiMentalHealthFill />
                        <div>
                            <span>Mental Health</span>
                            <div>
                                <span>Psychological Counselor</span>
                                <span> <span>1 Credit</span> / meeting</span>
                            </div>
                            <div>
                                <span>Clinical Psychologist</span>
                                <span><span>2 Credit</span> / meeting</span>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <RiMentalHealthFill />
                        <div>
                            <span>Mental Health</span>
                            <div>
                                <span>Psychological Counselor</span>
                                <span> <span>1 Credit</span> / meeting</span>
                            </div>
                            <div>
                                <span>Clinical Psychologist</span>
                                <span><span>2 Credit</span> / meeting</span>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <RiMentalHealthFill />
                        <div>
                            <span>Mental Health</span>
                            <div>
                                <span>Psychological Counselor</span>
                                <span> <span>1 Credit</span> / meeting</span>
                            </div>
                            <div>
                                <span>Clinical Psychologist</span>
                                <span><span>2 Credit</span> / meeting</span>
                            </div>
                        </div>
                    </div>
                </div>

                <Link to="credit-options" className='button my-5'>
                    Buy Credit
                </Link>
            </div>


        </div>
    )
}

export default Plans
