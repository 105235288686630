import React, { useEffect, useState } from 'react'
import AddOtherResults from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/other-results/add-other-results/AddOtherResults'
import { getOtherResults } from '../../../../../api/user-services/healt-info-services'

const AddOtherResultsPage = () => {
    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getOtherResults()
            setData(resp.data)
        } catch (error) {
            console.log("otherresult cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);
    return (
        <div>
            <AddOtherResults data={data} />
        </div>
    )
}

export default AddOtherResultsPage
