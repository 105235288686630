import React from 'react'
import "./packages-main-page.scss"
import PackageCard from '../package-card/PackageCard'
import PackageCard2 from '../package-card/PackageCard2'
const PackageMainPage = () => {
    return (
        <div id='package-main-page'>
            <span>Packages</span>
            <PackageCard />
            <PackageCard2 />
        </div>
    )
}

export default PackageMainPage
