

import React, { useEffect, useState } from 'react'
import "./meeting-main-container.scss"
import MeetingDocCard from '../meeting-doc-card/MeetingDocCard'
import MeetingCard from '../../user/meeting-card/MeetingCard'
import { Link } from 'react-router-dom'
import { getAppointmentByUser } from '../../../api/user-services/appointment-services'
import { useDispatch } from 'react-redux'
import { setSelectedDoctor } from '../../../store/doctor-slice/doctor-slice'
import { setAppointmentDate } from '../../../store/appointment-slice/appointment-slice'
import Loading from '../../loading/Loading'

const MeetingMainContainer = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [pastBtn, setPastBtn] = useState(false)
    const [upcomingBtn, setUpcomingBtn] = useState(true)
    const [pastAppointments, setPastAppointments] = useState([]);
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    const dispatch = useDispatch();

    const username = sessionStorage.getItem("user").replace(/^"(.*)"$/, '$1');

    const loadData = async () => {
        setLoading(true)
        try {
            const resp = await getAppointmentByUser(username);
            setData(resp.data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("Meetingleri çekerken hata oluştu");
        }
    }

    const today = new Date();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        const past = [];
        const upcoming = [];

        data?.forEach(item => {
            const [day, month, year] = item.bookdate.split("-").map(Number);
            const [hour, minute] = item.bookedTimeslot.split(":").map(str => parseInt(str.trim(), 10));

            const appointmentDate = new Date(year, month - 1, day, hour, minute);
            const currentDate = new Date();

            console.log(appointmentDate);
            console.log(currentDate);

            if (appointmentDate < currentDate) {
                past.push(item)
            } else {
                upcoming.push(item)
            }
        });

        setPastAppointments(past);
        setUpcomingAppointments(upcoming);
    }, [data]);

    const selectDoctor = (docname, date, hour) => {
        dispatch(setSelectedDoctor(docname));
        dispatch(setAppointmentDate(`${date} ${hour}`));
    }

    const handlePastAppointments = () => {
        setPastBtn(true);
        setUpcomingBtn(false);
    }

    const handleUpcomingAppointments = () => {
        setUpcomingBtn(true);
        setPastBtn(false);
    }

    console.log(pastAppointments);
    console.log(upcomingAppointments);

    return (
        <div id='meeting-main-container' className='box-style'>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="top">
                        <div className="meetings-info">
                            <span
                                onClick={handleUpcomingAppointments}
                                style={{ backgroundColor: upcomingBtn ? "#041734" : "" }}
                            >
                                Upcoming Appointments ({upcomingAppointments?.length})
                            </span>
                            <span
                                onClick={handlePastAppointments}
                                style={{ backgroundColor: pastBtn ? "#041734" : "" }}
                            >
                                Past Appointments ({pastAppointments?.length})
                            </span>
                        </div>
                    </div>
                    <div className="meeting-info-card d-flex flex-column gap-3 mt-5">
                        {upcomingBtn && upcomingAppointments?.map((item, i) => (
                            <div key={i} onClick={() => selectDoctor(item?.doctornamesurname, item?.bookdate, item?.bookedTimeslot)}>
                                <MeetingCard key={i} item={item} />
                            </div>
                        ))}
                        {upcomingBtn && upcomingAppointments.length === 0 && <span className='text-center'>There is no result</span>}
                        {pastBtn && pastAppointments.map((item, i) => (
                            <div key={i} onClick={() => selectDoctor(item?.doctornamesurname, item?.bookdate, item?.bookedTimeslot)}>
                                <MeetingCard key={i} item={item} />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default MeetingMainContainer;
