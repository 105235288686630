import React, { useEffect, useState } from 'react'
import "./doctor-card-mobile.scss"
import { CiStar } from "react-icons/ci";
import { getAllDoctorsBySpecialities, getDoctorImage } from '../../../api/user-services/doctor-services';
import { encryptedSessionStorage } from '../../../helpers/functions/encrypt-storage';
import flag from "../../../assets/tflag.png"

import axios from 'axios';
import Loading from '../../loading/Loading';
const DoctorCardMobile = ({ docs }) => {

    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(false)

    const loadData = async () => {
        setLoading(true)
        try {
            const resp = await getDoctorImage(docs?.firstname + " " + docs?.lastname)
            console.log(resp);
            const imageUrl = resp.config.url;
            const accessToken = encryptedSessionStorage.getItem("accessToken");
            const response = await axios.get(imageUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                const data = `data:${response.headers['content-type']};base64,${await convertBlobToBase64(response.data)}`;
                setImgSrc(data);
                setLoading(false)
            } else {
                console.error('Image fetch failed:', response.statusText);
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [docs?.firstname, docs?.lastname, imgSrc])

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
        });
    };


    return (
        <div id='doctor-card-mobile' className='box-style'>
            <div className="card-content">
                <div className='doc-image'>
                    {
                        loading ? <Loading /> : <img src={imgSrc} alt="" />
                    }

                </div>
                <div className='doc-flag'>
                    <img src={flag} alt="" />
                </div>
            </div>
            <div className="doc-info">
                <span>{docs?.title}</span>
                <span>
                    {docs?.firstname} {docs?.lastname}
                </span>
                <span>{docs?.speciality}</span>
            </div>
            {/* <div className="doc-opinion">
                <div className="stars">
                    <CiStar />
                    <CiStar />
                    <CiStar />
                    <CiStar />
                    <CiStar />
                </div>
                <span>12 opinion</span>
            </div> */}
        </div>
    )
}

export default DoctorCardMobile
