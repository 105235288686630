import React from 'react'
import LegalInfoLinks from '../../../../../components/user/profile/link-pages-account/legal-infos/LegalInfoLinks'

const LegalInformationPage = () => {
    return (
        <div id='legal-information-page'>
            <LegalInfoLinks />
        </div>
    )
}

export default LegalInformationPage
