import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { CiSearch } from "react-icons/ci";
import "./search-category.scss"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from '../../../store/search-slice/search-slice';
const SearchCategory = ({ data }) => {

    const navigate = useNavigate()
    const [categoryEntered, setCategoryEntered] = useState("")
    const searchCategory = useSelector(state => state.search.search)
    const dispatch = useDispatch()

    const handleSearch = (e) => {
        e.preventDefault()
        const { value } = e.target
        dispatch(setSearch(value))
        setCategoryEntered(value)

    }

    console.log(categoryEntered);

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/user/search/${searchedCategory[0]}`);
        if (e.key === 'Enter') {
            navigate(`/user/search/${searchedCategory[0]}`);
        }

    };




    const searchedCategory = data
        ? [...new Set(data.filter((doctor) => doctor.title.toLowerCase().includes(searchCategory.toLowerCase())).map((cat) => cat.title))]
        : [];

    console.log(searchedCategory[0]);




    return (
        <div id='search-category'>
            <Form onSubmit={handleSubmit}>
                <Form.Control
                    type='text'
                    placeholder='Search for a specialization'
                    onChange={handleSearch}
                />
                <Link to={`/user/search/${searchedCategory[0]}`}><CiSearch /></Link>
            </Form>
            {
                searchCategory !== "" && categoryEntered.length > 0 && searchedCategory.length > 0 && <Link to={`/user/search/${searchedCategory[0]}`}>
                    <div className="searched-hint"><span>Did you mean to search for this?</span> - {searchedCategory[0]}</div>
                </Link>
            }
            {
                categoryEntered.length > 0 && searchedCategory.length === 0 &&
                <Link to={`/user/search/${categoryEntered}`}>
                    <div className="searched-hint">{categoryEntered}</div>
                </Link>

            }

        </div>
    )
}

export default SearchCategory
