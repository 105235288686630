import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: ""
}

export const searchInput = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.search = action.payload
        }
    }
})

export const { setSearch } = searchInput.actions;
export default searchInput.reducer