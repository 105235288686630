import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;

export const getAvailbleSlot = (doctorname, username) => {
    return axios.get(`${API_URL}appointment/availableSlots?doctorName=${doctorname}&userName=${username}`);
}

export const bookAppointment = (doctorname, username, payload) => {
    return axios.post(`${API_URL}appointment/book/availableslot?doctorName=${doctorname}&userName=${username}`, payload);
}


export const getAppointmentByUser = (username) => {
    return axios.get(`${API_URL}appointment/bookedSlotsByUser?userName=${username}`);
}



export const postReportType = (payload) => {
    return axios.post(`${API_URL}report/type`, { payload })
}
export const getReportType = () => {
    return axios.get(`${API_URL}report/type`)
}