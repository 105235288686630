import React, { useEffect } from 'react'
import "./discover-detail-item.scss"
import { IoNewspaperOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setContent } from '../../../../store/discover-content-slice/discover-content-slice'
const DiscoverDetailItem = ({ item }) => {

    console.log(item);

    const dispatch = useDispatch()



    useEffect(() => {
        dispatch(setContent(item))
    }, [])




    return (
        <>
            {
                item.map((content, i) => (
                    <Link to={content?.uuid} key={i}>
                        <div id='discover-detail-item' className='box-style'>
                            <div className="discover-detail-item-img">
                                <img src={content?.discoveritemimage} alt={content?.title} />
                            </div>
                            <div className="discover-detail-item-content">
                                <span>{content?.title}</span>
                                <div>
                                    {
                                        content.textUrl && (
                                            <>
                                                <span><IoNewspaperOutline /> Read</span>
                                                <span>13 dakika</span>
                                            </>
                                        )
                                    }
                                    {
                                        content.podcastUrl && (
                                            <>
                                                <span><IoNewspaperOutline /> Listen</span>
                                                <span>13 dakika</span>
                                            </>
                                        )
                                    }
                                    {
                                        content.videoUrl && (
                                            <>
                                                <span><IoNewspaperOutline /> Watch</span>
                                                <span>13 dakika</span>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Link>
                ))
            }
        </>
    )
}

export default DiscoverDetailItem
