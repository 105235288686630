import React, { useEffect } from 'react'
import DiscoverContentDetails from '../../../components/containers/discover/discover-content-details/DiscoverContentDetails'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const DiscoverContentDetailsPage = () => {

    const id = useParams().id
    const currentContent = useSelector(state => state.content.content)



    const content = currentContent?.find((item) => item.uuid === id)

    console.log(content);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id='discover-content-details'>
            <DiscoverContentDetails content={content} />
        </div>
    )
}

export default DiscoverContentDetailsPage
