import React from 'react'
import AddAllergies from '../../../../../../components/user/profile/link-pages/health-history/allergies/add-allergies/AddAllergies'


const AddAllergiesPage = () => {
    return (
        <div>
            <AddAllergies />
        </div>
    )
}

export default AddAllergiesPage
