import React from 'react'

const Spacer = ({ height = 50 }) => {
    return (
        <div id='spacer' style={{ height: `${height}px` }}>

        </div>
    )
}

export default Spacer
