import React from 'react'
import "./user-navbar.scss"
import { Link } from 'react-router-dom'


const UserNavbar = () => {
    return (
        <nav id='user-navbar-links'>
            <Link to='/user'>Home</Link>
            <Link to="meetings-main">Meetings</Link>
            <Link to="discover">Discover</Link>
            <Link to="profile">Profile</Link>
            <Link to="">Favorites</Link>
        </nav>
    )
}

export default UserNavbar
