import axios from 'axios';
import { encryptedSessionStorage } from "./encrypt-storage";
// import { signOut } from '../../api/user-services/user-services';

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/html',
    },
});


axios.interceptors.request.use(
    (config) => {
        //console.log('Interceptor called');
        const accessToken = encryptedSessionStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
            //console.log('Access token added to headers:', accessToken);
        }
        return config;
    },
    (error) => {
        //console.log('Interceptor error:', error);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        //console.log('Interceptor response:', response);
        return response;
    },
    async (error) => {
        //console.log('Interceptor response error:', error);
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = sessionStorage.getItem('refreshToken');
            try {
                const res = await axios.post(`${API_URL}auth/refreshtoken`, { refreshToken });
                if (res.data.accessToken) {
                    encryptedSessionStorage.setItem('accessToken', res.data.accessToken);
                    originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`;
                    //console.log("accessToken guncellendi");
                    return axiosInstance(originalRequest);
                }
            } catch (err) {
                sessionStorage.removeItem('accessToken');
                sessionStorage.removeItem('refreshToken');
                window.location.href = '/';
                return Promise.reject(err);
            }
        }
        return Promise.reject(error);
    }
);

//console.log('Interceptor registration completed');

export default axiosInstance;


