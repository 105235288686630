import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = ({ size }) => {
    return (
        <div id="loading" className='d-flex justify-content-center'>
            <Spinner animation="border" variant="light" size={size} />
        </div>
    )
}

export default Loading
