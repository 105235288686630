import React, { useState } from 'react'
import "./sss.scss"
import { Accordion, Form } from 'react-bootstrap'
import { BiSearch } from 'react-icons/bi'

const SSS = () => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const faqData = [
        {
            question: 'How do I create an account?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        },
        {
            question: 'What is required to register?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        },
        {
            question: 'What services can I benefit from?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        },
        {
            question: 'In which fields can I consult with experts?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        },
        {
            question: 'How do payment transactions proceed?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        },
    ];




    return (
        <div id='sss'>
            <Form>
                <Form.Control
                    type='search'
                    placeholder='Search questions'
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <BiSearch />
            </Form>
            <Accordion>
                {faqData
                    .filter((faq) =>
                        faq.question.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((faq, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
            </Accordion>
        </div>
    )
}

export default SSS
