import React from 'react'
import CouponAndRights from '../../../../../components/user/profile/link-pages-account/coupon-and-rights/CouponAndRights'

const CouponAndRightsPage = () => {
    return (
        <div id='coupon-and-rights-page'>
            <CouponAndRights />
        </div>
    )
}

export default CouponAndRightsPage
