import React from 'react'
import Surgeries from '../../../../../../components/user/profile/link-pages/health-history/surgeries/Surgeries'

const SurgeriesPage = () => {
    return (
        <div>
            <Surgeries />
        </div>
    )
}

export default SurgeriesPage
