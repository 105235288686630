import React from 'react'
import "./discover.scss"
import ForYouCard from '../../user/for-you-card/ForYouCard'
import DiscoverUnitCard from '../../user/discover/discover-unit-card/DiscoverUnitCard'
import { Link } from 'react-router-dom'
const Discover = ({ data }) => {

    return (
        <div id='discover' className='box-style'>
            <div>
                <div className="discover-content">
                    {
                        data?.map((item) => (
                            <Link to={item.uuid} key={item.uuid}>
                                <DiscoverUnitCard item={item} />
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Discover
