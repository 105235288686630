import React, { useEffect, useState } from 'react'
import "./add-medicines.scss"

//icons
import { PiUploadSimple } from "react-icons/pi";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { Button, Form } from 'react-bootstrap';
import { MdDeleteOutline, MdFileDownload } from "react-icons/md";
import { swalToast } from '../../../../../../swalMessages/swalMessages';
import { addMedicineImage, addMedicineInfo } from '../../../../../../api/user-services/healt-info-services';
import { useNavigate } from 'react-router-dom';


const AddMedicines = ({ data }) => {
    const username = JSON.parse(sessionStorage.getItem("user"));
    const navigate = useNavigate()
    const [images, setImages] = useState([]);
    const [imageIds, setImageIds] = useState([])
    const [info, setInfo] = useState(
        {
            uuid: "",
            username: JSON.parse(sessionStorage.getItem("user")),
            medicine: "",
            boxAmount: "",
            period: "",
            instructions: "",
            usagemethod: "",
            date: "",
            currentlyUsed: false,
            fileuuid: []
        }
    )

    const handleChance = (e) => {
        const { name, checked, value } = e.target;
        const newValue = name === 'currentlyUsed' ? checked : value;

        setInfo({ ...info, [name]: newValue });
    }




    const handleUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;
        input.onchange = (e) => {
            const selectedFiles = e.target.files;
            if (selectedFiles.length > 0) {
                const fileNames = Array.from(selectedFiles).map(file => file.name);
                setImages(prevImages => [...prevImages, ...selectedFiles]);
            }
        };
        input.click();

        images.map(async (image) => {
            const imagename = image.name;
            const formData = new FormData();
            formData.append("file", image);

            try {
                const respImage = await addMedicineImage(imagename, username, formData);
                const imageId = respImage.data.message.split(":")[1].trim();
                setImageIds((prevImageIds) => [...prevImageIds, imageId]);
                console.log(respImage.data);
            } catch (error) {
                console.log("image eklenemedi");
            }
        });
    };

    const handleDelete = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const handleDownload = (file) => {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
    };

    useEffect(() => {
        setInfo({ ...info, fileuuid: imageIds });
    }, [imageIds]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addMedicineInfo(info);
            swalToast("Success", "Saved");
            navigate(-1)
        } catch (error) {
            swalToast("Error", "Something went wrong", "error");
        }
    };


    console.log(info);
    console.log(images);
    console.log(imageIds);

    // useEffect(() => {
    //     if (data) {
    //         setInfo({
    //             ...info,
    //             medicine: data?.medicine,
    //             boxAmount: data?.boxAmount,
    //             period: data?.period,
    //             instructions: data?.instructions,
    //             usagemethod: data?.usagemethod,
    //             date: data?.date,
    //             currentlyUsed: data?.currentlyUsed,
    //         })
    //     }
    // }, [data])


    return (
        <div id='add-medicines' className='box-style habits-style'>
            <div className="top-btn">
                <div onClick={handleUpload}>
                    <PiUploadSimple />
                    <span>Upload</span>
                </div>
            </div>

            <div className="uploaded-images">
                {images.length > 0 && (
                    <div className='reports-wrapper'>
                        {images.map((file, index) => (
                            <div key={index}>
                                <img src={URL.createObjectURL(file)} alt={`Uploaded ${index}`} />
                                <span>{file.name}</span>
                                <div className="icons">
                                    <MdFileDownload onClick={() => handleDownload(file)} />
                                    <MdDeleteOutline onClick={() => handleDelete(index)} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <Form className='my-5' onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Medicine</Form.Label>
                    <Form.Control
                        type='text'
                        name='medicine'
                        onChange={handleChance}
                        value={info.medicine}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Box Amount</Form.Label>
                    <div className='form-item'>
                        <Form.Control
                            type='number'
                            name='boxAmount'
                            onChange={handleChance}
                            value={info.boxAmount}
                        />
                        <span>Amount</span>
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Period</Form.Label>
                    <Form.Select
                        name='period'
                        onChange={handleChance}
                        value={info.period}
                    >
                        <option value="">Choose Period</option>
                        <option value="Day">Day</option>
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Instructions</Form.Label>
                    <Form.Select
                        name='instructions'
                        onChange={handleChance}
                        value={info.instructions}
                    >
                        <option value="">Instructions</option>
                        <option value="Before the meal">Before the meal</option>
                        <option value="After the meal">After the meal</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Usage Method</Form.Label>
                    <Form.Select
                        name='usagemethod'
                        onChange={handleChance}
                        value={info.usagemethod}
                    >
                        <option value="">Choose Method</option>
                        <option value="Oral">Oral</option>
                        <option value="Topical">Topical</option>
                        <option value="Inhalation">Inhalation</option>
                        <option value="Injection">Injection</option>
                        <option value="Transdermal">Transdermal</option>
                        <option value="Rectal">Rectal</option>
                        <option value="Vaginal">Vaginal</option>
                        <option value="Ophthalmic">Ophthalmic</option>
                        <option value="Nasal">Nasal</option>
                    </Form.Select>
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Duration of Use</Form.Label>
                    <Form.Control type='text' />
                </Form.Group> */}
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type='date'
                        name='date'
                        onChange={handleChance}
                        value={info.date}
                    />
                </Form.Group>
                <Form.Group className='d-flex align-items-center justify-content-between'>
                    <Form.Label>Currently Used</Form.Label>
                    <Form.Switch
                        name='currentlyUsed'
                        onChange={handleChance}
                        checked={info?.currentlyUsed || false}
                        value={info.currentlyUsed}
                    />
                </Form.Group>
                <Button className='button' type='submit' >Add</Button>
            </Form>

        </div>
    )
}

export default AddMedicines
