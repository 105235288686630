import React, { useEffect, useState } from 'react'
import { CiStar } from 'react-icons/ci'
import "./main-doctor-card.scss"

import { BsCameraVideoFill } from "react-icons/bs";
import { LuCalendarRange } from "react-icons/lu";
import { BsFillQuestionOctagonFill } from "react-icons/bs"
import { FaLocationDot } from "react-icons/fa6";
import DocInfoTabs from '../../containers/doc-info-tabs/DocInfoTabs';


import { MdOutlineQuestionAnswer } from "react-icons/md";
import DoctorCardHead from '../doctor-info/head-info/DoctorCardHead';
import DoctorCardContact from '../doctor-info/contact-infos/DoctorCardContact';
import { getDoctorAdress } from '../../../api/user-services/doctor-services';
import { useSelector } from 'react-redux';
import { getAvailbleSlot } from '../../../api/user-services/appointment-services';
const MainDoctorCard = ({ doctor, id }) => {

    const [adress, setAdress] = useState([])
    const [dateList, setDateList] = useState([])
    const [timeSlotList, setTimeSlotList] = useState([])
    const selectedDoctor = useSelector(state => state.doctor.selectedDoc)
    const username = sessionStorage.getItem("user")
    const cleanedUserName = username.replace(/"/g, "");

    const loadAdress = async () => {
        try {
            const resp = await getDoctorAdress(id || selectedDoctor)
            const avaibleDate = await getAvailbleSlot(id || selectedDoctor, cleanedUserName);
            setDateList(avaibleDate.data.availableDateList);
            setTimeSlotList(avaibleDate.data.availableTimeslotList);
            setAdress(resp.data)
        } catch (error) {
            console.log("error");
        }
    }

    useEffect(() => {
        loadAdress()
    }, [id, selectedDoctor])

    console.log(adress);
    console.log(id);
    console.log(selectedDoctor);
    console.log(dateList);
    console.log(timeSlotList);



    return (
        <div id='main-doctor-card' className='box-style'>
            <DoctorCardHead doctor={doctor} id={id} />
            <DoctorCardContact doctor={doctor} id={id} />
            <div className="options">
                <span> <BsCameraVideoFill /> Online</span>
                <span> <LuCalendarRange /> Meeting</span>
                <span> <BsFillQuestionOctagonFill /> Question</span>
            </div>
            <DocInfoTabs id={id} />
            <div className="detail-infos">
                <div className="left">
                    <span> <FaLocationDot /> {adress?.[0]?.city}</span>
                    <p>{adress?.[0]?.country}</p>
                </div>
                <div className="right">
                    <span>Next available appointment date</span>
                    <span>{dateList?.[0]} / {timeSlotList?.[0]}</span>
                </div>
            </div>
        </div>
    )
}

export default MainDoctorCard
