import React from 'react'
import CommitmentStatus from '../../../../../../components/user/profile/link-pages/health-profile/commitment-status/CommitmentStatus'

const CommitmentStatusPage = () => {
    return (
        <div>
            <CommitmentStatus />
        </div>
    )
}

export default CommitmentStatusPage
