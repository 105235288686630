import React from 'react'
import "./message-list.scss"

import { FaGift } from "react-icons/fa";
import SingleMessageBox from '../../user/message/single-message-box/SingleMessageBox';

const MessageList = () => {
    return (
        <div id='message-list' className='box-style'>
            <div className="top">
                <div>
                    <FaGift />
                    <span>The first consultation is free.</span>
                </div>
            </div>
            <div className='line my-3'></div>
            <div className='message-list'>
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
                <SingleMessageBox />
            </div>
        </div>
    )
}

export default MessageList
