import React, { useEffect, useState } from 'react'
import "./meeting-control.scss"
import DoctorCardHead from '../../doctor-info/head-info/DoctorCardHead'


//icons
import { GoCalendar } from "react-icons/go";
import { IoMdTime } from "react-icons/io";
import { GoDeviceCameraVideo } from "react-icons/go";
import { LuStethoscope } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { getDoctorAdress } from '../../../../api/user-services/doctor-services';

const MeetingControl = ({ meetingInfo, id }) => {

    const doctorName = useSelector(state => state.doctor.selectedDoc)
    const [adress, setAdress] = useState()

    const loadData = async () => {
        try {
            const resp = await getDoctorAdress(doctorName)
            setAdress(resp.data)
        } catch (error) {
            console.log("adres cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    console.log(adress);

    return (
        <div id='meeting-control' className='box-style'>
            <h5>Meeting Details</h5>
            <div className="meeting-control-box">
                <DoctorCardHead />
                <div className='meeting-info-top'>
                    <span>Appointment Date and Time</span>
                    <div>
                        <GoCalendar />
                        <span>{meetingInfo?.bookdate}</span>
                    </div>
                    <div>
                        <IoMdTime />
                        <span>{meetingInfo?.bookedTimeslot}</span>
                    </div>
                </div>
                <div className='meeting-info-bottom'>

                    <span>Service Type</span>
                    <div>
                        <GoDeviceCameraVideo />
                        <span>{meetingInfo?.serviceType}</span>
                    </div>
                    <div className='d-flex justify-content-start '>
                        <LuStethoscope />
                        <span>{meetingInfo?.serviceType}</span>
                        <span className='price'>100$</span>
                    </div>
                    {
                        meetingInfo?.serviceType === "In-Person" && <div>
                            <div>
                                <IoLocationOutline />
                            </div>
                            <span>
                                {adress?.[0]?.address}
                            </span>
                        </div>
                    }
                    <div className='meeting-purpose'>
                        <span>Meeting Purpose</span>
                        <div>{meetingInfo.appointmentPurpose}</div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MeetingControl
