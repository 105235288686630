
import React, { useEffect, useState } from 'react';
import "./doctor-card.scss";
import placeholder from "../../../../assets/placeholder.png";
import { getPopularDocsImageWithoutAuth } from '../../../../api/user-services/doctor-services';

const DoctorCard = ({ doc }) => {
    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(false);

    const loadData = async () => {
        setLoading(true);
        try {
            const resp = await getPopularDocsImageWithoutAuth(doc?.id);
            console.log('Response:', resp);
            if (resp.status === 200) {
                const imageBlob = new Blob([resp.data], { type: 'image/png' });
                const imageUrl = URL.createObjectURL(imageBlob);
                setImgSrc(imageUrl);
                setLoading(false);
            } else {
                console.error('Image fetch failed:', resp.statusText);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (doc?.id) {
            loadData();
        }
    }, [doc?.id]);


    console.log(doc);
    return (
        <div id='doctor-card'>
            <div className="doc-image">
                <img src={imgSrc || placeholder} alt={`${doc?.firstname} ${doc?.lastname}`} />
            </div>
            <div className="doc-info">
                <div className='info-top'>
                    <span className='info-title'>{doc?.title}</span>
                    <span className='name'> {doc?.firstname} {doc?.lastname}</span>
                </div>
                <div className='info-bottom'>
                    <p >{doc?.speciality?.split(",")[0]}</p>
                    <span className='country'>Turkey</span>
                </div>
            </div>
        </div>
    );
};

export default DoctorCard;

