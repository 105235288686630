


import React, { useState, useEffect } from 'react';
import "./family-history.scss";
import { Form } from 'react-bootstrap';
import diseases from "../../../../../../diseases/diseases.json";
import { postFamilyHistory, updateFamilyHistory } from '../../../../../../api/user-services/healt-info-services';
import { swalToast } from '../../../../../../swalMessages/swalMessages';
import { useNavigate } from 'react-router-dom';

const FamilyHistory = ({ data }) => {
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        familyhistoryyitems: [],
        username: JSON.parse(sessionStorage.getItem("user"))
    });

    useEffect(() => {
        if (data?.[0]?.familyhistoryyitems?.length > 0) {
            setInfo((prevInfo) => ({
                ...prevInfo,
                familyhistoryyitems: data[0].familyhistoryyitems,
            }));
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setInfo((prevState) => ({
                ...prevState,
                familyhistoryyitems: [...prevState.familyhistoryyitems, name],
            }));
        } else {
            setInfo((prevState) => ({
                ...prevState,
                familyhistoryyitems: prevState.familyhistoryyitems.filter((item) => item !== name),
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await postFamilyHistory(info);
            swalToast("Saved", "success");
            navigate(-1);
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning");
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            await updateFamilyHistory(info);
            swalToast("Updated", "success");
            navigate(-1);
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning");
        }
    };

    return (
        <div id='family-history' className='box-style'>
            <Form onSubmit={data?.[0]?.familyhistoryyitems?.length > 0 ? handleUpdate : handleSubmit}>
                <Form.Group>
                    {diseases.map((item) => (
                        <div key={item.id} className='form-group-item'>
                            <Form.Label>{item.name}</Form.Label>
                            <Form.Check
                                type='switch'
                                name={item.name}
                                onChange={handleChange}
                                checked={info.familyhistoryyitems.includes(item.name)}
                            />
                        </div>
                    ))}
                </Form.Group>
                <button type='submit' className="button">
                    {data?.[0]?.familyhistoryyitems?.length > 0 ? 'Update' : 'Save'}
                </button>
            </Form>
        </div>
    );
};

export default FamilyHistory;
