import React from 'react'
import ContactInfo from '../../../../../components/user/profile/link-pages-account/profile-info/contact-info/ContactInfo'

const ContactInfoPage = () => {
    return (
        <div>
            <ContactInfo />
        </div>
    )
}

export default ContactInfoPage
