import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "./landing-page.scss"
import HeroSec from '../../../components/main-page/common/hero/HeroSec'
import AboutSystem from '../../../components/main-page/common/about-system/AboutSystem'
import Description from '../../../components/main-page/common/description/Description'
import ServiceProviders from '../../../components/main-page/common/service-providers/ServiceProviders'
import { Container } from 'react-bootstrap'
import WorldwideOpinions from '../../../components/main-page/common/worldwide-opinions/WorldwideOpinions'
import StartManaging from '../../../components/main-page/common/start-managing/StartManaging'

//image
import wwimage from "../../../assets/worldwide.png"
import dashboard from "../../../assets/landing-page/illustrations/care.png"
import emCare from "../../../assets/landing-page/illustrations/em-care.png"
import Login from '../../../components/login-page/login-main/Login'
import SignUpMain from '../../../components/login-page/sign-up-main/SignUpMain'
import PasswordMain from '../../../components/login-page/password-main/PasswordMain'
import { FaChevronUp } from "react-icons/fa";
import { hideModal } from '../../../store/login/modalSlice'
import { getPopularDoctors } from '../../../api/user-services/doctor-services'


const LandingPage = () => {

    const [isSignUpClicked, setIsSignUpClicked] = useState(false)
    const [isLoginClicked, setIsLoginClicked] = useState(false)
    const [isForgotPasswordClicked, setIsForgotPasswordClicked] = useState(false)

    const isModal = useSelector(state => state.modal.isModal);
    const isUserLogin = useSelector(state => state.auth.isAuthenticated)
    const dispatch = useDispatch();

    const scrollTop = () => {
        window.scrollTo(0, 0)
    }




    useEffect(() => {
        isUserLogin && dispatch(hideModal())
    }, [isUserLogin])


    return (
        <div id='landing-page'>
            <div id='hero-div'>
                <section id="hero">
                    <HeroSec />
                </section>
            </div>
            <div id='providers'>
                <ServiceProviders />
            </div>
            <Container>
                <AboutSystem />
            </Container>
            <section id='desc-section'>
                <Description />
            </section>
            {/* <Container>
                <WorldwideOpinions
                    title1={"Expert Opinions Worldwide"}
                    title2={"Anytime, Anywhere Meet with Specialist Doctors!"}
                    text1={"Zupsie makes the valuable insights of expert doctors worldwide quickly accessible, empowering everyone to manage their health decisions more consciously and confidently."}
                    text2={"Discover Zupsie for reliable support in your health journey, backed by a trustworthy and extensive network of specialists."}
                    image={wwimage}

                />
            </Container> */}
            <div id="plans">
                <StartManaging />
            </div>
            <div className="emotional-connect">
                <WorldwideOpinions
                    title1={"Patient Privacy"}
                    title2={""}
                    text1={"you can rest assured that your privacy and data protection are our top priority. Our platform ensures the highest level of security, allowing you to securely upload your cancer diagnosis and medical documents. You can have peace of mind knowing that your sensitive information is in safe hands, giving you the confidence to seek a second opinion on your diagnosis."}
                    text2={""}
                    image={dashboard}
                />
            </div>
            <div className='confront-cancer'>
                <WorldwideOpinions
                    title1={"Patient Privacy"}
                    text1={"Zupsie's emotional support program allows you to share your challenging journey and support each other by pairing you with survivors who have had similar experiences."}
                    text2={"This special connection helps you emotionally strengthen and find hope. Share your own story, find meaning, and empower each other together!"}
                    image={emCare}
                />
            </div>
            <div className='last-comp'>
                <WorldwideOpinions
                    title1={"Every Cancer, Every Life"}
                    title2={"Fighting Together, Healing Together!"}
                    text1={"Find Hope and Guidance in Your Cancer Battle. Access Worldwide Expert Opinions and Connect with Fellow Warriors on Zupsie"}
                />
            </div>
            <Login
                isSignUpClicked={isSignUpClicked}
                setIsSignUpClicked={setIsSignUpClicked}
                isLoginClicked={isLoginClicked}
                setIsLoginClicked={setIsLoginClicked}
                isForgotPasswordClicked={isForgotPasswordClicked}
                setIsForgotPasswordClicked={setIsForgotPasswordClicked}
            />
            <SignUpMain
                isSignUpClicked={isSignUpClicked}
                setIsSignUpClicked={setIsSignUpClicked}
                isLoginClicked={isLoginClicked}
                setIsLoginClicked={setIsLoginClicked} />
            <PasswordMain
                isForgotPasswordClicked={isForgotPasswordClicked}
                setIsForgotPasswordClicked={setIsForgotPasswordClicked}
            />
            <div className="go-up" onClick={scrollTop}><FaChevronUp /></div>
        </div>
    )
}

export default LandingPage
