import React from 'react'
import Spacer from '../../spacer/Spacer'
import Line from '../line/Line'

const SpaceWithLine = () => {
    return (
        <div>
            <Spacer />
            <Line />
            <Spacer />
        </div>
    )
}

export default SpaceWithLine
