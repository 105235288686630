import React from 'react'
import "./create-acc-input.scss"
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { showModalSignUp } from '../../../../store/sign-up/signupSlice'
const CreateAccInput = () => {

    const dispatch = useDispatch()

    const handleSignIn = () => {
        dispatch(showModalSignUp())
    }


    return (
        <div id='create-acc-input'>
            {/* <Form>
                <Form.Control type='text' placeholder='5XX XXX XXXX' disabled />
            </Form> */}
            <button className='landing-btn-lg' onClick={handleSignIn}>
                Get Started
            </button>
        </div>
    )
}

export default CreateAccInput
