import React from 'react'
import "./description.scss"
import { Col, Container, Row } from 'react-bootstrap'
import DescriptionCard from './description-card/DescriptionCard'

//icons
import heartIcon from "../../../../assets/landing-page/icons/heart.svg"
import listPulsIcon from "../../../../assets/landing-page/icons/listPuls.svg"
import listTime from "../../../../assets/landing-page/icons/listTime.svg"

const Description = () => {
    return (
        <Container>

            <div id='description'>
                <div>
                    <h3>We aim to stand by every individual and family fighting against cancer</h3>
                </div>
                <div>
                    <Row className='description-content'>
                        <Col sm={12} lg={4} className='mb-5'>
                            <DescriptionCard
                                title={"Expert Doctor Opinions"}
                                text1={"Connect with top oncologists worldwide for comprehensive evaluations and specialized treatment recommendations."}
                                icon={listPulsIcon}
                            />
                        </Col>
                        <Col sm={12} lg={4} className='mb-5'>
                            <DescriptionCard
                                title={"Fast Appointment "}
                                text1={"Book prompt consultations with oncologists, whether in-person or virtual, with priority scheduling for urgent cases."}
                                icon={listTime}
                            />
                        </Col>
                        <Col sm={12} lg={4} className='mb-5'>
                            <DescriptionCard
                                title={"Emotional Support "}
                                text1={"Access counseling, support groups, and resources to help manage the emotional challenges of cancer diagnosis and treatment."}
                                icon={heartIcon}
                            />
                        </Col>
                    </Row>

                </div>
            </div>
        </Container>
    )
}

export default Description
