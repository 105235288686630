import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from '../components/user/header/Header'
import { Container } from 'react-bootstrap'
import Tabbar from '../components/tabbar/Tabbar'
import { useDispatch } from 'react-redux'

import "./user-layout.scss"

import { IoLogOutOutline } from "react-icons/io5";
import { signOut } from '../api/user-services/user-services'
import { logout } from '../store/auth-slice/authSlice'
import { encryptedSessionStorage } from '../helpers/functions/encrypt-storage'
import { swalToast } from '../swalMessages/swalMessages'
import UserNavbar from '../components/user/user-navbar/UserNavbar'
const UserLayout = () => {

    const [isUserProfile, setIsUserProfile] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const currentLocation = useLocation()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOut = async () => {
        try {
            await signOut();
            dispatch(logout())
            encryptedSessionStorage.clear()
            navigate("/")
            swalToast("Log out successful", "", "success")
        } catch (error) {
            console.log("error");
        }
    }



    useEffect(() => {
        if (currentLocation.pathname.startsWith("/user/profile")) {
            setIsUserProfile(true)
        } else {
            setIsUserProfile(false)
        }

    }, [currentLocation.pathname])


    const username = sessionStorage.getItem("user")

    return (
        <div id='user-layout'>
            <Container>
                <Header />
                <UserNavbar />
                <div className='logout-head'>
                    <div className="user-img">{username?.slice(1, 2)}</div>
                    <div className="d-flex flex-column gap-1">
                        <span><span>Welcome</span> <span>{username}</span></span>
                        <Link to={isUserProfile ? "/user" : "profile"}>{isUserProfile ? "Back To Main" : "Go To Profile"}</Link>
                    </div>
                    <div onClick={handleOut} className='user-main-logout'>
                        <IoLogOutOutline />
                        <span >Logout</span>
                    </div>
                </div>
                <div className='px-1 outlet'>
                    <Outlet />
                </div>
            </Container>
            <Tabbar />
        </div>
    )
}

export default UserLayout
