import React from 'react'
import "./credit-payment.scss"
import { Button, Form } from 'react-bootstrap'
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
const CreditPayment = () => {


    const initialValues = {
        name: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        preliminaryInformation: false,
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("Enter Your fullname on Card"),
        cardNumber: Yup.string().required("Enter your card number"),
        expiryDate: Yup.string().required("Enter expiry date"),
        cvv: Yup.string().required("Enter cvv").max(3, "Enter valid cvv").min(3, "Enter valid cvv"),
        threeDs: Yup.bool(),
        preliminaryInformation: Yup.bool(),


    })

    const onSubmit = (values) => {
        console.log(values)
        formik.resetForm()
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    console.log(initialValues);

    return (
        <div id='credit-payment' className='box-style'>
            <h5>Fill You Card Info</h5>
            <Form noValidate onSubmit={formik.handleSubmit} className='d-flex flex-column gap-4'>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter Name on Card'
                        name='name'
                        {...formik.getFieldProps("name")}
                        value={formik.values.name}
                        isValid={formik.touched.name && !formik.errors.name}
                        isInvalid={formik.touched.name && formik.errors.name}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {formik.errors.name}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter Card Number'
                        name='cardNumber'
                        {...formik.getFieldProps("cardNumber")}
                        value={formik.values.cardNumber}
                        isValid={formik.touched.cardNumber && !formik.errors.cardNumber}
                        isInvalid={formik.touched.cardNumber && formik.errors.cardNumber}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {formik.errors.cardNumber}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className='d-flex gap-3'>
                    <Form.Group>
                        <Form.Label>Expiry Date</Form.Label>
                        <Form.Control
                            type='date'
                            name='expiryDate'
                            {...formik.getFieldProps("expiryDate")}
                            value={formik.values.expiryDate}
                            isValid={formik.touched.expiryDate && !formik.errors.expiryDate}
                            isInvalid={formik.touched.expiryDate && formik.errors.expiryDate}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {formik.errors.expiryDate}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>CVV</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter CVV'
                            name='cvv'
                            {...formik.getFieldProps("cvv")}
                            value={formik.values.cvv}
                            isValid={formik.touched.cvv && !formik.errors.cvv}
                            isInvalid={formik.touched.cvv && formik.errors.cvv}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {formik.errors.cvv}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>

                <Form.Group className='d-flex align-items-center gap-2 '>
                    <Form.Check
                        className='mb-1'
                        type='checkbox'
                        name='preliminaryInformation'
                        {...formik.getFieldProps("preliminaryInformation")}
                        value={formik.values.preliminaryInformation}
                    />
                    <Form.Label className='mb-0'>I have read the <span className='color-text accept-label'>preliminary information form and the distance sales contract.</span>. I accept.</Form.Label>
                </Form.Group>

                <div className="amount">
                    <span>Total Amount :</span>
                    <span>50$</span>
                </div>
                <Button type='submit' className="button">Complete</Button>
            </Form>
        </div>
    )
}

export default CreditPayment
