import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPasswordModal: false
}


export const passwordSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showModalPassword: (state) => {
            state.isPasswordModal = true;
        },
        hideModalPassword: (state) => {
            state.isPasswordModal = false;
        }
    }
})

export const { showModalPassword, hideModalPassword } = passwordSlice.actions;
export default passwordSlice.reducer;