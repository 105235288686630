
import React, { useEffect, useState } from 'react';
import "./meeting-start.scss";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraManager } from "../../agora-video/agora-manager.jsx";
import { AgoraManagerLoc } from "../../agora-video/agoraManagerLoc.jsx";
import config from "../../agora-video/config.js";
import { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import { MdDateRange } from "react-icons/md";
import { FaMicrophone } from "react-icons/fa6";
import { CiVideoOn } from "react-icons/ci";
import { FaRegSmileBeam } from "react-icons/fa";
import { RiComputerLine } from "react-icons/ri";
import DoctorCardHead from '../../user/doctor-info/head-info/DoctorCardHead';
import { AgoraAvaibleLiveRoom, generateAgoraTokenForUser } from '../../../api/user-services/agora-services.js';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Loading from '../../loading/Loading.jsx';

const MeetingStart = () => {
    const navigate = useNavigate();
    const [roomData, setRoomData] = useState([]);
    const username = sessionStorage.getItem("user").replace(/^"(.*)"$/, '$1');
    const doctorName = useSelector(state => state.doctor.selectedDoc);
    const agoraClient = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: "rtc" }));
    const [userLiveToken, setUserLiveToken] = useState();
    const [joined, setJoined] = useState(false);
    const [renderAgora, setRenderAgora] = useState(false);

    const [activeConnection, setActiveConnection] = useState(true);


    const loadData = async () => {
        try {
            const resp = await AgoraAvaibleLiveRoom(doctorName, username);
            setRoomData(resp.data);
        } catch (error) {
            console.log("avaible live room cekilemedi");
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (roomData.length > 0) {
            const generateToken = async () => {
                try {
                    const respToken = await generateAgoraTokenForUser(doctorName, username, roomData[0]);
                    setUserLiveToken(respToken.data);
                } catch (error) {
                    console.log("Token oluşturulamadı");
                }
            };

            generateToken();
        }
    }, [roomData]);

    useEffect(() => {
        if (userLiveToken) {
            setTimeout(() => {
                setRenderAgora(true);
            }, 1000);
        }
    }, [userLiveToken]);

    const handleJoinClick = (isClicked) => {
        setJoined(isClicked);
        setActiveConnection(false)
    };
    console.log(roomData);
    console.log(userLiveToken);
    return (
        <div id='meeting-start' className='box-style'>
            {!joined && (
                <>
                    <div className="top-date">
                        <MdDateRange />
                        <span>19 Ocak 2024, Cuma</span>
                        <span>23:00 - 00:00</span>
                    </div>
                    <DoctorCardHead />

                    <div className="videocall-box">
                        {userLiveToken && renderAgora ? (
                            <AgoraRTCProvider client={agoraClient}>
                                <AgoraManagerLoc
                                    key={userLiveToken.token}
                                    config={config}
                                    userLiveToken={userLiveToken}
                                    activeConnection={activeConnection} setActiveConnection={setActiveConnection}
                                    handleJoinClick={handleJoinClick}
                                />
                            </AgoraRTCProvider>
                        ) : (
                            <Loading />
                        )}

                    </div>
                    <div className="warnings">
                        <div>
                            <FaRegSmileBeam />
                            <span>Find a quiet space where you feel comfortable before starting the meeting.</span>
                        </div>
                        <div>
                            <RiComputerLine />
                            <span>You can join the meeting from your phone or computer.</span>
                        </div>
                    </div>

                </>
            )}
            {joined && userLiveToken && renderAgora &&
                <AgoraRTCProvider client={agoraClient}>
                    <AgoraManager
                        config={config}
                        userLiveToken={userLiveToken}
                    />
                </AgoraRTCProvider>
            }
        </div>
    );
}

export default MeetingStart;
