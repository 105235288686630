import React from 'react'
import CommonHealthCard from './common-health-card/CommonHealthCard'
import "./common-health-concerns.scss"
import dataHealthConcern from "../../../healthConcern.json"
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedDoctor } from '../../../store/doctor-slice/doctor-slice'
const CommonHealthConcerns = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleNavigate = (docName) => {
        dispatch(setSelectedDoctor(docName))
        navigate(`/user/doctor/${docName}`)
    }


    return (
        <div id='common-health'>
            <div className="titles">
                <span className="main-title">Common Health Concerns
                </span>
                <span className='sub-title'>Consult a doctor online for any health issue</span>
            </div>

            <div className="common-healt-content">
                {
                    dataHealthConcern.map((item) => (
                        <div key={item.id} onClick={() => handleNavigate(item.doctorName)}>
                            <CommonHealthCard item={item} />
                        </div>


                    ))
                }
            </div>


        </div>
    )
}

export default CommonHealthConcerns
