import React from 'react'
import SettingsLinks from '../../../../../components/user/profile/link-pages-account/settings-page/SettingsLinks'

const SettingsPage = () => {
    return (
        <div id='settings-page'>
            <SettingsLinks />
        </div>
    )
}

export default SettingsPage
