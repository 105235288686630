import { configureStore } from "@reduxjs/toolkit";
import modalSlice from "./login/modalSlice";
import signUpSlice from "./sign-up/signupSlice";
import passwordSlice from "./password/passwordSlice";
import authSlice from "./auth-slice/authSlice";
import searchInputSlice from "./search-slice/search-slice";
import doctorSlice from "./doctor-slice/doctor-slice";
import discoverContentSlice from "./discover-content-slice/discover-content-slice";
import categoriesSlice from "./categories/categories-slice";
import appointmentSlice from "./appointment-slice/appointment-slice";
import serviceDateSlice, { serviceAndDate } from "./service-date-slice/service-date-slice";
import mediaControllerSlice from "./media-controller/mediaControllerSlice";




const store = configureStore({
    reducer: {
        modal: modalSlice,
        signUpModal: signUpSlice,
        passwordModal: passwordSlice,
        auth: authSlice,
        search: searchInputSlice,
        doctor: doctorSlice,
        content: discoverContentSlice,
        categories: categoriesSlice,
        appointmentDate: appointmentSlice,
        serviceAndDate: serviceDateSlice,
        mediaController: mediaControllerSlice
    }

})

export default store;