import React from 'react'
import "./company-infos.scss"

import { FiPhoneCall } from "react-icons/fi";
import { GoMail } from "react-icons/go";
import { FaHeartPulse } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

import { companyInfos } from "../company-settings";
const CompanyInfos = () => {
    return (
        <div id='company-infos'>
            <div className='box-style my-3 d-flex flex-column gap-3'>
                <div className='number info-box'>
                    <FiPhoneCall />
                    <span><a href="tel:+908504809056">{companyInfos.phone}</a></span>
                </div>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum, fuga aliquid! Asperiores nemo ipsa adipisci iure cum, beatae tempore. Consectetur?</p>
            </div>
            <div className='box-style my-3 d-flex flex-column gap-3'>
                <div className="mail info-box">
                    <GoMail />
                    <a href="mailto:info@zupsie.com">
                        <span>{companyInfos.email}</span>
                    </a>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium repellendus illo sint atque sunt deserunt voluptate commodi aperiam assumenda perspiciatis.</p>
            </div>
            <div className="company-name info-box box-style my-3">
                <FaHeartPulse />
                <span>{companyInfos.name}</span>
            </div>

            <div className="social box-style my-3">
                <a href="https://www.instagram.com"><FaInstagram /></a>
                <a href="https://www.twitter.com"><FaTwitter /></a>
                <a href="https://www.linkedin.com"><FaLinkedin /></a>
                <a href="https://www.youtube.com"><FaYoutube /></a>
            </div>
        </div>
    )
}

export default CompanyInfos
