import React, { useEffect, useState } from 'react'
import "../habits-forms.scss"
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { postNutrition, updateNutrition } from '../../../../../../../api/user-services/healt-info-services'
import { useNavigate } from 'react-router-dom'
const NutritionForm = ({ data }) => {

    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            id: "",
            numberofmeals: "",
            name: "nutrition",
            regular: true,
            followingAdiet: true,
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )
    const handleChange = (e) => {
        const { name, checked, value } = e.target;
        const newValue = name === 'regular' || name === 'followingAdiet' ? checked : value;

        setInfo({ ...info, [name]: newValue });
    }


    const handleSubmit = async (e) => {
        e.preventDefault()

        try {

            if (data?.[0]?.id) {
                await updateNutrition(info)
            } else {
                await postNutrition(info)
            }

            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }

    useEffect(() => {
        if (data) {
            setInfo({
                ...info,
                id: data?.[0]?.id,
                numberofmeals: data?.[0]?.numberofmeals,
                regular: true,
                followingAdiet: true,
            });
        }
    }, [data]);

    console.log(data);
    console.log(info);


    return (
        <div id='nutrition-form' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group className='d-flex align-items-center justify-content-between'>
                    <Form.Label>Regular</Form.Label>
                    <Form.Switch
                        name='regular'
                        onChange={handleChange}
                        checked={info?.regular || false}
                    />
                </Form.Group>
                <Form.Group className='d-flex align-items-center justify-content-between'>
                    <Form.Label>I'm following a diet.
                    </Form.Label>
                    <Form.Switch
                        name='followingAdiet'
                        onChange={handleChange}
                        checked={info?.followingAdiet || false}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Number of meals</Form.Label>
                    <Form.Control
                        type='number'
                        onChange={handleChange}
                        name='numberofmeals'
                        placeholder={data?.[0]?.numberofmeals || "number of meals"}
                    />
                </Form.Group>


                <Button className='button' type='submit' >Save</Button>
            </Form>
        </div>
    )
}

export default NutritionForm
