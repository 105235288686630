import React from 'react'
import "./doctor-card-contact.scss"
import { FaInstagram, FaXTwitter, FaYoutube } from 'react-icons/fa6'
import { BsCameraVideoFill } from 'react-icons/bs'
import { MdOutlineQuestionAnswer } from 'react-icons/md'
import { Link } from 'react-router-dom'
const DoctorCardContact = ({ id }) => {
    return (
        <div id='doctor-card-contact'>
            <div className="question-social-call">
                <div className="top">
                    {/* <button className='button'><MdOutlineQuestionAnswer />Ask Question</button> */}
                    <div className="social">
                        <FaInstagram />
                        <FaXTwitter />
                        <FaYoutube />
                    </div>
                </div>
                <Link to={`/user/doctor/${id}/meetings/${id}`} className='button w-100'>Schedule an Appointment</Link>
            </div>
        </div>
    )
}

export default DoctorCardContact
