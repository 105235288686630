import React, { useEffect, useState } from 'react'

import countries from "../../../../../../countries/countriesCode.json"
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { addEmergencyInfos, updateEmergencyInfos } from '../../../../../../api/user-services/user-account-services'
import { swalToast } from '../../../../../../swalMessages/swalMessages'

const EmergencyInfo = ({ data }) => {

    const username = JSON.parse(sessionStorage.getItem("user"))
    const navigate = useNavigate()
    const [code, setCode] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const newPhoneNumber = `${code}${phoneNumber}`
    console.log(newPhoneNumber);

    const getPhoneNumber = (e) => {
        setPhoneNumber(e.target.value)
    }
    const getCode = (e) => {
        setCode(e.target.value)
    }

    const [info, setInfo] = useState(
        {
            id: "",
            username: username,
            name: "",
            lastname: "",
            email: "",
            phone: "",
            relationship: "",
            explain: ""
        }
    )




    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (data?.id) {
                await updateEmergencyInfos(info)
                swalToast("Personal info updated successfully", "", "success")
            } else {

                await addEmergencyInfos({ ...info, phone: newPhoneNumber })
                swalToast("Personal info added successfully", "", "success")
            }
            navigate(-1)
        } catch (error) {
            swalToast("Something went wrong", "", "error")
        }
    }




    return (
        <div id='emergency-info' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type='text'
                        name='name'
                        value={info?.name}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Lastname</Form.Label>
                    <Form.Control
                        type='text'
                        name='lastname'
                        value={info?.lastname}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <div className='d-flex align-items-center gap-2'>
                        <Form.Select className='w-25'
                            onChange={getCode}
                            value={code}
                        >
                            <option value="0">Code</option>
                            {
                                countries.map((country, i) => (
                                    <option key={i} value={country.dial_code}>{country.dial_code}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Control
                            type='number'
                            onChange={getPhoneNumber}
                            value={info?.phone || phoneNumber}
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                        type='email'
                        name='email'
                        value={info?.email}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Relationship</Form.Label>
                    <Form.Control
                        type='text'
                        name='relationship'
                        onChange={handleChange}
                        value={info?.relationship}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Explain</Form.Label>
                    <Form.Control type='text' as={"textarea"} style={{ maxHeight: "100px", minHeight: "100px" }}
                        name='explain'
                        onChange={handleChange}
                        value={info?.explain}
                    />
                </Form.Group>
                <button className="button">Save</button>
            </Form>
        </div>
    )
}

export default EmergencyInfo
