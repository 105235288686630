

import React, { useEffect, useState } from 'react'
import "./commitment-status.scss"
import { Form } from 'react-bootstrap'
import { getCommitmentStatusList, postCommitmentStatusList } from '../../../../../../api/user-services/healt-info-services'
import abilityTypes from "../../../../../../diseases/abilityTypes.json"
import { swalToast } from '../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'

const CommitmentStatus = () => {
    const navigate = useNavigate()
    const username = JSON.parse(sessionStorage.getItem("user"))
    const [statusList, setStatusList] = useState()
    const [info, setInfo] = useState(
        {
            id: "string",
            abilities: [],
            abilitytypes: []
        }
    )

    const handleChange = (e) => {
        const { name, checked, value } = e.target;

        if (name === 'abilities') {
            setInfo(prevState => ({
                ...prevState,
                abilities: [value]
            }))
        } else {
            if (checked) {
                setInfo(prevState => ({
                    ...prevState,
                    abilitytypes: [...prevState.abilitytypes, name]
                }));
            } else {
                setInfo(prevState => ({
                    ...prevState,
                    abilitytypes: prevState.abilitytypes.filter(item => item !== name)
                }));
            }
        }
    };

    const loadData = async () => {
        try {
            const resp = await getCommitmentStatusList()
            setStatusList(resp.data)
            console.log(resp);
            // if (resp.data[0]) {
            //     setInfo(prevState => ({
            //         ...prevState,
            //         abilities: resp.data[0].abilities,
            //         abilitytypes: resp.data[0].abilitytypes
            //     }))
            // }
        } catch (error) {
            console.log("status list cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await postCommitmentStatusList(username, info)
            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }
    console.log(info);
    console.log(statusList);
    return (
        <div id='commitment-status' className='box-style'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Functional Ability</Form.Label>
                    <Form.Select
                        onChange={handleChange}
                        name='abilities'
                        value={info.abilities[0] || ""}
                    >
                        {
                            statusList?.[0]?.abilities?.map((item, index) => (
                                <option key={index} value={item.id}>{item}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    {
                        abilityTypes.map((item) => (
                            <div key={item.id} className='form-group-item'>
                                <Form.Label>{item.name}</Form.Label>
                                <Form.Check
                                    type='switch'
                                    name={item.name}
                                    onChange={handleChange}
                                    checked={info.abilitytypes.includes(item.name)}
                                />
                            </div>
                        ))
                    }
                </Form.Group>
                <button className="button" type='submit'>Save</button>
            </Form>
        </div>
    )
}

export default CommitmentStatus
