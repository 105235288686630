import React from 'react'
import MeetingMainContainer from '../../../components/containers/meeting-main-page/MeetingMainContainer'

const MeetingPageMain = () => {
    return (
        <div id='meeting-page-main'>
            <MeetingMainContainer />
        </div>
    )
}

export default MeetingPageMain
