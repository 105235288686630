import React, { useEffect, useState } from 'react'
import "../habits-forms.scss"
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { postTabaccoAlcholDrugs, updateTabaccoAlcholDrugs } from '../../../../../../../api/user-services/healt-info-services'
import { useNavigate } from 'react-router-dom'
const TabaccoForm = ({ data }) => {


    const navigate = useNavigate()
    const [currentInfo, setCurrentInfo] = useState()
    const [info, setInfo] = useState(
        {
            name: "Tabacco",
            habitUsage: "",
            whatKindHabit: "",
            beginDate: "",
            quitDate: "",
            howmany: "",
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target

        setInfo({ ...info, [name]: value })
    }




    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (currentInfo?.id) {
                await updateTabaccoAlcholDrugs(info)
            } else {
                await postTabaccoAlcholDrugs(info)
            }
            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }

    useEffect(() => {
        const currentCategory = data?.find((item) => item.name === "Tabacco");
        setCurrentInfo(currentCategory);
        if (currentCategory) {
            setInfo({
                ...info,
                habitUsage: currentCategory.habitUsage,
                whatKindHabit: currentCategory.whatKindHabit,
                beginDate: currentCategory.beginDate,
                quitDate: currentCategory.quitDate,
                howmany: currentCategory.howmany
            });
        }
    }, [data]);


    console.log(data);

    return (
        <div id='tabacco-form' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Taabacco Use</Form.Label>
                    <Form.Select
                        name='habitUsage'
                        onChange={handleChange}
                        value={info.habitUsage}

                    >
                        <option value=""></option>
                        <option value="none">none</option>
                        <option value="quit">quit</option>
                        <option value="regular">regular</option>
                        <option value="social">social</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>What</Form.Label>
                    <Form.Select
                        name='whatKindHabit'
                        onChange={handleChange}
                        value={info.whatKindHabit}


                    >
                        <option value=""></option>
                        <option value="Electronic Cigarette">Electronic Cigarette</option>
                        <option value="Cigarette">Cigarette</option>
                        <option value="Cigar">Cigar</option>
                        <option value="Pipe">Pipe</option>
                        <option value="Hookah">Hookah</option>
                        <option value="Other">Other</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Begin Date</Form.Label>
                    <Form.Control type='date'
                        name='beginDate'
                        onChange={handleChange}
                        value={info?.beginDate}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Quit Date</Form.Label>
                    <Form.Control type='date'
                        name='quitDate'
                        onChange={handleChange}
                        value={info?.quitDate}

                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>How many cigarettes do you smoke per day?</Form.Label>
                    <Form.Control type='text'
                        name='howmany'
                        onChange={handleChange}
                        placeholder={info?.howmany || "How many"}
                        value={info.howmany}

                    />
                </Form.Group>

                <Button type='submit' className='button'>Save</Button>
            </Form>
        </div>
    )
}

export default TabaccoForm
