import React from 'react'
import Questions from '../../user/supports/questions/Questions'
import CompanyInfos from '../../user/supports/company-infos/CompanyInfos'

const Supports = () => {
    return (
        <div id='supports'>
            <Questions />
            <CompanyInfos />
        </div>
    )
}

export default Supports
