import React from 'react'
import HealthProfile from '../../../../../components/user/profile/link-pages/health-profile/HealthProfile'

const HealthProfilePage = () => {
    return (
        <div className='health-profile-page'>
            <HealthProfile />
        </div>
    )
}

export default HealthProfilePage
