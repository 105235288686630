import React, { useEffect, useState } from 'react'
import "./service-providers.scss"
import { Container } from 'react-bootstrap'
import DoctorCard from '../doctor-card/DoctorCard'
import { getPopularDocsWithoutAuth, getPopularDoctors } from '../../../../api/user-services/doctor-services'
const ServiceProviders = () => {

    const [docs, setDocs] = useState([])

    const loadData = async () => {
        try {
            const resp = await getPopularDocsWithoutAuth()
            setDocs(resp.data)

        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        loadData()
    }, [])

    console.log(docs);

    return (
        <div id='service-providers'>
            <Container>
                <div className='provider-content'>
                    <h4>Health Care Providers</h4>
                    <p className='text-center p-sm'>Our healthcare providers at Zupsie are exceptional doctors with extensive qualifications and experience, dedicated to delivering the best second opinions and treatment plans for cancer patients</p>

                    <button className='landing-btn-lg hover-dark'>Show All</button>
                </div>

                <div className='doctors-cards'>
                    {
                        docs?.map((doc) => (
                            <DoctorCard key={doc?.id} doc={doc} />
                        ))
                    }


                </div>
            </Container>
        </div>
    )
}

export default ServiceProviders
