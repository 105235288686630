import React from 'react'
import "./logout.scss"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOut } from '../../../../../api/user-services/user-services';
import { logout } from '../../../../../store/auth-slice/authSlice';
import { encryptedLocalStorage } from '../../../../../helpers/functions/encrypt-storage';
import { swalToast } from '../../../../../swalMessages/swalMessages';

const LogOut = ({ isLogoutClicked, setIsLogoutClicked }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOut = async () => {
        try {
            await signOut();
            dispatch(logout())
            encryptedLocalStorage.clear()
            navigate("/")
            swalToast("Log out successful", "", "success")
        } catch (error) {
            console.log("error");
        }
    }

    const closeLogout = () => {
        setIsLogoutClicked(false)
    }


    return (
        <div id='logout' className={isLogoutClicked ? "active" : ""}>
            <div className="logout-content box-style" >
                <p className='title text-center'>Are you sure you want to log out?</p>
                <button className='button w-100' onClick={handleOut}>Yes</button>
                <button className='button w-100' onClick={closeLogout}>No</button>
            </div>
        </div>
    )
}

export default LogOut
