import React from 'react'
import "./discover-content-details.scss"
import DiscoverContentCard from '../../../user/discover/discover-content-card/DiscoverContentCard'
const DiscoverContentDetails = ({ content }) => {

    console.log(content);
    return (
        <div id='discover-content-details' className='box-style'>
            <div className="top">
                <div className="item-image">
                    <img src={content?.discoveritemimage} alt="" />
                </div>
            </div>
            <DiscoverContentCard content={content} />
        </div>
    )
}

export default DiscoverContentDetails
