import React, { useState } from 'react';
import "./dates-with-hours.scss";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setHour } from '../../../../../store/service-date-slice/service-date-slice';

const DatesWithHours = ({ timeSlotList, meetingInfo, setMeetingInfo }) => {

    const [isActive, setIsActive] = useState(false);
    const [activeHour, setActiveHour] = useState(null);
    const dispatch = useDispatch()


    const handleTimeSlot = (time) => {
        setMeetingInfo((prevMeetingInfo) => ({
            ...prevMeetingInfo,
            bookedTimeslot: time
        }));
        setIsActive(true)
        setActiveHour(time);
        dispatch(setHour(true))
    }


    return (
        <div id='dates-with-hours'>
            {timeSlotList.map((hour, i) => (
                <div key={i} className={`hour ${isActive && activeHour === hour ? 'active' : ''}`}>
                    <span onClick={() => handleTimeSlot(hour)}>{`${hour}`}</span>
                </div>
            ))}
        </div>
    );
}

export default DatesWithHours;
