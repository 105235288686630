import React from 'react'
import "./package-summary.scss"
import Logo from '../../main-page/common/logo/Logo'
const PackageSummary = ({ id }) => {

    const date = new Date();

    const day = String(date.getDate()).padStart(2, '0'); // Gün
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay (0-11 olduğu için 1 ekliyoruz)
    const year = date.getFullYear(); // Yıl

    const formattedDate = `${day}.${month}.${year}`;
    console.log(formattedDate);

    return (
        <div id='package-summary'>
            <div className="package-card box-style" >
                <div className="top">
                    <div><Logo isLogoBlack={true} /></div>
                    <span className='zupsie-company'>Zupsie Teknoloji Sağlık Hizmetleri ve Danışmanlık A.Ş</span>
                </div>

                <div className="package-info">
                    <div className="type">
                        <span>Package Type</span>
                        <span>
                            {
                                id === "buy-advanced-plan" && "Advenced"
                            }
                            {
                                id === "buy-main-plan" && "Main Plan"
                            }
                        </span>
                    </div>
                    <div className="date">
                        <span>Date</span>
                        <span>{formattedDate}</span>
                    </div>
                </div>

                <div className="package-summary">
                    <h4>Purchase Summary</h4>
                    <h5>Advenced Plan</h5>

                    <ul>
                        <li><strong>Report Evaluation:</strong> Diagnosis reports reviewed by specialist doctors.</li>
                        <li><strong>Expert Opinions:</strong> Comprehensive evaluations and treatment recommendations.</li>
                        <li><strong>Second Opinion:</strong> One-on-one consultations with chosen doctors.</li>
                        <li><strong>Continuous Support:</strong> Regular follow-up and consultations.</li>
                        <li><strong>Emergency Consultation:</strong> 24/7 expert support line.</li>
                        <li><strong>Educational Resources:</strong> Updated materials and webinars.</li>
                        <li><strong>Customization:</strong> Comments from doctors of your choice.</li>
                    </ul>

                </div>

            </div>
        </div>
    )
}

export default PackageSummary
