
import React, { useEffect, useState } from 'react'
import TabaccoForm from '../../../../../../components/user/profile/link-pages/health-history/habits/tabacco-form/TabaccoForm'
import { getTabaccoAlcholDrugs } from '../../../../../../api/user-services/healt-info-services'

const TabaccoPage = () => {

    const [data, setData] = useState()


    const loadData = async () => {
        try {
            const resp = await getTabaccoAlcholDrugs()
            setData(resp.data)
        } catch (error) {
            console.log("tabacco getirilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div>
            <TabaccoForm data={data} />
        </div>
    )
}

export default TabaccoPage
