import React from 'react'
import MeetingStart from '../../../components/containers/meeting-start/MeetingStart'

const MeetingStartPage = () => {
    return (
        <div id='meeting-start-page'>
            <MeetingStart />
        </div>
    )
}

export default MeetingStartPage
