import React from 'react'

const HealthServicesInfo = () => {
    return (
        <div id='health-services-info' className='box-style'>

        </div>
    )
}

export default HealthServicesInfo
