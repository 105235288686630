import React, { useEffect, useState } from 'react'
import "./sign-up-form.scss"
import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import countriesCode from "../../../countries/countriesCode.json"
import { generateOTP, signUp, verifyOTPService } from '../../../api/user-services/user-services'
import Loading from '../../loading/Loading'
import { swalToast } from '../../../swalMessages/swalMessages'


const SignUpForm = ({ isPhoneEntered, setIsPhoneEntered, setIsLoginClicked, setIsSignUpClicked }) => {
    const [validated, setValidated] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false)
    const [isOTPcodeEntered, setIsOTPCodeEntered] = useState(false)
    const [userFullPhoneNumber, setUserFullPhoneNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const [isSignUpSuccess, setIsSignUpSuccess] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryCallingCode, setCountryCallingCode] = useState()

    const [signUpOtp, setSignUpOtp] = useState(
        {
            id: "",
            phoneNumber: "",
            email: "",
            username: "",
            otp: "",
            status: ""
        }
    )

    const loadData = async () => {
        try {
            const resp = await fetch("https://ipapi.co/json");
            const data = await resp.json();
            setCountryCallingCode(data.country_calling_code);
        } catch (error) {
            console.log("IP bilgileri alınırken hata oluştu:", error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    console.log(countryCallingCode);

    const handleSubmitOTP = async (e) => {
        e.preventDefault()
        setLoading(true)
        const fullPhoneNumber = `${countryCallingCode || countryCode}${phoneNumber}`
        setUserFullPhoneNumber(fullPhoneNumber)
        try {
            await generateOTP({ ...signUpOtp, phoneNumber: fullPhoneNumber }, fullPhoneNumber)
            setIsPhoneEntered(true)
            setLoading(false)
            swalToast("OTP sent successfully!", "Please check your phone", "success")
        } catch (error) {
            console.log("hata aldin");
            swalToast("Error!", "An unexpected error occurred.", "error")
            setLoading(false)
        }
    }


    const [verifyOTP, setVerifyOTP] = useState({

        id: "string",
        phoneNumber: "string",
        email: "string",
        username: "string",
        otp: "",
        status: "string"

    })

    const handleVerifyOTP = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await verifyOTPService({ ...verifyOTP, phoneNumber: userFullPhoneNumber }, userFullPhoneNumber)
            setIsOTPCodeEntered(true)
            setLoading(false)
            swalToast("OTP verified successfully!", "Please enter your informations", "success")
        } catch (error) {
            setLoading(false)
            swalToast("OTP Error!", error.response.data, "error")
            setIsPhoneEntered(false)
            setCountryCode("")
            setPhoneNumber("")
            setVerifyOTP({ ...verifyOTP, otp: " " })
        } finally {
            setLoading(false)
        }
    }




    const [userInfo, setUserInfo] = useState({
        username: "",
        phonenumber: "",
        firstname: "",
        lastname: "",
        nationality: "",
        identitynumber: "",
        birthdate: "",
        sex: "",
        issubscriberagreement: true,
        isconsentforzupsie: true,
        isconsentforpersonalsales: true,
        email: "",
        role: [
            "USER",
            "MOD"
        ],
        password: ""
    })

    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);

        const selectedCountryInfo = countriesCode.find(country => country.dial_code === e.target.value);
        setSelectedCountry(selectedCountryInfo);
    };

    const handlePhoneNumber = (e) => {
        const number = e.target.value
        if (number.startsWith("0")) {
            swalToast("Invalid Input", "Phone number cannot start with 0", "warning");
            return;
        }
        setPhoneNumber(number)
    }

    const handleConfirmPassword = (e) => {
        const secondPassword = e.target.value
        setConfirmPassword(secondPassword)
        console.log(confirmPassword);
    }



    const getUserInfo = (e) => {
        const { name, value } = e.target
        if (name === "birthdate") {
            const date = new Date(value);
            console.log("Parsed date object:", date);

            if (isNaN(date.getTime())) {
                console.error("Invalid date format!");
            } else {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                setUserInfo({ ...userInfo, [name]: `${day}.${month}.${year}` });
            }
        } else {
            setUserInfo({ ...userInfo, [name]: value });
        }

        setUserInfo({ ...userInfo, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPhoneEntered(true)

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

        }

        setValidated(true);


        const fullPhoneNumber = `${countryCallingCode || countryCode}${phoneNumber}`

        if (userInfo.password !== confirmPassword) {
            swalToast("Passwords do not match!", "Please re-enter your password.", "warning")
            return
        }
        if (userInfo.password.length !== 6 || isNaN(userInfo.password) || !/^\d+$/.test(userInfo.password)) {
            setIsPasswordInvalid(true)
            swalToast("Check Again!", "Password must be 6 digits and contain only numbers. It should not include special symbols.", "info")
            return;
        }
        try {
            await signUp({ ...userInfo, phonenumber: fullPhoneNumber })
            swalToast("Congratulations!", "Registration Completed!", "success")
            setIsSignUpSuccess(true)
            setIsLoginClicked(true)
            setIsSignUpClicked(false)
        } catch (error) {
            swalToast(`Sorry. Registration Failed! `, error, "info")
        }
    }

    const handeLoginClick = () => {
        setIsSignUpClicked(false)
        setIsLoginClicked(true)
    }

    const defaultCountryFlag = countriesCode.filter((country) => country.dial_code === countryCallingCode ? country.flag : null)[0]?.flag || null

    return (
        <div id='sign-up-form'>

            {
                (isPhoneEntered && isOTPcodeEntered) ? (
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className='mb-2'>Please fill in the information completely.</div>
                        <div className="enter-user-infos">
                            <Form.Group className='form-group'>
                                <Form.Control
                                    type='text'
                                    placeholder='UserName*'
                                    name='username'
                                    value={userInfo.username}
                                    onChange={getUserInfo}
                                    required
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>You must enter username!</Form.Control.Feedback>
                            </Form.Group >
                            <div className='d-flex flex-column'>
                                <div className='d-flex gap-3'>
                                    <Form.Group className='d-flex flex-column form-group w-100 '>
                                        <Form.Control
                                            type='text'
                                            placeholder='Name*'
                                            name='firstname'
                                            value={userInfo.firstname}
                                            onChange={getUserInfo}
                                            required
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>You must enter firstname!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='form-group w-100'>
                                        <Form.Control
                                            type='text'
                                            placeholder='Lastname*'
                                            name='lastname'
                                            value={userInfo.lastname}
                                            onChange={getUserInfo}
                                            required
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>You must enter lastname!</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='d-flex gap-3'>
                                    <Form.Group className='d-flex flex-column form-group w-100'>
                                        <Form.Control
                                            type='text'
                                            placeholder='ID Number*'
                                            name='identitynumber'
                                            value={userInfo.identitynumber}
                                            onChange={getUserInfo}
                                            required
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>You must enter identity number!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='form-group w-100'>
                                        <Form.Select
                                            name='nationality'
                                            value={userInfo.nationality}
                                            onChange={getUserInfo}
                                            required

                                        >
                                            <option value="">Nationality*</option>
                                            {
                                                countriesCode.map((country, i) => (
                                                    <option key={i} value={country.name}>{country.name}</option>
                                                ))
                                            }
                                            <option>Select Country Code</option>
                                            {
                                                countriesCode.map((country, i) => (
                                                    <option key={i} value={country.name}>{country.name}</option>
                                                ))
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <Form.Group id='code-phoneNumber' className='d-flex gap-4 form-group '>
                                    <div className='d-flex gap-2 w-100 '>
                                        <Form.Select
                                            className='w-50 name-countryCode'
                                            name='countryCode'
                                            onChange={handleCountryCodeChange}
                                            value={countryCode}
                                            disabled
                                        >
                                            <option value={countryCallingCode}>{countryCallingCode} </option>
                                            {
                                                countriesCode.map((countryDialCode, i) => (
                                                    <option key={i} value={countryDialCode.dial_code}>{countryDialCode.dial_code}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control
                                            className='name-phoneNumber'
                                            type='text'
                                            placeholder='Phone Number*'
                                            name='phoneNumber'
                                            value={phoneNumber}
                                            onChange={handlePhoneNumber}
                                            disabled
                                        />
                                    </div>
                                    <Form.Group className='w-100 d-flex flex-column form-group'>
                                        <Form.Control
                                            type='email'
                                            placeholder='E-Mail*'
                                            name='email'
                                            value={userInfo.email}
                                            onChange={getUserInfo}
                                            required
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>You must enter e-mail!</Form.Control.Feedback>

                                    </Form.Group>
                                </Form.Group>
                                <div className='d-flex gap-3'>
                                    <Form.Group className='d-flex flex-column form-group w-100'>
                                        <Form.Control
                                            type='date'
                                            placeholder='Date of Birth*'
                                            name='birthdate'
                                            value={userInfo.birthdate}
                                            onChange={getUserInfo}
                                            required
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>You must enter birthdate!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='form-group w-100'>
                                        <Form.Select
                                            name='sex'
                                            value={userInfo.sex}
                                            onChange={getUserInfo}
                                            required
                                        >
                                            <option value="">Gender*</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Select>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>You must enter gender!</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <Form.Group className='d-flex flex-column form-group'>
                                    <span className='password-messages text-dark mb-1'>Password must be 6 digits and contain only numbers.</span>
                                    <div className='d-flex gap-4'>
                                        <Form.Group className='w-100'  >
                                            <Form.Control
                                                type='password'
                                                placeholder='Password*'
                                                name='password'
                                                value={userInfo.password}
                                                onChange={getUserInfo}
                                                required
                                            />
                                            <Form.Control.Feedback type='invalid'>You must enter password!</Form.Control.Feedback>
                                        </Form.Group >
                                        <Form.Group className='w-100 form-group'>
                                            <Form.Control
                                                type='password'
                                                placeholder='Confirm Password*'
                                                name='confirmPassword'
                                                value={confirmPassword}
                                                onChange={handleConfirmPassword}
                                                required
                                            />
                                        </Form.Group>
                                    </div>
                                    {
                                        userInfo.password !== confirmPassword && <span className='text-danger password-messages'>Passwords do not match!</span>
                                    }
                                    {
                                        ((userInfo.password === confirmPassword) && userInfo.password.length > 0) && <span className='text-success password-messages'>Passwords matched!</span>
                                    }
                                    {
                                        (isPasswordInvalid && userInfo.password.length < 6) && <span className='text-danger password-messages'>Password must be 6 digits and contain only numbers.</span>
                                    }
                                </Form.Group>
                                <Form.Group className='d-flex flex-column gap-2 mt-5 terms'>
                                    <Link>
                                        Click here for the information text about the processing of your personal data.
                                    </Link>
                                    <div className='d-flex align-items-center'>
                                        <Form.Check
                                            type='checkbox'
                                            name='issubscriberagreement'
                                            checked={userInfo.issubscriberagreement}
                                            onChange={(e) => setUserInfo({ ...userInfo, issubscriberagreement: e.target.checked })}
                                        />
                                        <Form.Label >I have read and accept the membership agreement.</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <Form.Check
                                            type='checkbox'
                                            name='isconsentforzupsie'
                                            checked={userInfo.isconsentforzupsie}
                                            onChange={(e) => setUserInfo({ ...userInfo, isconsentforzupsie: e.target.checked })}
                                        />
                                        <Form.Label >I consent to Zupsie for personalized sales and marketing.</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <Form.Check
                                            type='checkbox'
                                            name='isconsentforpersonalsales'
                                            checked={userInfo.isconsentforpersonalsales}
                                            onChange={(e) => setUserInfo({ ...userInfo, isconsentforpersonalsales: e.target.checked })}
                                        />
                                        <Form.Label >I consent for personalized sales.</Form.Label>
                                    </div>
                                </Form.Group>
                                <Button type='submit' className='button'>Sign Up</Button>
                            </div>
                        </div>
                    </Form>
                ) :
                    (
                        <>
                            {
                                !isPhoneEntered ? (
                                    <Form onSubmit={handleSubmitOTP} >

                                        <div className='enter-phone'>
                                            <Form.Group>
                                                <Form.Label>Mobile Phone</Form.Label>
                                                <br />
                                                <div className='d-flex align-items-center gap-2 mb-3 login-phone-info'>
                                                    <Form.Select
                                                        className='w-50'
                                                        name='countryCode'
                                                        onChange={handleCountryCodeChange}
                                                        value={countryCode}
                                                    >
                                                        <option value={countryCallingCode}>{countryCallingCode} {defaultCountryFlag}</option>
                                                        {
                                                            countriesCode.map((countryDialCode, i) => (
                                                                <option key={i} value={countryDialCode.dial_code}>{countryDialCode.dial_code} {countryDialCode.flag} </option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    <Form.Control
                                                        className='mb-0'
                                                        type='text'
                                                        placeholder='Phone Number*'
                                                        name='phoneNumber'
                                                        value={phoneNumber}
                                                        onChange={handlePhoneNumber}
                                                    />
                                                    <div className='country-flag'>{selectedCountry ? selectedCountry?.flag : defaultCountryFlag}</div>
                                                </div>
                                            </Form.Group>
                                            <Button type='submit' className='button'>
                                                {
                                                    loading ? <div className='d-flex align-items-center gap-3'><Loading /> <span>Code Sending...</span></div> : "Next"
                                                }
                                            </Button>
                                            <div className='acc-control'>
                                                <span>Already have an account?</span>
                                                <Link onClick={handeLoginClick}>Log In</Link>
                                            </div>
                                        </div>
                                    </Form>
                                ) : (<Form onSubmit={handleVerifyOTP} >

                                    <div className='enter-phone'>
                                        <Form.Group>
                                            <Form.Label>Enter Code</Form.Label>
                                            <Form.Control
                                                className='mb-0'
                                                type='text'
                                                placeholder='Verify Code*'
                                                name='otpcode'
                                                value={verifyOTP.otp}
                                                onChange={(e) => setVerifyOTP({ ...verifyOTP, otp: e.target.value })}
                                            />
                                        </Form.Group>
                                        <Button type='submit' className='button'>
                                            {
                                                loading ? <div className='d-flex align-items-center gap-3'><Loading /> <span>Please wait. The code is being validated.</span></div> : "Next"
                                            }
                                        </Button>
                                        <div className='acc-control'>
                                            <span>Already have an account?</span>
                                            <Link onClick={handeLoginClick}>Log In</Link>
                                        </div>
                                    </div>
                                </Form>
                                )
                            }

                        </>
                    )
            }







        </div>
    )
}

export default SignUpForm














