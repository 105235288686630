import React from 'react'
import AboutApp from '../../../../../components/user/profile/link-pages-account/about-app/AboutApp'

const AboutAppPage = () => {
    return (
        <div id='about-app-page'>
            <AboutApp />
        </div>
    )
}

export default AboutAppPage
