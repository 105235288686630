import React from 'react'
import Supports from '../../../components/containers/supports/Supports'

const SupportPage = () => {
    return (
        <div id='support-page'>
            <Supports />
        </div>
    )
}

export default SupportPage
