import React from 'react'
import "./meeting-detail-info.scss"
import MeetingMainDocCard from '../../../user/meeting-main/meeting-main-doc-card/MeetingMainDocCard'
const MeetingDetailInfo = () => {
    return (
        <div id='meeting-detail-info'>
            <MeetingMainDocCard />
        </div>
    )
}

export default MeetingDetailInfo
