



import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    appointmentDate: sessionStorage.getItem("appointmentDate") ? JSON.parse(sessionStorage.getItem("appointmentDate")) : [],
    appointmentOnlyDate: sessionStorage.getItem("appointmentOnlyDate") ? JSON.parse(sessionStorage.getItem("appointmentOnlyDate")) : [],
    appointmentOnlyHour: sessionStorage.getItem("appointmentOnlyHour") ? JSON.parse(sessionStorage.getItem("appointmentOnlyHour")) : [],
}

export const appointmentSlice = createSlice({
    name: "appointmentDate",
    initialState,
    reducers: {
        setAppointmentDate: (state, action) => {
            state.appointmentDate = action.payload;
            sessionStorage.setItem("appointmentDate", JSON.stringify(action.payload));
        },
        setAppointmentOnlyDate: (state, action) => {
            state.appointmentOnlyDate = action.payload;
            sessionStorage.setItem("appointmentOnlyDate", JSON.stringify(action.payload));
        },
        setAppointmentOnlyHour: (state, action) => {
            state.appointmentOnlyHour = action.payload;
            sessionStorage.setItem("appointmentOnlyHour", JSON.stringify(action.payload));
        }
    }
})

export const { setAppointmentDate, setAppointmentOnlyDate, setAppointmentOnlyHour } = appointmentSlice.actions;
export default appointmentSlice.reducer;