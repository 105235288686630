import React, { useState } from 'react'
import "./login.scss"
import LoginForm from '../login-form/LoginForm'
import { Col, Container, Row } from 'react-bootstrap'
import { GrClose } from "react-icons/gr"
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../../store/login/modalSlice'
import Logo from '../../main-page/common/logo/Logo'
const Login = ({
    isSignUpClicked,
    setIsSignUpClicked,
    isLoginClicked,
    setIsLoginClicked,
    isForgotPasswordClicked,
    setIsForgotPasswordClicked

}) => {
    const [isPhoneAndPassword, setIsPhoneAndPassword] = useState(false)
    const isModal = useSelector(state => state.modal.isModal);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(hideModal())
        setIsPhoneAndPassword(false)
        setIsLoginClicked(false)
    }



    return (
        <>
            {
                (!isForgotPasswordClicked || !isSignUpClicked || isLoginClicked) && (
                    <div id='login' style={{ display: isLoginClicked || isModal ? "flex" : "none" }}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="left">
                                        <div>
                                            <Logo />
                                            {/* <div className="line"></div>
                                <h6>Şimdi Al Sonra Öde</h6> */}
                                        </div>
                                        <div className="login-form">
                                            <LoginForm
                                                isPhoneAndPassword={isPhoneAndPassword}
                                                setIsPhoneAndPassword={setIsPhoneAndPassword}
                                                isSignUpClicked={setIsSignUpClicked}
                                                setIsSignUpClicked={setIsSignUpClicked}
                                                setIsLoginClicked={setIsLoginClicked}
                                                isForgotPasswordClicked={isForgotPasswordClicked}
                                                setIsForgotPasswordClicked={setIsForgotPasswordClicked}

                                            />
                                        </div>
                                        <div>

                                            <GrClose onClick={closeModal} />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="right">
                                        <img src="" alt="" />
                                        <GrClose onClick={closeModal} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )
            }
        </>
    )
}

export default Login
