import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;


export const getPopularCategories = () => {
    return axios.get(`${API_URL}doctor/all/specialities`);
}


export const getAllDoctorsBySpecialities = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctors?speciality=${payload}&sort=uuid&sort=desc`);
}

//doctor-image
export const getDoctorImage = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctorimage?doctorName=${payload}`);
}


//doctor-adress
export const getDoctorAbout = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctoraboutme?doctorName=${payload}`);
}

//doctor about me
export const getDoctorAdress = (payload, username) => {
    return axios.get(`${API_URL}doctor/all/doctoraddress?doctorName=${payload}&userName=${username}`);
}


//doctor diases treat
export const getDoctorTreat = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctortreatedservicemodel?doctorName=${payload}`);
}


//doctor-opinion
export const getDoctorOpinion = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctoropinions?doctorName=${payload}`);
}

//doctor-clinic
export const getDoctorClinic = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctorclinics?doctorName=${payload}`);
}
//doctor-opinions
export const getDoctorOpinions = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctoropinions?doctorName=${payload}`);
}
//doctor-graduates
export const getDoctorGraduates = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctorgraduates?doctorName=${payload}`);
}
//doctor-languages
export const getDoctorLanguages = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctorlanguages?doctorName=${payload}`);
}


//doctor-experiences
export const getDoctorExperiences = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctorexperiences?doctorName=${payload}`);
}
//doctor-comments
export const getDoctorComments = (payload) => {
    return axios.get(`${API_URL}doctor/all/doctorcomments?doctorName=${payload}`);
}
//popular-doctors
export const getPopularDoctors = () => {
    return axios.get(`${API_URL}doctor/all/populardoctors`);
}
//all-doctors
export const getAllDoctors = () => {
    return axios.get(`${API_URL}doctor/all/subscribeddoctors`);
}

//popular doctors without authorization
export const getPopularDocsWithoutAuth = () => {
    return axios.get(`${API_URL}test/all/populardoctors?sort=date&sort=desc`);
}
//popular doctors image without authorization
export const getPopularDocsImageWithoutAuth = (id) => {
    return axios.get(`${API_URL}test/filesbyid/${id}`, {
        responseType: 'arraybuffer'
    });
}