import React from 'react'
import AddSurgeries from '../../../../../../components/user/profile/link-pages/health-history/surgeries/add-surgeries/AddSurgeries'

const AddSurgeriesPage = () => {
    return (
        <div>
            <AddSurgeries />
        </div>
    )
}

export default AddSurgeriesPage
