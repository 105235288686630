import React from 'react'
import "./doctor-adress.scss"

import { CiHospital1 } from "react-icons/ci";
import { RiHome6Line } from "react-icons/ri";
import { FaStar } from "react-icons/fa6";
import { IoWifiOutline } from "react-icons/io5";
import { MdCreditScore } from "react-icons/md";
import { LuParkingSquare } from "react-icons/lu";
import { IoIosFootball } from "react-icons/io";
import { FaCoffee } from "react-icons/fa";
const DoctorAdress = ({ adress, clinic }) => {
    return (
        <div id='doctor-adress' className='box-style'>
            <div className="top">
                <CiHospital1 />
                <div className="clinic-info">
                    <span>Clinic Info</span>
                    <span>{adress[0]?.contactName}</span>
                </div>
            </div>
            <div className="line"></div>
            <div className="middle">
                <div>
                    <RiHome6Line />
                    <span>Adress</span>
                </div>
                <p>{adress[0]?.address}</p>
            </div>
            <div className="line"></div>
            <div className="bottom">
                <div>
                    <FaStar />
                    <span>Facilities</span>
                </div>
                <div className='facilities'>
                    <span><IoWifiOutline />Wi-Fi</span>
                    <span><MdCreditScore />Credit Card</span>
                    <span><LuParkingSquare />Car Park</span>
                    <span><IoIosFootball />Children's playground</span>
                    <span><FaCoffee />Catering</span>
                </div>
            </div>
        </div>
    )
}

export default DoctorAdress
