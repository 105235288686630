import React from 'react'
import "./special-equipment-form.scss"
import { Form, FormGroup } from 'react-bootstrap'
const SpecialEquipmentForm = ({ info, setInfo }) => {

    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    return (
        <div id='special-equipments-form'>
            <Form>
                <FormGroup>
                    <Form.Label>What is it?</Form.Label>
                    <Form.Control
                        type='text'
                        name='whatisequipment'
                        onChange={handleChange}
                        value={info?.whatisequipment}
                    />
                </FormGroup>
                <FormGroup>
                    <Form.Label>Where is it?</Form.Label>
                    <Form.Control
                        type='text'
                        name='whereisequipment'
                        onChange={handleChange}
                        value={info?.whereisequipment}
                    />
                </FormGroup>

            </Form>
        </div>
    )
}

export default SpecialEquipmentForm
