import React, { useState } from 'react'
import "./support-sss.scss"
import SSS from '../sss/SSS'
import SupportEmail from '../support-email/SupportEmail'
import SupportTel from '../support-tel/SupportTel'
const SupportSSS = () => {

    const [isSSSbtnActive, setIsSSSbtnActive] = useState(true)
    const [isEmailbtnActive, setIsEmailbtnActive] = useState(false)
    const [isTelbtnActive, setIsTelbtnActive] = useState(false)

    const handleSSS = () => {
        setIsSSSbtnActive(true)
        setIsEmailbtnActive(false)
        setIsTelbtnActive(false)
    }
    const handleEmail = () => {
        setIsEmailbtnActive(true)
        setIsSSSbtnActive(false)
        setIsTelbtnActive(false)
    }
    const handleTel = () => {
        setIsTelbtnActive(true)
        setIsEmailbtnActive(false)
        setIsSSSbtnActive(false)
    }



    return (
        <div id='support-sss' className='box-style'>
            <div className="sss-buttons">
                <span className={isSSSbtnActive ? "active" : ""} onClick={handleSSS}>SSS</span>
                <span className={isEmailbtnActive ? "active" : ""} onClick={handleEmail}>E-posta</span>
                <span className={isTelbtnActive ? "active" : ""} onClick={handleTel}>Tel</span>
            </div>
            {
                isSSSbtnActive && <SSS />
            }
            {
                isEmailbtnActive && <SupportEmail />
            }
            {
                isTelbtnActive && <SupportTel />
            }


        </div>
    )
}

export default SupportSSS
