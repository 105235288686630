import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'
import { postSurgeries } from '../../../../../../../api/user-services/healt-info-services'

const AddSurgeries = () => {

    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            uuid: "",
            surgerie: "",
            reason: "",
            hospital: "",
            symptomps: "",
            date: "",
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target

        setInfo({ ...info, [name]: value })
    }




    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            await postSurgeries(info)
            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }

    console.log(info);

    return (
        <div id='add-surgeries' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Surgerie</Form.Label>
                    <Form.Control
                        type='text'
                        name='surgerie'
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type='date'
                        name='date'
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                        type='text'
                        name='reason'
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Hospital</Form.Label>
                    <Form.Control
                        type='text'
                        name='hospital'
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button className='button' type='submit'>Save</Button>
            </Form>
        </div>
    )
}

export default AddSurgeries
