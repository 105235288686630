import React, { useState } from 'react'
import "./profile-links.scss"
import { Link } from 'react-router-dom'

//icons health
import { MdOutlineHealthAndSafety } from "react-icons/md"
import { RiHealthBookLine } from "react-icons/ri";
import { GiMedicines } from "react-icons/gi";
import { TbNotes } from "react-icons/tb";
import { TiFolderAdd } from "react-icons/ti";
import { MdOutlineSpeakerNotes } from "react-icons/md";

//icons account
import { FaUser } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { RiCouponLine } from "react-icons/ri";
import { IoMdInformationCircle } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { MdAppsOutage } from "react-icons/md";
import { RiLogoutBoxRLine } from "react-icons/ri";



const ProfileLinks = ({ setIsLogoutClicked }) => {

    const [isActive, setIsActive] = useState(false)
    const [isHealthBtnActive, setIsHealthBtnActive] = useState(true)
    const [isAccountBtnActive, setIsAccountBtnActive] = useState(false)

    const handleHealthClick = () => {
        setIsHealthBtnActive(true)
        setIsAccountBtnActive(false)

    }

    const handleAccountClick = () => {
        setIsAccountBtnActive(true)
        setIsHealthBtnActive(false)
    }


    const btnBg = "#041734";


    return (
        <div id='profile-links'>
            <div className="top">
                <div className="button w-100"
                    onClick={handleHealthClick}
                    style={{ backgroundColor: isHealthBtnActive ? "" : btnBg, opacity: isHealthBtnActive ? "" : "0.5" }}
                >My Health</div>
                <div className="button w-100"
                    onClick={handleAccountClick}
                    style={{ backgroundColor: isAccountBtnActive ? "" : btnBg, opacity: isAccountBtnActive ? "" : "0.5" }}
                >My Account</div>
            </div>
            {
                isHealthBtnActive && <div className="links links-health box-style">
                    <Link to="health-profile">
                        <MdOutlineHealthAndSafety />
                        <span>Health Profile</span>
                    </Link>
                    <Link to="health-history">
                        <RiHealthBookLine />
                        <span>Health History</span>
                    </Link>
                    <Link to="medicines">
                        <GiMedicines />
                        <span>Medicines</span>
                    </Link>
                    <Link to="prescriptions">
                        <TbNotes />
                        <span>Prescriptions</span>
                    </Link>
                    <Link to="laboratory-documents">
                        <TiFolderAdd />
                        <span>Laboratory Documents</span>
                    </Link>
                    <Link to="examination-history">
                        <MdOutlineSpeakerNotes />
                        <span>Examination History</span>
                    </Link>
                </div>
            }
            {
                isAccountBtnActive && <div className="links links-account box-style">
                    <Link to="profile-info">
                        <FaUser />
                        <span>Profile Info</span>
                    </Link>
                    <Link to="payment-history">
                        <MdPayments />
                        <span>Payment History</span>
                    </Link>
                    <Link to="coupon-rights">
                        <RiCouponLine />
                        <span>Coupon And Rights</span>
                    </Link>
                    <Link to="legal-informations">
                        <IoMdInformationCircle />
                        <span>Legal Information</span>
                    </Link>
                    <Link to="settings">
                        <IoSettingsSharp />
                        <span>Settings</span>
                    </Link>
                    <Link to="support-sss">
                        <MdSupportAgent />
                        <span>Support</span>
                    </Link>
                    <Link to="about-app">
                        <MdAppsOutage />
                        <span>About App</span>
                    </Link>
                    <Link to="" onClick={() => setIsLogoutClicked(true)}>
                        <RiLogoutBoxRLine />
                        <span>Log Out</span>
                    </Link>
                </div>
            }
        </div>
    )
}

export default ProfileLinks
