import React from 'react'
import "./about-system-content.scss"
const AboutSystemContent = ({ title1, title2, text1, text2, text3, text4, image, btnText, subTitle1, subTitle2, subTitle3, subTitle4 }) => {
    return (
        <div id='about-system-content'>
            <div className="content">
                <div className="desc-content">
                    <div className="desc">
                        <h3>{title1}</h3>
                        <div className='desc-item-content'>
                            <p className='p-sm'><span className='subtitle'>{subTitle1} </span>: {text1}</p>
                            <p className='p-sm'><span className='subtitle'>{subTitle2}</span> : {text2}</p>
                        </div>
                    </div>
                    <div className="line-i"></div>
                    <div className="desc">
                        <h3>{title2}</h3>
                        <div className='desc-item-content '>
                            <p className='p-sm'><span className='subtitle'>{subTitle3} </span>: {text3}</p>
                            <p className='p-sm'><span className='subtitle'>{subTitle4}</span> : {text4}</p>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <img src={image} alt="" />
                </div>
            </div>
        </div>
    )
}

export default AboutSystemContent
