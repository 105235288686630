import React from 'react'
import "./discover-details.scss"
import DiscoverDetailItem from '../../../user/discover/discover-detail-item/DiscoverDetailItem'
const DiscoverDetails = ({ currentCategory }) => {

    console.log(currentCategory);
    return (
        <div id='discover-details' className='box-style'>
            <div className="top" >
                <div className="item-image">
                    <img src={currentCategory?.[0].categoryItemImage} alt="" />
                </div>
            </div>
            <div className='discover-detail-content'>
                {
                    currentCategory?.map((item) => (
                        <DiscoverDetailItem key={item.uuid} item={item.categoryTitleSet} />
                    ))
                }
            </div>
        </div>
    )
}

export default DiscoverDetails
