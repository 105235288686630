

import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { PiUploadSimple } from "react-icons/pi";
import { MdDeleteOutline, MdFileDownload } from "react-icons/md";
import "./add-prescriptions.scss";
import { swalToast } from '../../../../../../../swalMessages/swalMessages';
import { useNavigate } from 'react-router-dom';
import { addPrescriptionsImage, addPrescriptionsInfo, getPrescriptionsInfo } from '../../../../../../../api/user-services/healt-info-services';
import Loading from '../../../../../../loading/Loading';
import { TiTick } from "react-icons/ti";
import filePlaceholder from "../../../../../../../assets/fileplaceholder.png";

const AddPrescriptions = () => {
    const username = JSON.parse(sessionStorage.getItem("user"));
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [info, setInfo] = useState({
        id: "",
        date: "",
        situation: "",
        fileuuid: [],
        username: username
    });

    const loadData = async () => {
        try {
            const resp = await getPrescriptionsInfo();
            setData(resp.data);
        } catch (error) {
            console.log("prescriptions cekilemedi");
        }
    };

    const handleChange = (e) => {
        const { name, checked, value } = e.target;
        const newValue = name === 'currentlyUsed' ? checked : value;
        setInfo({ ...info, [name]: newValue });
    };

    const handleUpload = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*,.pdf,.txt';
        input.multiple = true;
        input.onchange = async (e) => {
            const selectedFiles = e.target.files;
            if (selectedFiles.length > 0) {
                setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
                setLoading(true);

                for (const file of selectedFiles) {
                    const formData = new FormData();
                    formData.append("file", file);

                    try {
                        const respImage = await addPrescriptionsImage(file.name, username, formData);
                        const imageId = respImage.data.message.split(":")[1].trim();
                        setInfo(prevInfo => ({
                            ...prevInfo,
                            fileuuid: [...prevInfo.fileuuid, imageId]
                        }));
                        setLoading(false);
                    } catch (error) {
                        console.log("file eklenemedi");
                        setLoading(false);
                    }
                }
            }
        };
        input.click();
    };

    const handleDelete = (index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const handleDownload = (file) => {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await addPrescriptionsInfo(info);
            swalToast("Success", "Saved");
            navigate(-1);
            setLoading(false);
        } catch (error) {
            swalToast("Error", "Something went wrong", "error");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div id='add-prescriptions' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type='date'
                        name='date'
                        onChange={handleChange}
                        value={data?.date || info?.date || ""}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Situation</Form.Label>
                    <Form.Select
                        name='situation'
                        onChange={handleChange}
                    >
                        <option value="">{data?.situation || info?.situation || ""}</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Active">Active</option>
                        <option value="Expired">Expired</option>
                    </Form.Select>
                </Form.Group>

                <div className="top-btn">
                    <div onClick={handleUpload}>
                        <PiUploadSimple />
                        {
                            loading ? <span className='image-uploading'>File Uploading...</span> : <span>Upload</span>
                        }
                    </div>
                </div>
                <Button className='button' type='submit' disabled={loading}>
                    Save
                </Button>
            </Form>
            <div className="uploaded-images">
                {files.length > 0 && (
                    <div className='reports-wrapper'>
                        {files.map((file, index) => (
                            <div key={index}>
                                {file.type.startsWith("image/") ? (
                                    <img src={URL.createObjectURL(file)} alt={`Uploaded ${index}`} />
                                ) : (
                                    <img src={filePlaceholder} alt={`Uploaded ${index}`} />
                                )}
                                <span>{file.name}</span>
                                <div className="icons">
                                    {
                                        loading ? <Loading /> : <TiTick />
                                    }
                                    <MdFileDownload onClick={() => handleDownload(file)} />
                                    <MdDeleteOutline onClick={() => handleDelete(index)} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddPrescriptions;


