import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import countries from "../../../../../../countries/countriesCode.json"
import { addContactInfos, getContactInfos, getSummaryProfileInfo, updateContactInfos } from '../../../../../../api/user-services/user-account-services'
import { swalToast } from '../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'






const ContactInfo = () => {
    const navigate = useNavigate()
    const username = JSON.parse(sessionStorage.getItem("user"))
    const [contactInfos, setContactInfos] = useState()
    const [data, setData] = useState()
    const [code, setCode] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const newPhoneNumber = `${code}${phoneNumber}`

    const [bussinessCode, setBussinessCode] = useState()
    const [bussinessPhoneNumber, setBussinessPhoneNumber] = useState()
    const newBussinessPhoneNumber = `${bussinessCode}${bussinessPhoneNumber}`

    const [info, setInfo] = useState(
        {
            uuid: "",
            phone: "",
            businessPhone: "",
            email: "",
            country: "",
            address: "",
            username: username
        }
    )

    const getPhoneNumber = (e) => {
        setPhoneNumber(e.target.value)
    }
    const getCode = (e) => {
        setCode(e.target.value)
    }
    const getBussinessCode = (e) => {
        setBussinessCode(e.target.value)
    }
    const getBussinessPhoneNumber = (e) => {
        setBussinessPhoneNumber(e.target.value)
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        setInfo({ ...info, [name]: value })
    }


    const handleSubmit = async (e) => {

        e.preventDefault()

        try {

            if (contactInfos?.[0].username === username) {

                await updateContactInfos({ ...info, businessPhone: newBussinessPhoneNumber })
                swalToast("Personal info updated successfully", "", "success")
            } else {

                await addContactInfos({ ...info, businessPhone: newBussinessPhoneNumber })
                swalToast("Personal info added successfully", "", "success")
            }
            navigate(-1)

        } catch (error) {
            swalToast("Something went wrong", "", "error")
        }
    }



    const loadData = async () => {
        try {
            const resp = await getSummaryProfileInfo()
            const respContactInfos = await getContactInfos()
            setContactInfos(respContactInfos.data)
            setData(resp.data)
        } catch (error) {
            console.log("emergency cekilemedi");
        }
    }

    useEffect(() => {
        loadData()

        if (contactInfos) {
            setInfo(prevInfo => (
                {
                    ...prevInfo,
                    phone: contactInfos?.[0].phone,
                    email: contactInfos?.[0].email,
                    country: contactInfos?.[0].country,
                }
            ))
        } else {
            if (data) {
                setInfo(prevInfo => (
                    {
                        ...prevInfo,
                        phone: data?.[0].phonenumber,
                        email: data?.[0].email,
                        country: data?.[0].country,
                    }
                ))
            }
        }

    }, [])

    console.log(data);
    console.log(contactInfos);
    console.log(info);


    return (
        <div id='personal-info' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <div className='d-flex align-items-center gap-2'>
                        <Form.Select className='w-25'
                            disabled
                        >
                            <option value="0">Code</option>
                            {
                                countries.map((country, i) => (
                                    <option key={i} value={country.dial_code}>{country.dial_code}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Control
                            placeholder={data?.phonenumber}
                            disabled
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Business Phone</Form.Label>
                    <div className='d-flex align-items-center gap-2'>
                        <Form.Select className='w-25'
                            onChange={getBussinessCode}
                            value={bussinessCode}
                        >
                            <option value="0">Code</option>
                            {
                                countries.map((country, i) => (
                                    <option key={i} value={country.dial_code}>{country.dial_code}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Control
                            type='number'
                            onChange={getBussinessPhoneNumber}
                            value={info?.businessPhone || bussinessPhoneNumber}

                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                        type='email'
                        name='email'
                        value={info?.email}
                        onChange={handleChange}
                        placeholder={data?.email}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                        onChange={handleChange}
                        name='country'
                        value={info?.country}
                    >
                        <option value="0">Chose Country</option>
                        {
                            countries.map((country, i) => (
                                <option key={i} value={country.name}>{country.name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Adress</Form.Label>
                    <Form.Control type='text' as={"textarea"} style={{ maxHeight: "100px", minHeight: "100px" }}
                        onChange={handleChange}
                        name='address'
                        value={info?.address}
                    />
                </Form.Group>
                <Button className='button' type='submit'>Save</Button>
            </Form>
        </div>
    )
}

export default ContactInfo
