import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { addGeneralInfos, updateGeneralInfos } from '../../../../../../api/user-services/user-account-services'
import { swalToast } from '../../../../../../swalMessages/swalMessages'

const GeneralInfo = ({ data }) => {
    const username = JSON.parse(sessionStorage.getItem("user"))
    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            id: "",
            language: "",
            educationalstatus: "",
            profession: "",
            username: username
        }
    )


    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (data?.[0]?.id) {
                await updateGeneralInfos(info)
                swalToast("Personal info updated successfully", "", "success")
            } else {
                await addGeneralInfos(info)
                swalToast("Personal info added successfully", "", "success")
            }
            navigate(-1)
        } catch (error) {
            swalToast("Something went wrong", "", "error")
        }
    }


    console.log(info);

    useEffect(() => {
        if (data) {
            setInfo(prevInfo => (
                {
                    ...prevInfo,
                    id: data?.[0]?.id,
                    language: data?.[0]?.language,
                    educationalstatus: data?.[0]?.educationalstatus,
                    profession: data?.[0]?.profession
                }
            ))
        }
    }, [data])


    return (
        <div id='general-info' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Language</Form.Label>
                    <Form.Select
                        name='language'
                        value={info?.language}
                        onChange={handleChange}
                    >
                        <option value="0">Language</option>
                        <option value="English">English</option>
                        <option value="German">German</option>
                        <option value="French">French</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Educational Status</Form.Label>
                    <Form.Select
                        name='educationalstatus'
                        value={info?.educationalstatus}
                        onChange={handleChange}
                    >
                        <option value="Education">Education</option>
                        <option value="Elementary School Graduate">Elementary School Graduate</option>
                        <option value="Middle School Graduate">Middle School Graduate</option>
                        <option value="High School Graduate">High School Graduate</option>
                        <option value="Associate's Degree Holder">Associate's Degree Holder</option>
                        <option value="Bachelor's Degree Holder">Bachelor's Degree Holder</option>
                        <option value="Master's Degree Holder">Master's Degree Holder</option>
                        <option value="Doctorate Degree Holder">Doctorate Degree Holder</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Profession</Form.Label>
                    <Form.Control
                        type='text'
                        name='profession'
                        onChange={handleChange}
                        value={info?.profession}
                    />
                </Form.Group>
                <Button className='button' type='submit'>
                    SAVE
                </Button>
            </Form>
        </div>
    )
}

export default GeneralInfo
