import React from 'react'
import ProfileInfo from '../../../../../components/user/profile/link-pages-account/profile-info/ProfileInfo'

const ProfileInfoPage = () => {
    return (
        <div>
            <ProfileInfo />
        </div>
    )
}

export default ProfileInfoPage
