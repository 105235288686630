import React from 'react'
import "./package-card.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showModalSignUp } from '../../../store/sign-up/signupSlice'
const PackageCard = () => {

    const isUserLogin = useSelector(state => state.auth.isAuthenticated)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = useLocation().pathname

    const planName = "main-plan"

    const handleSignUp = () => {
        if (isUserLogin) {
            if (path === "/") {
                navigate(`/user/packages-detail/${planName}`)
            } else if (path === "/user") {
                navigate(`packages-detail/${planName}`)
            }
        } else {
            dispatch(showModalSignUp())
        }
    }

    return (
        <div id='package-card' className='box-style'>
            <div className="pack-image">
                <img src={require("../../../assets/payment/p1.jpeg")} alt="" />
            </div>
            <div className="pack-info">
                <span>Main Plan</span>
                <div className='pack-info-text' >
                    <h4 >Basic Package Description</h4>
                    <h5>Basic Package Contents:</h5>
                    <p>Our basic package is designed to provide you with a comprehensive evaluation of your medical reports and facilitate obtaining a second opinion regarding your diagnosis. The contents of the package are as follows:</p>
                    <h6>Report Evaluation:</h6>
                    <p>Your diagnosis reports are meticulously reviewed by three highly successful specialist doctors in their respective fields in Turkey, along with their teams. Each doctor carefully evaluates your reports and provides personalized comments.</p>
                    <h6>Expert Opinions:</h6>
                    <p>Detailed evaluations and diagnostic comments from three different doctors are presented to you in written form and, if necessary, accompanied by visual materials.</p>
                    <h6>Second Opinion:</h6>
                    <p>You are given the opportunity to have a one-on-one consultation with one of the three doctors whose evaluations you prefer. This consultation is aimed at confirming the accuracy of your diagnosis and addressing any questions you may have about your treatment process.</p>

                    <h5>Benefits and Value of the Package:</h5>

                    <h6>1. Opportunity to Get a Second Opinion:</h6>
                    <p><span>Provides Assurance:</span> By verifying the accuracy of your diagnosis with different experts, you can proceed with your treatment process with confidence.</p>
                    <p><span>Treatment Strategy: </span> Obtaining a second opinion helps you make more informed and secure decisions about your treatment options.</p>

                    <h6>2. Expert Opinions:</h6>
                    <p><span>Quality Evaluation:</span> Detailed examinations by successful doctors in their fields ensure the most accurate assessment of your diagnosis.</p>
                    <p><span>Personalized Comments:</span> Each doctor’s personalized comments help you understand the unique aspects of your condition and treatment options.</p>

                    <h6>3. Information and Education:</h6>
                    <p><span>Education: </span> By gaining more information about your condition, you can take an active role in your treatment process.</p>
                    <p><span>Personalized Comments:</span> The provided evaluation reports and second opinion offer in-depth knowledge about your condition and treatment options.</p>

                    <h6>4. Reducing Stress and Anxiety:</h6>
                    <p><span>Relief: </span> Common views from different specialists reduce uncertainties about your condition and boost your morale.</p>
                    <p><span>Support:</span> The second opinion consultation with the chosen doctor allows you to get answers to your questions and alleviates your concerns.</p>

                    <h6>5. Security and Privacy:</h6>
                    <p><span>Data Protection: </span> All your medical information is processed and protected according to privacy and security standards.</p>
                    <p><span>Reliable Service: </span> Evaluations by specialist doctors ensure you receive accurate and reliable information.</p>
                </div>
            </div>
            <div className="pack-buy">
                <div className="pack-buy-top">
                    <span>Very Good 8.3</span>
                    <span>(132 comment)</span>
                </div>
                <div className="pack-buy-bottom">
                    <span>199$</span>

                    <button className="button" onClick={handleSignUp}>Buy</button>

                </div>
            </div>
        </div>
    )
}

export default PackageCard
