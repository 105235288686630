import React from 'react'
import PackageWhitePaperCont from '../../../components/containers/packages-page/PackageWhitePaperCont'


const PackagesWhitePaper = () => {
    return (
        <div id='package-white-paper'>
            <PackageWhitePaperCont />
        </div>
    )
}

export default PackagesWhitePaper
