import React, { useEffect, useState } from 'react'
import "../habits-forms.scss"
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { postSleepHabit, updateSleepHabit } from '../../../../../../../api/user-services/healt-info-services'
import { useNavigate } from 'react-router-dom'
const SleepForm = ({ data }) => {

    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            id: "",
            howmanyhours: "",
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (data?.[0]?.id) {
                await updateSleepHabit(info)
            } else {
                await postSleepHabit(info)

            }
            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }

    useEffect(() => {
        if (data) {
            setInfo({
                ...info,
                id: data?.[0]?.id,
                howmanyhours: data?.[0]?.howmanyhours,
            });
        }
    }, [data]);


    console.log(data);
    // console.log(info);



    return (
        <div id='sleep-form' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>How many hours do you sleep?</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder={data?.howmanyhours || "0"}
                        onChange={handleChange}
                        name='howmanyhours'
                        value={info?.howmanyhours}
                    />
                </Form.Group>


                <Button className='button' type='submit' >Save</Button>
            </Form>
        </div>
    )
}

export default SleepForm
