import React, { useEffect, useState } from 'react'
import "./disability-situations.scss"

//icons
import { FaRegCircleUser } from "react-icons/fa6";
import { Form, FormGroup } from 'react-bootstrap';
import DisabilityStatusForm from './disability-statu-form/DisabilityStatusForm';
import SpecialEquipmentForm from './special-equipment-form/SpecialEquipmentForm';
import { getDisabilitySituations, postDisabilitySituations } from '../../../../../../api/user-services/healt-info-services';
import { swalToast } from '../../../../../../swalMessages/swalMessages';
import { useNavigate } from 'react-router-dom';
const DisabilitySituations = ({ data }) => {

    const [isAddDisabilityBtn, setIsAddDisabilityBtn] = useState(true)
    const [isAddEquipmentBtn, setIsAddEquipmentBtn] = useState(false)
    const username = JSON.parse(sessionStorage.getItem("user"))
    const navigate = useNavigate()

    const handleAddDisability = () => {
        setIsAddDisabilityBtn(true)
        setIsAddEquipmentBtn(false)
    }
    const handleAddEquipment = () => {
        setIsAddEquipmentBtn(true)
        setIsAddDisabilityBtn(false)
    }

    const bgBtn = "#041734"

    const [info, setInfo] = useState(
        {
            uuid: "",
            status: "",
            percentage: "",
            whatisequipment: "",
            whereisequipment: ""
        }
    )



    const handleSubmit = async (e) => {
        try {
            await postDisabilitySituations(username, info)
            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }

    useEffect(() => {
        if (data) {
            setInfo({
                status: data?.[data.length - 1].status,
                percentage: data?.[data.length - 1].percentage,
                whatisequipment: data?.[data.length - 1].whatisequipment,
                whereisequipment: data?.[data.length - 1].whereisequipment
            })
        }
    }, [data])





    return (
        <div id='disability-situations' className='box-style'>
            <div className="top">
                <div className='button' style={{ backgroundColor: isAddDisabilityBtn ? bgBtn : "" }} onClick={handleAddDisability}>
                    <span>Add Disability Status</span>
                </div>
                <div className='button' style={{ backgroundColor: isAddEquipmentBtn ? bgBtn : "" }} onClick={handleAddEquipment}>
                    <span>Special Equipments / Add Equipments</span>
                </div>
            </div>

            {
                isAddDisabilityBtn && <div className="disability-status">
                    <DisabilityStatusForm info={info} setInfo={setInfo} />
                </div>
            }

            {
                isAddEquipmentBtn && <div className="add-equipments">
                    <SpecialEquipmentForm info={info} setInfo={setInfo} />
                </div>
            }
            <button className="button w-100 mt-5" onClick={handleSubmit}>Add</button>
        </div>
    )
}

export default DisabilitySituations
