import React from 'react'
import "./tabbar.scss"
import { Link } from 'react-router-dom'
import { MdOutlineExplore } from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi2";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { AiOutlineProfile } from "react-icons/ai";
import { IoMdHeartEmpty } from "react-icons/io";
const Tabbar = () => {
    return (
        <section id='tabbar'>
            <div className="tabbar-content">
                <Link className='aside-link' to="/user">
                    <div>
                        <HiOutlineHome />
                    </div>
                    <span>Home</span>
                </Link>

                <Link className='aside-link' to="meetings-main">
                    <div><IoCalendarNumberOutline /></div>
                    <span>Meetings</span>
                </Link>

                <Link className='aside-link login-btn' to="discover">
                    <div><MdOutlineExplore /></div>
                </Link>

                <Link className='aside-link' to="profile">
                    <div><AiOutlineProfile /></div>
                    <span>Profile</span>
                </Link>

                <Link className='aside-link' to="">
                    <div><IoMdHeartEmpty /></div>
                    <span>Favorites</span>
                </Link>
            </div>
        </section>
    )
}

export default Tabbar
