import React, { useState } from 'react'
import "./health-info.scss"
import { Form } from 'react-bootstrap'
import { postHealthInfo } from '../../../../../../api/user-services/healt-info-services'
import { swalToast } from '../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'
const HealthInfo = ({ data }) => {

    const navigate = useNavigate()
    const [info, setInfo] = useState({
        username: JSON.parse(sessionStorage.getItem("user")),
        height: "",
        weight: "",
        bloodType: ""
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            await postHealthInfo(info)
            swalToast("Health Info Updated", "Health Info Updated Successfully", "success")
            navigate(-1)
        } catch (error) {
            console.log("healt info post edilirken hata olustu");
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }

    }







    return (
        <div id='health-info' className='box-style'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Height</Form.Label>
                    <div className='form-group-item'>
                        <Form.Control
                            type='number'
                            placeholder={`${data?.[0]?.height}` || 'Enter your height'}
                            onChange={handleChange}
                            name='height'
                        />
                        <span>cm</span>
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Weight</Form.Label>
                    <div className="form-group-item">
                        <Form.Control
                            type='number'
                            placeholder={`${data?.[0]?.weight}` || 'Enter your weight'}
                            onChange={handleChange}
                            name='weight'
                        />
                        <span>kg</span>
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Blood Type</Form.Label>
                    <Form.Select
                        onChange={handleChange}
                        name='bloodType'
                    >
                        <option value="">{data?.[0]?.bloodType || "blood type"}</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                    </Form.Select>
                </Form.Group>
                <button type='submit' className="button">Save</button>
            </Form>
        </div>
    )
}

export default HealthInfo
