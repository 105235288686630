import React from 'react'
import "./payment-container.scss"
import PaymentForm from '../../user/payment-form/PaymentForm'
import Spacer from '../../spacer/Spacer'
const PaymentContainer = ({ meetingInfo, userLocaleInfo, isPaymentHTMLContent, setIsPaymentHTMLContent }) => {
    return (
        <div id='payment-container' className='box-style'>
            <Spacer />
            <PaymentForm meetingInfo={meetingInfo} userLocaleInfo={userLocaleInfo} isPaymentHTMLContent={isPaymentHTMLContent} setIsPaymentHTMLContent={setIsPaymentHTMLContent} />
        </div>
    )
}

export default PaymentContainer
