import React from 'react'
import "./delete-account.scss"

import { AiOutlineUserDelete } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

const DeleteAccount = () => {

    const navigate = useNavigate()

    return (
        <div id='delete-account' className='box-style'>
            <div className="warning-box">
                <AiOutlineUserDelete />
            </div>
            <span>Are you sure you want to delete your account?</span>
            <p>When you close your account, your general profile will be deactivated. If you change your mind, you can reactivate it by logging back into your account. During this period, there will be no access to your health data in any way.</p>
            <button className="button" onClick={() => navigate(-1)}>Back</button>
            <button className="button">Delete</button>
        </div>
    )
}

export default DeleteAccount
