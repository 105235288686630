import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import countries from "../../../../../../countries/countriesCode.json"
import { useNavigate } from 'react-router-dom'
import { addPersonalInfos, updatePersonalInfos } from '../../../../../../api/user-services/user-account-services'
import { swalToast } from '../../../../../../swalMessages/swalMessages'

const PersonalInfo = ({ data }) => {
    const username = JSON.parse(sessionStorage.getItem("user"))
    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            id: "",
            name: "",
            lastname: "",
            birthdate: "",
            gender: "",
            maritalStatus: "",
            identificationNumber: "",
            passportnumber: "",
            nationality: "",
            residence: "",
            username: username
        }
    )




    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (data?.id) {
                await updatePersonalInfos(info)
                swalToast("Personal info updated successfully", "", "success")
            } else {
                await addPersonalInfos(info)
                swalToast("Personal info added successfully", "", "success")
            }
            navigate(-1)
        } catch (error) {
            swalToast("Something went wrong", "", "error")
        }
    }

    useEffect(() => {
        if (data) {
            setInfo(prevInfo => (
                {
                    ...prevInfo,
                    id: data?.id,
                    name: data?.name,
                    lastname: data?.lastname,
                    birthdate: data?.birthdate,
                    gender: data?.gender,
                    maritalStatus: data?.maritalStatus,
                    identificationNumber: data?.identificationNumber,
                    passportnumber: data?.passportnumber,
                    nationality: data?.nationality,
                    residence: data?.residence
                }
            ))
        }
    }, [data])


    console.log(info);

    return (
        <div id='personal-info' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type='text'
                        name='name'
                        value={info?.name}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Lastname</Form.Label>
                    <Form.Control
                        type='text'
                        name='lastname'
                        value={info?.lastname}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Birthdate</Form.Label>
                    <Form.Control
                        type='date'
                        name='birthdate'
                        value={info?.birthdate}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                        name='gender'
                        value={info?.gender}
                        onChange={handleChange}
                    >
                        <option value="">Chose</option>
                        <option value="Man">Man</option>
                        <option value="Women">Women</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Marital status</Form.Label>
                    <Form.Select
                        name='maritalStatus'
                        value={info?.maritalStatus}
                        onChange={handleChange}
                    >
                        <option value="">Chose</option>
                        <option value="Married">Married</option>
                        <option value="Single">Single</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Identification number</Form.Label>
                    <Form.Control
                        type='number'
                        name='identificationNumber'
                        value={info?.identificationNumber}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Passport number</Form.Label>
                    <Form.Control
                        type='text'
                        name='passportnumber'
                        value={info?.passportnumber}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nationality</Form.Label>
                    <Form.Select
                        name='nationality'
                        value={info?.nationality}
                        onChange={handleChange}
                    >
                        <option value="0">Chose Nation</option>
                        {
                            countries.map((country, i) => (
                                <option key={i} value={country.name}>{country.name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Residence</Form.Label>
                    <Form.Control
                        type='text'
                        name='residence'
                        value={info?.residence}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button className='button' type='submit'>
                    SAVE
                </Button>
            </Form>
        </div>
    )
}

export default PersonalInfo
