import React, { useEffect, useState } from 'react'
import PersonalInfo from '../../../../../components/user/profile/link-pages-account/profile-info/personal-info/PersonalInfo'
import { getPersonalInfos } from '../../../../../api/user-services/user-account-services'

const PersonalInfoPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getPersonalInfos()
            setData(resp.data)
        } catch (error) {
            console.log("personel info cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);


    return (
        <div id='personal-info-page'>
            <PersonalInfo data={data} />
        </div>
    )
}

export default PersonalInfoPage
