import React from 'react'
import "./no-record.scss"

import { FcDocument } from "react-icons/fc";
const NoRecord = ({ title }) => {
    return (
        <div id='no-record'>
            <span>{title}</span>
            <div className='no-record-content'>
                <FcDocument />
                <span>No Record</span>
            </div>
        </div>
    )
}

export default NoRecord
