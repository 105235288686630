import React, { useEffect, useState } from 'react'
import "../habits-forms.scss"
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'
import { postSexualActivity, updateSexualActivity } from '../../../../../../../api/user-services/healt-info-services'
const SexualForm = ({ data }) => {

    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            id: "",
            sexualPartner: "",
            birthControl: "",
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo({ ...info, [name]: value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (data?.[0]?.id) {
                await updateSexualActivity(info)
            } else {
                await postSexualActivity(info)
            }

            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }


    useEffect(() => {
        if (data) {
            setInfo({
                ...info,
                id: data?.[0]?.id,
                sexualPartner: data?.[0]?.sexualPartner,
                birthControl: data?.[0]?.birthControl
            });
        }
    }, [data]);


    console.log(data);
    console.log(info);



    return (
        <div id='sexual-form' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group className='d-flex align-items-center justify-content-between'>
                    <Form.Label>Sexually active</Form.Label>
                    <Form.Switch />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Sexual partner</Form.Label>
                    <Form.Select
                        onChange={handleChange}
                        name='sexualPartner'
                        value={info?.sexualPartner}
                    >
                        <option value="">{data?.sexualPartner}</option>
                        <option value="none">none</option>
                        <option value="women">women</option>
                        <option value="man">man</option>
                        <option value="both">both</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Birth control</Form.Label>
                    <Form.Select
                        onChange={handleChange}
                        name='birthControl'
                        value={info?.birthControl}
                    >
                        <option value="">{data?.birthControl}</option>
                        <option value="none">none</option>
                        <option value="Condom">Condom</option>
                        <option value="Pill">Pill</option>
                        <option value="Injection">Injection</option>
                        <option value="Patch">Patch</option>
                        <option value="Tubal ligation">Tubal ligation</option>
                        <option value="other">other</option>
                    </Form.Select>
                </Form.Group>

                <Button className='button' type='submit' >Save</Button>
            </Form>
        </div>
    )
}

export default SexualForm
