// import { EncryptionMode, UID, SDK_MODE } from "agora-rtc-sdk-ng";

// const config: configType = {
//   uid: 501,
//   appId: "12602fc17a5c45749f1091dd17e93e89",
//   channelName: "501",
//   rtcToken: "007eJxTYCgI3RPreD5J/9SLGPPJWj4dDlK+7s9vl9l15tw6ZF7IuliBwdDIzMAoLdnQPNE02cTU3MQyzdDA0jAlxdA81dI41cKy9vrX1IZARoY5VWsYGKEQxGdmMDUwZGAAANYWHcY=",
//   serverUrl: "",
//   proxyUrl: "http://localhost:8080/",
//   tokenExpiryTime: 600,
//   token: "007eJxTYCgI3RPreD5J/9SLGPPJWj4dDlK+7s9vl9l15tw6ZF7IuliBwdDIzMAoLdnQPNE02cTU3MQyzdDA0jAlxdA81dI41cKy9vrX1IZARoY5VWsYGKEQxGdmMDUwZGAAANYWHcY=",
//   encryptionMode: "aes-128-gcm2",
//   salt: "",
//   encryptionKey: "",
//   destChannelName: "",
//   destChannelToken: "",
//   destUID: 2,
//   secondChannel: "",
//   secondChannelToken: "",
//   secondChannelUID: 2,
//   selectedProduct: "rtc"
// };

// export type configType = {
//   uid: UID;
//   appId: string;
//   channelName: string;
//   rtcToken: string | null;
//   serverUrl: string;
//   proxyUrl: string;
//   tokenExpiryTime: number;
//   token: string;
//   encryptionMode: EncryptionMode;
//   salt: "";
//   encryptionKey: string;
//   destUID: number;
//   destChannelName: string,
//   destChannelToken: string,
//   secondChannel: string,
//   secondChannelToken: string
//   secondChannelUID: number,
//   selectedProduct: SDK_MODE
// };

// export default config;


import { EncryptionMode, UID, SDK_MODE } from "agora-rtc-sdk-ng";

const config = {
  uid: 501,
  appId: "12602fc17a5c45749f1091dd17e93e89",
  channelName: "501",
  // rtcToken: "007eJxTYCgI3RPreD5J/9SLGPPJWj4dDlK+7s9vl9l15tw6ZF7IuliBwdDIzMAoLdnQPNE02cTU3MQyzdDA0jAlxdA81dI41cKy9vrX1IZARoY5VWsYGKEQxGdmMDUwZGAAANYWHcY=",
  serverUrl: "",
  proxyUrl: "http://localhost:8080/",
  tokenExpiryTime: 600,
  // token: "007eJxTYCgI3RPreD5J/9SLGPPJWj4dDlK+7s9vl9l15tw6ZF7IuliBwdDIzMAoLdnQPNE02cTU3MQyzdDA0jAlxdA81dI41cKy9vrX1IZARoY5VWsYGKEQxGdmMDUwZGAAANYWHcY=",
  encryptionMode: "aes-128-gcm2",
  salt: "",
  encryptionKey: "",
  destChannelName: "",
  destChannelToken: "",
  destUID: 2,
  secondChannel: "",
  secondChannelToken: "",
  secondChannelUID: 2,
  selectedProduct: "rtc"
};

export default config
