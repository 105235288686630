import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./payment-info-modal.scss"

import { PiWarningCircle } from "react-icons/pi";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { LuAlarmCheck } from "react-icons/lu";
import { MdDoneOutline } from "react-icons/md";
const PaymentInfoModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id='payment-info-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <MdDoneOutline />
                    <span>Successful Transaction!</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span><PiWarningCircle /> </span>
                    <p>An appointment request has been created for Doctor John Doe.</p>
                </div>
                <div>
                    <span><FaRegCalendarCheck /> </span>
                    <p>19.12.2023</p>
                </div>
                <div>
                    <span><LuAlarmCheck /></span>
                    <p>11:10</p>
                </div>
                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
            </Modal.Body>
            <Modal.Footer>
                <Button className='button' onClick={props.onHide}>Close</Button>
                <Button className='button'>Complete</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PaymentInfoModal
