import React from 'react'
import "./discover-full-content.scss"


//icons
import { BsCameraReelsFill } from "react-icons/bs";
import { FaBook } from "react-icons/fa6";
import { SiGooglepodcasts } from "react-icons/si";
import YoutubeVideo from '../../../youtube-video-comp/YoutubeVideo';
const DiscoverFullContent = ({ content }) => {
    return (
        <div id='discover-full-content' className='box-style'>
            <div className="top">
                <h5>{content?.title}</h5>
                {
                    content?.textUrl && (
                        <>

                            <span><FaBook /> - Read </span>
                        </>
                    )
                }
                {
                    content?.videoUrl && (
                        <>

                            <span><BsCameraReelsFill /> - Watch </span>
                        </>
                    )

                }
                {
                    content?.podcastUrl && (
                        <>

                            <span><SiGooglepodcasts /> - Listen </span>
                        </>
                    )
                }
            </div>
            <div className="main-content">
                <div>
                    <p>{content?.description}</p>

                    {
                        content?.textUrl && (
                            <>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid saepe veniam excepturi optio debitis ex deleniti inventore numquam cum omnis. Ratione magni doloribus, exercitationem fugit praesentium reprehenderit dolor sunt repellendus!
                                </p>

                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione architecto, ex perferendis cupiditate libero molestiae.</p>

                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates excepturi iusto iure debitis optio esse harum commodi, omnis distinctio consequatur molestias laudantium iste aut in quibusdam doloremque eos autem perferendis vero! Nemo tenetur deserunt cupiditate possimus odit ipsum obcaecati est maxime doloribus consectetur error ducimus ipsa tempore quo qui, nam ex eum laboriosam, saepe harum pariatur. At qui consequuntur eveniet nobis, fugit tenetur neque optio dolorem quisquam officia exercitationem sapiente et maiores magnam aliquam laborum. Necessitatibus voluptas ipsum esse blanditiis nam ratione molestias saepe. Maxime voluptates enim in nam odit, dolore eos minima tenetur sequi magni unde provident eligendi dolor. Repellat fuga eius atque accusamus placeat blanditiis soluta veritatis corporis! Distinctio, illo, veniam vero perferendis quam voluptatem voluptatibus earum corrupti quasi eos sit vitae consequatur autem id, voluptatum sunt totam nesciunt impedit velit cumque fugiat asperiores itaque iusto sint. Sapiente officia explicabo voluptatibus odio a ea illum provident rerum reiciendis.</p>
                            </>
                        )
                    }
                    {
                        content?.videoUrl && (
                            <YoutubeVideo videoUrl={content?.videoUrl} />
                        )
                    }
                </div>
            </div>

        </div>
    )
}

export default DiscoverFullContent
