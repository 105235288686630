import React from 'react'
import "./diagnosis.scss"
import NoRecord from '../../../../../no-record/NoRecord'
import { Link } from 'react-router-dom'
const Diagnosis = ({ data }) => {


    console.log(data);

    return (
        <div id='diagnosis' className='box-style'>
            {
                (data && data?.length > 0) ? (
                    data?.map((item) => (
                        <div className="diagnosis" key={item.uuid}>
                            <div>
                                <span>Description</span>
                                <p>{item?.description}</p>
                            </div>
                            <div>
                                <span>Doctor</span>
                                <p>{item?.doctor || "This part was not filled in."}</p>
                            </div>
                            <div>
                                <span>Date</span>
                                <p>{item?.date}</p>
                            </div>
                        </div>
                    ))
                ) : (<NoRecord title={"Diagnosis"} />)
            }
            <Link className='button ' to="add-diagnosis">
                Add Diagnosis
            </Link>
        </div>
    )
}

export default Diagnosis
