import React, { useEffect, useState } from 'react'
import Medicines from '../../../../../components/user/profile/link-pages/medicines/Medicines'
import { getMedicinesInfo } from '../../../../../api/user-services/healt-info-services'

const MedicinesPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getMedicinesInfo()
            setData(resp.data)
        } catch (error) {
            console.log("medicines cekilemedi");
        }
    }


    useEffect(() => {
        loadData()
    }, [])


    console.log(data);

    return (
        <div>
            <Medicines data={data} />
        </div>
    )
}

export default MedicinesPage
