import React, { useEffect, useState } from 'react'
import MeetingDocCard from '../../../components/containers/meeting-doc-card/MeetingDocCard'
import { useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import "./meeting-page.scss"
import MeetingServices from '../../../components/user/meeting-options/meeting-services/MeetingServices'
import MeetingDetails from '../../../components/user/meeting-options/meeting-detail/MeetingDetails'
import PaymentContainer from '../../../components/containers/payment/PaymentContainer'
import MeetingControl from '../../../components/user/meeting-options/meeting-control/MeetingControl'
import { useDispatch, useSelector } from 'react-redux'
import { getReportType, reportType } from '../../../api/user-services/appointment-services'
import { encryptedSessionStorage } from '../../../helpers/functions/encrypt-storage'
import axios from 'axios'
import { setDate, setHour, setService } from '../../../store/service-date-slice/service-date-slice'

const MeetingPage = () => {

    const id = useParams().id
    const dispath = useDispatch()
    const [reportTypes, setReportTypes] = useState([])
    const [userLocaleInfo, setUserLocaleInfo] = useState()
    const [isPaymentHTMLContent, setIsPaymentHTMLContent] = useState(false)
    const [isPurposeEntered, setIsPurposeEntered] = useState(false);
    const isServiceSelected = useSelector(state => state.serviceAndDate.service)
    const isDateSelected = useSelector(state => state.serviceAndDate.date)
    const isHourSelected = useSelector(state => state.serviceAndDate.hour)

    const user = JSON.parse(sessionStorage.getItem("user"));
    const usermail = sessionStorage.getItem("email")
    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState('services');



    const loadData = async () => {
        try {
            const resp = await getReportType();
            const res = await fetch("https://ipapi.co/json")
            const data = await res.json()
            setUserLocaleInfo(data)
            setReportTypes(resp.data)
        } catch (error) {
            console.log(error);
        }
    }

    console.log(reportTypes);


    //!tablar arasi gecis, gerektiginde kullanilir
    // const handleTabChange = (tabKey) => {
    //     setActiveTab(tabKey);
    // };

    const handleNext = () => {
        const currentIndex = tabs.indexOf(activeTab);
        if (currentIndex < tabs.length - 1) {
            const nextTab = tabs[currentIndex + 1];
            setActiveTab(nextTab);
            dispath(setService(false))
            dispath(setDate(false))
            dispath(setHour(false))
            setIsPurposeEntered(false)

        }

    };



    const handleCancel = () => {
        dispath(setService(false))
        dispath(setDate(false))
        setIsPurposeEntered(false)
        if (activeTab !== "services") {
            const currentIndex = tabs.indexOf(activeTab);
            if (currentIndex > 0) {
                const previousTab = tabs[currentIndex - 1];
                setActiveTab(previousTab);
            }
        } else {
            navigate(-1);
        }
    };

    const tabs = ["services", "date", "detail", "control", "payment"];


    const [meetingInfo, setMeetingInfo] = useState({
        uuid: "string",
        doctoruuid: "string",
        reportuuidlist: [
            "string"
        ],
        serviceType: "string",
        appointmentPurpose: "",
        bookedTimeslot: "string",
        changeStatus: 0,
        statusText: "string",
        username: user,
        email: usermail,
        bookdate: "string",
        availableTimeslotList: [
            "string"
        ],
        availableDateList: [
            "string"
        ]
    })


    useEffect(() => {
        loadData()
    }, [])

    return (
        <div id="meeting-page" className='box-style'>
            <Tabs
                activeKey={activeTab}
                // onSelect={handleTabChange}
                defaultActiveKey="services"
                id="uncontrolled-tab-example"
                className="mb-3 scroll-style box-style"
            >
                <Tab eventKey="services" title="Services">
                    <MeetingServices meetingInfo={meetingInfo} setMeetingInfo={setMeetingInfo} reportTypes={reportTypes} />
                </Tab>
                <Tab eventKey="date" title="Date">
                    <MeetingDocCard id={id} username={user} meetingInfo={meetingInfo} setMeetingInfo={setMeetingInfo} />
                </Tab>
                <Tab eventKey="detail" title="Detail">
                    <MeetingDetails meetingInfo={meetingInfo} setMeetingInfo={setMeetingInfo} reportTypes={reportTypes} setReportTypes={setReportTypes} activeTab={activeTab} isPurposeEntered={isPurposeEntered} setIsPurposeEntered={setIsPurposeEntered} />
                </Tab>
                <Tab eventKey="control" title="Control">
                    <MeetingControl id={id} meetingInfo={meetingInfo} />
                </Tab>
                <Tab eventKey="payment" title="Payment" >
                    <PaymentContainer meetingInfo={meetingInfo} userLocaleInfo={userLocaleInfo} isPaymentHTMLContent={isPaymentHTMLContent} setIsPaymentHTMLContent={setIsPaymentHTMLContent} />
                </Tab>
            </Tabs>
            <div className="buttons">
                <button className="button" onClick={handleCancel} style={{ display: activeTab === "payment" && isPaymentHTMLContent ? "none" : "" }}>{activeTab === 'services' ? 'Cancel' : 'Back'}</button>
                <button
                    className="button"
                    onClick={handleNext}
                    style={{
                        display:
                            activeTab === "payment" ||
                                (activeTab === 'detail' && meetingInfo.appointmentPurpose === "") ||
                                (activeTab === 'services' && isServiceSelected === false) || (activeTab === 'date' && isDateSelected === false) ||
                                (activeTab === 'date' && isHourSelected === false) ? "none" : ""
                    }}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default MeetingPage
