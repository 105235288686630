import React from 'react'
import "./support-tel.scss"

import { FiPhoneCall } from "react-icons/fi";

const SupportTel = () => {
    return (
        <div id='support-tel'>
            <FiPhoneCall />
            <p>For detailed information, please contact our healthcare service directly.</p>
            <a className='button' href="tel:123-456-7890">444 1 707</a>
        </div>
    )
}

export default SupportTel
