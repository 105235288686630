import React, { useEffect, useState } from 'react'
import Logo from '../../main-page/common/logo/Logo'
import messageIcon from "../../../assets/user/message/comments.png"
import "./header.scss"
import { IoIosArrowBack } from "react-icons/io"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { PiRssSimpleBold } from "react-icons/pi";
import { MdBookmarks } from "react-icons/md";
const Header = () => {

    const [isLocationInMain, setIsLocationInMain] = useState(false)
    const [isLocSupports, setIsLocSupports] = useState(false)
    const [isDiscover, setIsDiscover] = useState(false)
    const [isMessages, setIsMessages] = useState(false)
    const [isMeeting, setIsMeeting] = useState(false)
    const currentLocation = useLocation()
    const navigate = useNavigate()



    const handleBack = () => {
        if (currentLocation.pathname !== "/user") {
            navigate(-1)
        }
    }

    useEffect(() => {
        setIsLocationInMain(currentLocation.pathname !== "/user");
        setIsLocSupports(currentLocation.pathname === "/user/doctor/4/payment");

        if (currentLocation.pathname.startsWith("/user/discover")) {
            setIsDiscover(true)
        } else {
            setIsDiscover(false)
        }
        if (currentLocation.pathname.startsWith("/user/messages")) {
            setIsMessages(true)
        } else {
            setIsMessages(false)
        }
        if (currentLocation.pathname.startsWith("/user/meetings-main")) {
            setIsMeeting(true)
        } else {
            setIsMeeting(false)
        }

    }, [currentLocation.pathname])


    return (
        <div id='user-header'>
            {
                !isLocationInMain ? <Logo /> : <div className='back' onClick={handleBack}>
                    <IoIosArrowBack />
                </div>
            }


            {
                !isLocSupports && !isDiscover && !isMessages && !isMeeting && <Link to="messages">
                    <img src={messageIcon} alt="message-icon" />
                </Link>
            }

            {
                isLocSupports &&
                <Link to="supports" className='support-link'>
                    <span>Support</span>
                    <PiRssSimpleBold />
                </Link>
            }


            {
                isDiscover && <h5 className='discover'>Discover</h5>
            }
            {
                isDiscover && <MdBookmarks className='bookmark' />
            }
            {
                isMessages && <h5 className='messages'>Messages</h5>
            }
            {
                isMeeting && <h5 className='messages'>Meetings</h5>
            }




        </div >
    )
}

export default Header
