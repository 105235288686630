import React, { useState } from 'react'
import "./credit-options.scss"

//icons
import { IoWalletOutline } from "react-icons/io5";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const CreditOptions = () => {

    const [price, setPrice] = useState("")

    const handleCreditOption = (e) => {
        const selectedPrice = e.target.value
        setPrice(selectedPrice)
    }

    console.log(price);

    return (
        <div id='credit-options' className='box-style'>

            <div className="balance-box">
                <IoWalletOutline />
                <div>
                    <span>Zupsie Balance</span>
                </div>
                <span>0 Credit</span>
            </div>
            <div className="buy-credit-options">
                <h5>Options Buy Credit</h5>
                <Form>
                    <div className="item box-style">
                        <div>
                            <Form.Check
                                type='radio'
                                name="credit-option"
                                value="50"
                                onChange={handleCreditOption}
                            />
                            <Form.Label>1 Credit</Form.Label>
                        </div>
                        <span><span>50 $</span> / Credit</span>
                    </div>
                    <div className="item box-style">
                        <div>
                            <Form.Check
                                type='radio'
                                name="credit-option"
                                value="75"
                                onChange={handleCreditOption}

                            />
                            <Form.Label>2 Credit</Form.Label>
                        </div>
                        <span><span>75 $</span> / Credit</span>
                    </div>
                    <div className="item box-style">
                        <div>
                            <Form.Check
                                type='radio'
                                name="credit-option"
                                value="100"
                                onChange={handleCreditOption}

                            />
                            <Form.Label>4 Credit</Form.Label>
                        </div>
                        <span><span>100 $ </span> / Credit</span>
                    </div>
                    <div className="item box-style">
                        <div>
                            <Form.Check
                                type='radio'
                                name="credit-option"
                                value="150"
                                onChange={handleCreditOption}

                            />
                            <Form.Label>16 Credit</Form.Label>
                        </div>
                        <span><span>150 $</span> / Credit</span>
                    </div>

                    <div className='bottom'>
                        <span>{price} $</span>
                        <Link to="/user/profile/plans/credit-payment" className='button credit-option-next-btn'>
                            Next
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default CreditOptions
