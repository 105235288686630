import React from 'react'

const Line = ({ black }) => {

    const white = {

        width: '100%',
        height: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.118)',
    }
    const blackColor = {

        width: '100%',
        height: '1px',
        backgroundColor: 'rgba(0, 0, 0, 0.218)',
    }

    return (
        <div id='line' style={black ? blackColor : white}>

        </div>
    )
}

export default Line
