import React, { useEffect, useState } from 'react'
import "./doc-info-tabs.scss"
import { Tab, Tabs } from 'react-bootstrap'
import DoctorAdress from '../../user/doctor-info/adress/DoctorAdress'
import About from '../../user/doctor-info/about/About'
import ArticlesTab from '../../user/doctor-info/article-tab/ArticlesTab'
import Opinions from '../../user/doctor-info/opinions/Opinions'
import { useParams } from 'react-router-dom'
import { wait } from '@testing-library/user-event/dist/utils'
import { getAllDoctors, getDoctorAbout, getDoctorAdress, getDoctorClinic, getDoctorComments, getDoctorExperiences, getDoctorGraduates, getDoctorLanguages, getDoctorTreat } from '../../../api/user-services/doctor-services'
import ServicesDoc from '../../user/doctor-info/services-doc/ServicesDoc'
import { useSelector } from 'react-redux'
const DocInfoTabs = ({ id }) => {

    const [adress, setAdress] = useState([])
    const [about, setAbout] = useState([])
    const [clinic, setClinic] = useState([])
    const [treat, setTreat] = useState([])
    const [graduates, setGraduates] = useState([])
    const [comments, setComments] = useState([])
    const [experience, setExperience] = useState([])
    const [languages, setLanguages] = useState()
    const selectedDoctor = useSelector(state => state.doctor.selectedDoc)

    //adres
    const loadData = async () => {
        try {
            const adress = await getDoctorAdress(id || selectedDoctor)
            const clinic = await getDoctorClinic(id || selectedDoctor)
            const comments = await getDoctorComments(id || selectedDoctor)
            const about = await getDoctorAbout(id || selectedDoctor)
            const treat = await getDoctorTreat(id || selectedDoctor)
            const experience = await getDoctorExperiences(id || selectedDoctor)
            const graduates = await getDoctorGraduates(id || selectedDoctor)
            const languages = await getDoctorLanguages(id || selectedDoctor)
            setAbout(about.data)
            setAdress(adress.data)
            setClinic(clinic.data)
            setComments(comments.data)
            setAbout(about.data)
            setTreat(treat.data)
            setExperience(experience.data)
            setGraduates(graduates.data)
            setLanguages(languages.data)
        } catch (error) {
            console.log("err aldin info tabtan");
        }
    }


    useEffect(() => {
        loadData()
    }, [])

    console.log(id);
    console.log(selectedDoctor);
    console.log(adress);
    console.log(clinic);
    console.log(about);
    console.log(treat);
    console.log(languages);

    return (
        <div id='doc-info-tabs'>
            <Tabs
                defaultActiveKey="about"
                id="uncontrolled-tab-example"
                className="mb-3 scroll-style"
            >
                <Tab eventKey="adress" title="Adress">
                    <DoctorAdress adress={adress} clinic={clinic} />
                </Tab>
                <Tab eventKey="services" title="Services">
                    <ServicesDoc id={id} />
                </Tab>
                <Tab eventKey="about" title="About">
                    <About about={about} treat={treat} experience={experience} graduates={graduates} languages={languages} />
                </Tab>
                {/* <Tab eventKey="articles" title="Articles" >
                    <ArticlesTab />
                </Tab> */}
                {/* <Tab eventKey="opinions" title="Opinions" >
                    <Opinions comments={comments} />
                </Tab> */}
                <Tab eventKey="videos" title="Videos" >
                    Tab content for Contact
                </Tab>
            </Tabs>
        </div>
    )
}

export default DocInfoTabs
