import React from 'react'
import CreditPayment from '../../../../components/user/profile/credit-payment/CreditPayment'

const CreditPaymentPage = () => {
    return (
        <div>
            <CreditPayment />
        </div>
    )
}

export default CreditPaymentPage
