import React from 'react'
import DeleteAccount from '../../../../../components/user/profile/link-pages-account/settings-page/delete-account/DeleteAccount'

const DeleteAccountPage = () => {
    return (
        <div id='delete-account-page'>
            <DeleteAccount />
        </div>
    )
}

export default DeleteAccountPage
