import React from 'react'
import "./logo.scss"
import { useNavigate } from "react-router-dom"
import plus from "../../../../assets/logo/plus.png"
import { useSelector } from 'react-redux'
const Logo = ({ isLogoBlack }) => {

    const navigate = useNavigate()
    const isUserLogin = useSelector(state => state.auth.isAuthenticated)

    const handleClick = () => {
        isUserLogin ? navigate("/user") : navigate("/")
    }


    return (

        <div id='logo' onClick={handleClick}>
            <span className='logo-text' style={{ color: isLogoBlack ? "black" : "" }}>Zupsie</span>
            <img src={plus} alt="" />
        </div>

    )
}

export default Logo
