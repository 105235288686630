import React from 'react'
import "./about-system.scss"

//iamges
import image1 from "../../../../assets/landing-page/illustrations/ill1.png"
import image2 from "../../../../assets/landing-page/illustrations/ill2.png"

import AboutSystemContent from '../about-system-content/AboutSystemContent'
import { useDispatch, useSelector } from 'react-redux'
import { showModalSignUp } from '../../../../store/sign-up/signupSlice'
const AboutSystem = () => {

    const dispatch = useDispatch()
    const isUserLogin = useSelector(state => state.auth.isAuthenticated)

    const handleSignIn = () => {
        dispatch(showModalSignUp())
    }


    return (
        <div id='about-system'>

            <h2 className='text-center'>How Zupsie Helps You
            </h2>
            <p className='p-lg text-center system-desc'>At Zupsie, we're committed to guiding you through every stage of your cancer journey. Our goal is to provide comprehensive support, including medical expertise and emotional assistance, ensuring that you never feel alone as you navigate your treatment plan.</p>

            <div className="about-system-content">
                <img src={require("../../../../assets/landing-page/lines/line1.png")} alt="" className="line1" />
                <AboutSystemContent
                    title1={"Create an Account"}
                    title2={"Get Evaluations"}
                    subTitle1={"Create Account"}
                    subTitle2={"Upload Reports"}
                    subTitle3={"Expert Review"}
                    subTitle4={"Personalized Feedback"}
                    text1={"Register on the Zupsie platform."}
                    text2={"Securely submit your cancer diagnosis and medical documents."}
                    text3={"Top doctors review your reports."}
                    text4={"Receive detailed comments and recommendations."}
                    image={image1}
                />
                <img src={require("../../../../assets/landing-page/lines/line2.png")} alt="" className="line2" />
                <div id="about-sys-cont-2">
                    <AboutSystemContent
                        title1={"Receive Recommendations"}
                        title2={"Consult with Specialists"}
                        subTitle1={"Comprehensive Insights"}
                        subTitle2={"Expert Advice"}
                        subTitle3={"Choose Your Doctors"}
                        subTitle4={"Discuss Your Care"}
                        text1={"Get treatment options and lifestyle suggestions for your cancer care."}
                        text2={"Access valuable information on managing your condition."}
                        text3={"Select cancer specialists for one-on-one consultations."}
                        text4={"Get answers and plan your treatment process."}

                        image={image2}
                    />
                    <img src={require("../../../../assets/landing-page/lines/line1.png")} alt="" className="line3" />
                </div>
            </div>

            <div className="about-sys-bottom">
                <h2 className='text-center'>Ongoing Support
                </h2>
                <div className='system-desc'>
                    <div className='system-desc-content'>
                        <span>Regular Check-Ins:</span>
                        <p className='p-sm'> Monthly online consultations to monitor your cancer treatment progress.</p>
                    </div>
                    <div className='system-desc-content'>
                        <span>24/7 Assistance:</span>
                        <p className='p-sm'>Emergency expert support for any urgent cancer-related issues.</p>
                    </div>
                </div>


                <button className="landing-btn-lg hover-dark" onClick={handleSignIn}>Join Now</button>



            </div>
        </div>
    )
}

export default AboutSystem
