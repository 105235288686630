import React from 'react'
import "./laboratory-documents.scss"

//icons
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const LaboratoryDocuments = () => {
    return (
        <div id='laboratory-documents' className='box-style'>
            <Link className="links" to="radiology-results">
                <div>
                    <IoDocumentTextOutline />
                    <span>Radiology Results</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="links" to="cardiology-results">
                <div>
                    <IoDocumentTextOutline />
                    <span>Cardiology Results</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="links" to="other-results">
                <div>
                    <IoDocumentTextOutline />
                    <span>Other </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="links" to="medical-reports">
                <div>
                    <IoDocumentTextOutline />
                    <span>Medical Reports</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="links" to="laboratory-reports">
                <div>
                    <IoDocumentTextOutline />
                    <span>Laboratory Results</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
        </div>
    )
}

export default LaboratoryDocuments
