export const companyInfos = {
    name: "Zupsie Technology Health Finance Inc.",
    email: "info@zupsie.com",
    phone: "0850 480 90 56",
    social: [
        {
            link: "https://instagram.com"
        },
        {
            link: "https://twitter.com"
        },
        {
            link: "https://youtube.com"
        }
    ]
}