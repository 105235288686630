import React, { useEffect, useState } from 'react';
import "./doctor-card-head.scss";
import { CiStar } from 'react-icons/ci';
import { getAllDoctors, getDoctorImage, getDoctorOpinion } from '../../../../api/user-services/doctor-services';
import { TbWashDryW } from 'react-icons/tb';
import axios from 'axios';
import { encryptedSessionStorage } from '../../../../helpers/functions/encrypt-storage';
import Loading from '../../../loading/Loading';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DoctorCardHead = ({ doctor, id }) => {

    const doccname = useParams().id

    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(false)
    const [allDoctors, setallDoctors] = useState()

    const selectedDoctorName = useSelector(state => state.doctor.selectedDoc)
    const currentDoctor = allDoctors?.find(item => item?.firstname + " " + item?.lastname === selectedDoctorName)

    const loadData = async () => {
        setLoading(true)
        try {
            let doctorName = (doctor && doctor?.firstname + " " + doctor?.lastname || selectedDoctorName || doccname || currentDoctor?.firstname + " " + currentDoctor?.lastname);


            const res = await getAllDoctors()
            setallDoctors(res.data)
            const resp = await getDoctorImage(doctorName);
            const imageUrl = resp.config.url;
            const accessToken = encryptedSessionStorage.getItem("accessToken");
            const response = await axios.get(imageUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                const data = `data:${response.headers['content-type']};base64,${await convertBlobToBase64(response.data)}`;
                setImgSrc(data);
                setLoading(false)
            } else {
                console.error('Image fetch failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        loadData();
    }, [id, doctor, imgSrc, selectedDoctorName, doccname]);


    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
        });
    };


    return (
        <div id='doctor-card-head'>
            <div className='top'>
                <div className="card-content ">
                    {
                        loading ? <Loading /> : <img src={imgSrc} />
                    }

                </div>
                <div className="doc-info">
                    <span>
                        {doctor?.title || currentDoctor?.title} {doctor?.firstname || currentDoctor?.firstname} {doctor?.lastname || currentDoctor?.lastname}
                    </span>




                    <span>
                        {doctor?.speciality || currentDoctor?.speciality}
                    </span>

                    {/* <div className="doc-opinion">
                        <div className="stars">
                            <CiStar />
                            <CiStar />
                            <CiStar />
                            <CiStar />
                            <CiStar />
                        </div>
                        <span>12 opinion</span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default DoctorCardHead;
