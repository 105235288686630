import React, { useEffect, useState } from 'react'
import "./for-you.scss"
import ForYouCard from '../../user/for-you-card/ForYouCard'
import { getDiscoverCategories } from '../../../api/user-services/discover-services'
const ForYou = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getDiscoverCategories()
            setData(resp.data)
        } catch (error) {
            console.log("discover cekilemedi");
        }
    }




    useEffect(() => {
        loadData()
    }, [])



    return (
        <div id='for-you' className='box-style'>
            <span>For You</span>
            <div>
                <div className="for-you-content">
                    {
                        data?.map((item) => (
                            <ForYouCard key={item.uuid} item={item} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ForYou
