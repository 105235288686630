import React from 'react'
import "./about.scss"

import { FaUserDoctor } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { FaHeartbeat } from "react-icons/fa";
import { IoSchool } from "react-icons/io5";
import { FaLanguage } from "react-icons/fa6";
const About = ({ about, treat, experience, graduates, languages }) => {

    console.log(graduates);
    return (
        <div id='about' className='box-style'>
            <div className="top">
                <FaUserDoctor />
                <div className="clinic-info">
                    <span>Doctor Info</span>
                    <span>{about?.[0]?.title}</span>
                    <span className='speciality'>{about?.[0]?.speciality}</span>
                    <span className='speciality my-3'><span className='fw-5'>Position :</span> {experience?.[0]?.position}</span>
                </div>
            </div>
            <div className="line"></div>
            <div className="middle">
                <div>
                    <FaUser />
                    <span>About Me</span>
                </div>
                <p>{about?.[0]?.description}</p>
            </div>
            <div className="line"></div>
            <div className="middle">
                <div>
                    <FaHeartbeat />
                    <span>Diseases Treated</span>
                </div>
                <ul>
                    {
                        treat?.[0]?.description?.split(", ").map((item, i) => <li key={i}>{item}</li>)
                    }
                </ul>
            </div>
            <div className="line"></div>
            <div className="middle">
                <div>
                    <FaUser />
                    <span>Work Experiences</span>
                </div>
                <ul>
                    {
                        experience?.[0]?.companyname?.split(", ").map((item, i) => <li key={i}>{item}</li>)
                    }
                </ul>
            </div>
            <div className="line"></div>
            <div className="middle">
                <div>
                    <IoSchool />
                    <span>Schools / Educations</span>
                </div>
                <ul>
                    {
                        graduates?.[0]?.graduatefrom?.split(",").map((item, i) => <li key={i}>{item} - {graduates?.[0]?.graduatedate?.split(",")[i]}</li>)
                    }
                </ul>
            </div>
            <div className="line"></div>
            <div className="middle">
                <div>
                    <FaLanguage />
                    <span>Languages</span>
                </div>
                <ul>
                    {
                        languages?.[0]?.language?.split(",").map((item, i) => <li key={i}>{item}</li>)
                    }
                </ul>
            </div>
        </div>
    )
}

export default About
