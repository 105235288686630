import React from 'react'
import "./health-history-main.scss"
import { Link } from 'react-router-dom';


//icons
import { GoHistory } from "react-icons/go";
import { PiBasketball } from "react-icons/pi";
import { LuStethoscope } from "react-icons/lu";
import { IoHandLeftSharp } from "react-icons/io5";
import { FaUserDoctor } from "react-icons/fa6";
import { GiTripleNeedle } from "react-icons/gi";
import { IoChevronForwardSharp } from "react-icons/io5";
const HealthHistoryMain = () => {
    return (
        <div id='health-history-main' className='box-style'>
            <Link to="family-history">
                <GoHistory />
                <span>Family History</span>
                <IoChevronForwardSharp />
            </Link>
            <Link to="habits">
                <PiBasketball />
                <span>Habits</span>
                <IoChevronForwardSharp />
            </Link>
            <Link to="diagnosis">
                <LuStethoscope />
                <span>Diagnoses</span>
                <IoChevronForwardSharp />
            </Link>
            <Link to="allergies">
                <IoHandLeftSharp />
                <span>Allergies</span>
                <IoChevronForwardSharp />
            </Link>
            <Link to="surgeries">
                <FaUserDoctor />
                <span>Surgeries</span>
                <IoChevronForwardSharp />
            </Link>
            <Link to="vaccinations">
                <GiTripleNeedle />
                <span>Vaccinations</span>
                <IoChevronForwardSharp />
            </Link>
        </div>
    )
}

export default HealthHistoryMain
