import React from 'react'
import { Form } from 'react-bootstrap'
import "./examination-history.scss"


import { CiSearch } from "react-icons/ci";
const ExaminationHistory = () => {
    return (
        <div id='examination-history' className='box-style habits-styles'>
            <Form>
                <Form.Group>
                    <Form.Control type='search' placeholder='search examination' />
                    <CiSearch />
                </Form.Group>
            </Form>
        </div>
    )
}

export default ExaminationHistory
