import React from 'react'
import "./for-you-card.scss"
import { IoNewspaperOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

//icons
import { BsCameraReelsFill } from "react-icons/bs";
import { FaBook } from "react-icons/fa6";
import { SiGooglepodcasts } from "react-icons/si";

const ForYouCard = ({ item }) => {



    return (
        <Link to={`discover/${item?.uuid}`}>
            <div id='for-you-card' className='box-style'>
                <div className="for-you-card-img">
                    <img src={item?.categoryItemImage} alt="" />
                </div>
                <div className="for-you-card-content">
                    <span>{item?.categoryName}</span>
                    <div>
                        {
                            item?.categoryTitleSet?.[0].textUrl && (
                                <>
                                    <FaBook />
                                    <span>Read - 30 minute</span>
                                </>
                            )
                        }
                        {
                            item?.categoryTitleSet?.[0].videoUrl && (
                                <>
                                    <BsCameraReelsFill />
                                    <span>Watch - 30 minute</span>
                                </>
                            )

                        }
                        {
                            item?.categoryTitleSet?.[0].podcastUrl && (
                                <>
                                    <SiGooglepodcasts />
                                    <span>Listen - 30 minute</span>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ForYouCard
