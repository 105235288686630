import React, { useEffect, useState } from 'react'
import AddVaccinations from '../../../../../../components/user/profile/link-pages/health-history/vaccinations/AddVaccinations'
import { getVaccinationItems, getVaccinations } from '../../../../../../api/user-services/healt-info-services'

const AddVaccinationsPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getVaccinationItems()
            setData(resp.data)
        } catch (error) {
            console.log("vaccination cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    console.log(data);


    return (
        <div>
            <AddVaccinations data={data} />
        </div>
    )
}

export default AddVaccinationsPage
