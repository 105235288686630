import React, { useEffect, useState } from 'react'
import NoRecord from '../../../../../no-record/NoRecord'
import { Link } from 'react-router-dom'
import "./list-vaccinations.scss"
import { getVaccinations } from '../../../../../../api/user-services/healt-info-services'
const Vaccinations = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getVaccinations()
            setData(resp.data)
        } catch (error) {
            console.log("vaccinations error");
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    console.log(data);

    return (
        <div id='vaccinations' className='box-style'>
            {
                (data && data?.length > 0) ? (<div className="vaccinations">
                    <div>
                        <span>Vaccinations</span>
                        {
                            data?.map((item) => (
                                <p>{item?.date}</p>
                            ))
                        }
                    </div>
                </div>) : (<NoRecord title={"Vaccinations"} />)
            }
            <Link className='button' to="add-vaccinations">
                Add Vaccinations
            </Link>
        </div>
    )
}

export default Vaccinations
