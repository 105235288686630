import React from 'react'
import "./about-app.scss"
const AboutApp = () => {
    return (
        <div id='about-app' className='box-style '>
            <h5 className='text-center'>About App</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, asperiores.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, accusantium? Vero dolore nemo iusto quisquam, eius rerum totam hic voluptas dolorem sunt quia consequatur molestias aliquid voluptatem reiciendis dignissimos nihil praesentium veritatis corporis mollitia! Magni voluptatum laboriosam labore a expedita tempora vitae quasi soluta? Nam at sit repellendus doloremque nihil?</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam nobis magnam hic modi velit eius laborum minima consectetur animi, quod libero sit dicta. A pariatur consectetur dolore eveniet quam perferendis explicabo sed voluptatum soluta omnis magni, ex perspiciatis nesciunt deleniti quos excepturi error porro eligendi et tempora sequi. Explicabo possimus officiis aut tempore numquam enim laboriosam accusantium, vitae deserunt reiciendis!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae adipisci dolore iste et nisi unde perspiciatis dolores veniam molestias totam!</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum commodi excepturi nam voluptatibus, ea assumenda. Alias, quasi perferendis labore dolor sunt voluptates quod, maiores deserunt praesentium numquam doloremque maxime autem perspiciatis soluta inventore corporis excepturi tempore assumenda nostrum mollitia eius.</p>
        </div>
    )
}

export default AboutApp
