import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showModalSignUp } from '../../../store/sign-up/signupSlice'

//icon
import checkFill from "../../../assets/landing-page/icons/Check_fill.svg"
import chevronRight from "../../../assets/landing-page/icons/chevronRight.svg"


const LandingPackageAdv = () => {

    const isUserLogin = useSelector(state => state.auth.isAuthenticated)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = useLocation().pathname

    const planName = "advanced-plan"

    const handleSignUp = () => {
        if (isUserLogin) {
            if (path === "/") {
                navigate(`/user/packages-detail/${planName}`)
            } else if (path === "/user") {
                navigate(`packages-detail/${planName}`)
            }
        } else {
            dispatch(showModalSignUp())
        }
    }


    return (
        <div id='landing-package-main'>
            <div className="pack-type">
                ADVANCED
            </div>
            <p className='p-sm'>Our basic package is designed to provide you with a comprehensive evaluation of your medical reports and facilitate obtaining a second opinion regarding your diagnosis. </p>

            <div className="line-sm"></div>

            <div className="price-content">
                <span className='price'>$299</span>
                <span >per Month</span>
            </div>

            <div className="list-content">
                <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <div className='list-item-content'>
                        <span>Report Evaluation</span>
                        <ul>
                            <li>Reviewed by five internationally recognized doctors.</li>
                            <li>Personalized comments and treatment recommendations from each doctor.</li>
                        </ul>
                    </div>
                </div>
                <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <div className='list-item-content'>
                        <span>Expert Opinions</span>
                        <ul>
                            <li>Comprehensive written reports and visual materials.</li>
                            <li>Detailed recommendations on diagnosis, treatment options, lifestyle suggestions, and disease management..</li>
                        </ul>
                    </div>
                </div>
                <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <div className='list-item-content'>
                        <span>Second Opinion</span>
                        <ul>
                            <li>One-on-one consultations with two chosen doctors.</li>
                            <li>Confirm diagnosis, plan treatment, and address questions.</li>
                        </ul>
                    </div>
                </div>
                <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <div className='list-item-content'>
                        <span>Continuous Support and Follow-Up:</span>
                        <ul>
                            <li>Regular consultations with a chosen doctor.</li>
                            <li>Monthly online consultations to monitor progress and adjust treatment.</li>
                        </ul>
                    </div>
                </div>
                <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <div className='list-item-content'>
                        <span>Emergency Consultation Service:</span>
                        <ul>
                            <li>24/7 expert support line for urgent health issues.</li>
                        </ul>
                    </div>
                </div>
                <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <div className='list-item-content'>
                        <span>Educational and Informational Resources:</span>
                        <ul>
                            <li>Access to updated educational materials, webinars, and interactive content.</li>
                        </ul>
                    </div>
                </div>
                {/* <div className='list-item'>
                    <img src={checkFill} alt="check" />
                    <span>Emergency Care</span>
                    <span className="coming-soon">Coming Soon</span>
                </div> */}

                <Link className='view-all'>Learn More <img src={chevronRight} alt="" /> </Link>
            </div>

            <button className="landing-btn-lg hover-dark w-100 " onClick={handleSignUp}>Select Plan</button>
        </div>
    )
}

export default LandingPackageAdv
