import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./agora-manager.scss"

import {
    LocalUser,
    RemoteUser,
    useClientEvent,
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
} from "agora-rtc-react";

//icons
import { FaMicrophoneAlt } from "react-icons/fa"; //micon
import { FaMicrophoneAltSlash } from "react-icons/fa"; //micoff
import { HiVideoCamera } from "react-icons/hi2"; //cameraon
import { HiVideoCameraSlash } from "react-icons/hi2"; //cameraoff

import Loading from "../loading/Loading";
import { useDispatch } from "react-redux";
import { setIsCamOn, setIsMicOn } from "../../store/media-controller/mediaControllerSlice";

export const AgoraManagerLoc = ({ userLiveToken, config, children, activeConnection, setActiveConnection, handleJoinClick }) => {

    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    // const [activeConnection, setActiveConnection] = useState(true);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
    const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack(cameraOn);


    useJoin({
        appid: userLiveToken?.appId,
        channel: userLiveToken?.channelName,
        token: userLiveToken?.token,
        uid: userLiveToken?.userId,
    }, activeConnection);

    usePublish([localMicrophoneTrack, localCameraTrack]);

    console.log(userLiveToken);



    const deviceLoading = isLoadingMic || isLoadingCam;
    if (deviceLoading) return <div className="loading-devices">
        <Loading />
        <span>Loading devices... Please Wait...</span>
    </div>;




    console.log(micOn);
    console.log(cameraOn);

    const handleMicClick = () => {
        setMic(a => !a)
        dispatch(setIsMicOn(a => !a))
    }
    const handleCamClick = () => {
        setCamera(a => !a)
        dispatch(setIsCamOn(a => !a))
    }

    const handleJoinBtn = () => {
        handleJoinClick(true)
        localCameraTrack?.close();
        localMicrophoneTrack?.close()
    }


    return (
        <div id="live">
            <div id="videos">
                <div id='localVideo' className={`vid`}  >
                    <LocalUser
                        audioTrack={localMicrophoneTrack}
                        videoTrack={localCameraTrack}
                        cameraOn={cameraOn}
                        micOn={micOn}
                        playAudio={micOn}
                        playVideo={cameraOn}
                    />
                </div>


                <div id="controlsToolbar">
                    <div id="mediaControls">
                        <button className="btn" onClick={handleMicClick}>
                            {
                                micOn ? <FaMicrophoneAlt /> : <FaMicrophoneAltSlash />
                            }
                        </button>
                        <button className="btn" onClick={handleCamClick}>
                            {
                                cameraOn ? <HiVideoCamera /> : <HiVideoCameraSlash />
                            }
                        </button>
                        {/* <button id="endConnection"
                            onClick={() => {
                                Swal.fire({
                                    title: "Are you sure??",
                                    text: "The conversation will be terminated.",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#2097f3",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "End The Conversation"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                            title: "",
                                            text: "The meeting has been concluded.",
                                            icon: "success"
                                        });
                                        navigate("/user")
                                        setActiveConnection(false)
                                        localCameraTrack?.close();
                                        localMicrophoneTrack?.close()
                                    }
                                });
                            }}> <AiOutlineDisconnect />
                        </button> */}
                    </div>

                </div>



            </div>
            <button className="button w-100"
                onClick={handleJoinBtn}
                style={{ marginTop: "11rem" }}
            >Join</button>
        </div>
    )

}

