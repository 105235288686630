import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import "../../modals/meetings-modal/meetings-modal.scss"

import { IoWarningOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
const MeetingsMainModalCancel = (props) => {



    return (
        <div >
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id='meetings-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <IoWarningOutline />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <span>Doctor : </span>
                        <p>Dr. John Doe</p>
                    </div>
                    <div>
                        <span>Date : </span>
                        <p>19.12.2023 11:00</p>
                    </div>
                    <div >
                        <p className='text-center'>Are you sure you want to cancel the appointment?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex flex-column'>
                    <div className='d-flex gap-3'>
                        <Button className='button w-100' onClick={props.onHide}>Close</Button>
                        <Link to="" className='button'>Accept</Link>
                    </div>
                </Modal.Footer>




            </Modal>
        </div>
    )
}

export default MeetingsMainModalCancel
