import React from 'react'
import NoRecord from '../../../../../../no-record/NoRecord'
import { Link } from 'react-router-dom'

import "../results-main.scss"

import { FaPlus } from "react-icons/fa6";

const MedicalReports = ({ data }) => {
    return (
        <div id='medical-results' className='box-style '>
            {
                (data && data?.length > 0) ? data?.map((item) => (
                    <div className="medical document-list mb-2" key={item?.id}>
                        <div>
                            <span>Date</span>
                            <p>{item?.date}</p>
                        </div>
                        <div>
                            <span>Explanation</span>
                            <p>{item?.explanation}</p>
                        </div>
                    </div>
                )) : <NoRecord title="Medical" />
            }

            <div className="add-absolute-btn pt-5">
                <Link to="add-medical-results">
                    <FaPlus />
                </Link>
            </div>
        </div>
    )
}

export default MedicalReports
