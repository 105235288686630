import React from 'react'
import "./prescriptions.scss"
import NoRecord from '../../../../../no-record/NoRecord'

//icons
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';
const Prescriptions = ({ data }) => {
    return (
        <div id='prescriptions' className='box-style'>
            {
                (data && data?.length > 0) ? (
                    <>
                        {
                            data.map((item) => (
                                <div className="prescriptions" key={item?.id} >
                                    <div>
                                        <span>Surgerie</span>
                                        <p>{item?.situation}</p>
                                    </div>
                                    <div>
                                        <span>Date</span>
                                        <p>{item?.date || "This part was not filled in."}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </>

                ) : (<NoRecord title={"Prescriptions"} />)
            }
            <Link className="add-button" to="add-prescriptions">
                <FaPlus />
            </Link>
        </div>
    )
}

export default Prescriptions
