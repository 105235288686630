// import React, { useState } from 'react';
// import "./meeting-date.scss";
// import DatesWithHours from '../../user/doctor-info/meeting-dates/dates-with-hours/DatesWithHours';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import { PiSealWarningThin } from "react-icons/pi";
// import { swalToast } from '../../../swalMessages/swalMessages';
// import { useDispatch } from 'react-redux';
// import { setDate } from '../../../store/service-date-slice/service-date-slice';

// const MeetingDate = ({ dateList, timeSlotList, meetingInfo, setMeetingInfo, }) => {

//     const [value, onChange] = useState(new Date());
//     const dispatch = useDispatch()

//     const currentDate = new Date().getDate()

//     const handleItemClick = (clickedDate) => {
//         const formattedDate = clickedDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
//         console.log(formattedDate);
//         console.log(dateList[0]);
//         console.log(formattedDate > dateList[0]);
//         if (formattedDate >= dateList[0] && formattedDate <= dateList[1]) {
//             console.log("Doğru tarih seçildi");
//             setMeetingInfo((prevMeetingInfo) => ({
//                 ...prevMeetingInfo,
//                 bookdate: formattedDate
//             }));
//             dispatch(setDate(true))

//         } else {
//             swalToast("Please choose a date within the following range", `${dateList[0]} - ${dateList[1]}`, "info");

//         }
//     };

//     const disablePastDates = ({ date }) => {
//         const today = new Date();
//         return date < today;
//     };

//     console.log(dateList);

//     return (
//         <div id='meeting-date'>
//             <div className="info">
//                 <PiSealWarningThin />
//                 <span>Please choose the time you would like to receive the service.</span>
//             </div>
//             <span className='available-date'>Please choose a date within the following range. : <span>{dateList[0]} - {dateList[1]}</span></span>
//             <div className="line"></div>
//             <Calendar
//                 onChange={onChange}
//                 value={value}
//                 onClickDay={handleItemClick}
//                 tileDisabled={disablePastDates}
//                 locale="en-US"
//             />
//             <div className='box-style d-flex flex-column gap-4'>
//                 <h6>Select Time</h6>
//                 <DatesWithHours timeSlotList={timeSlotList} meetingInfo={meetingInfo} setMeetingInfo={setMeetingInfo} />
//             </div>
//         </div>
//     );
// }

// export default MeetingDate;


import React, { useState } from 'react';
import "./meeting-date.scss";
import DatesWithHours from '../../user/doctor-info/meeting-dates/dates-with-hours/DatesWithHours';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { PiSealWarningThin } from "react-icons/pi";
import { swalToast } from '../../../swalMessages/swalMessages';
import { useDispatch } from 'react-redux';
import { setDate } from '../../../store/service-date-slice/service-date-slice';

const MeetingDate = ({ dateList, timeSlotList, meetingInfo, setMeetingInfo, }) => {
    const [value, onChange] = useState(new Date());
    const dispatch = useDispatch();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    };

    const handleItemClick = (clickedDate) => {
        const startDate = parseDate(dateList[0]);
        const endDate = parseDate(dateList[1]);

        console.log(clickedDate);

        if (clickedDate >= startDate && clickedDate <= endDate) {
            console.log("Correct date selected");
            const formattedDate = clickedDate.toLocaleDateString('en-GB').replace(/\//g, '-');
            setMeetingInfo((prevMeetingInfo) => ({
                ...prevMeetingInfo,
                bookdate: formattedDate
            }));
            dispatch(setDate(true));
        } else {
            swalToast("Please choose a date within the following range", `${dateList[0]} - ${dateList[1]}`, "info");
        }
    };

    const disablePastDates = ({ date }) => {
        const today = new Date();
        return date < today;
    };

    return (
        <div id='meeting-date'>
            <div className="info">
                <PiSealWarningThin />
                <span>Please choose the time you would like to receive the service.</span>
            </div>
            <span className='available-date'>Please choose a date within the following range: <span>{dateList[0]} - {dateList[1]}</span></span>
            <div className="line"></div>
            <Calendar
                onChange={onChange}
                value={value}
                onClickDay={handleItemClick}
                tileDisabled={disablePastDates}
                locale="en-US"
            />
            <div className='box-style d-flex flex-column gap-4'>
                <h6>Select Time</h6>
                <DatesWithHours timeSlotList={timeSlotList} meetingInfo={meetingInfo} setMeetingInfo={setMeetingInfo} />
            </div>
        </div>
    );
}

export default MeetingDate;
