import React, { useEffect, useState } from 'react'
import "./meetings.scss"
import MeetingCard from '../../user/meeting-card/MeetingCard'
import { getAppointmentByUser, getAvailbleSlot } from '../../../api/user-services/appointment-services'
import Loading from '../../loading/Loading'
const Meetings = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const username = sessionStorage.getItem("user").replace(/^"(.*)"$/, '$1')
    const today = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');
    const currentHour = new Date().getHours().toString().padStart(2, "0");

    const loadData = async () => {
        setLoading(true)
        try {
            const resp = await getAppointmentByUser(username)
            setData(resp.data)
            setLoading(false)
        } catch (error) {
            console.log("meetingleri cekerken hata olustu");
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData()

    }, [])



    const bookdate = data?.map(item => item.bookdate)

    let currentDay = today.split("-")[0];
    let currentMonth = today.split("-")[1];
    let curentYear = today.split("-")[2];

    const upcomingAppointments = data?.filter(item => (item?.bookdate.split("-")[0] > currentDay && (item?.bookdate.split("-")[1] > currentMonth || item?.bookdate.split("-")[1] === currentMonth) && (item?.bookdate.split("-")[2] === curentYear || item?.bookdate.split("-")[2] > curentYear)) || (item?.bookdate.split("-")[0] === currentDay && item?.bookdate.split("-")[1] === currentMonth && item?.bookdate.split("-")[2] === curentYear) && currentHour < item?.bookedTimeslot?.split(":")[0]);

    console.log(today);
    console.log(currentHour);


    console.log(today);
    console.log(bookdate);
    console.log(bookdate[0]);


    console.log(currentDay, currentMonth, curentYear);



    return (
        <div id='meetings'>
            <span>Meetings</span>
            <div className="meetings-content">
                {
                    loading ? (<div className='loading-app'><Loading /> <span>Your appointments are being retrieved. Please wait...</span></div>) : data?.map((item, i) => {
                        if ((item?.bookdate.split("-")[0] > currentDay && (item?.bookdate.split("-")[1] > currentMonth || item?.bookdate.split("-")[1] === currentMonth) && (item?.bookdate.split("-")[2] === curentYear || item?.bookdate.split("-")[2] > curentYear)) || (item?.bookdate.split("-")[0] === currentDay && item?.bookdate.split("-")[1] === currentMonth && item?.bookdate.split("-")[2] === curentYear) && currentHour < item?.bookedTimeslot?.split(":")[0]) {
                            return <MeetingCard key={i} item={item} />
                        }
                    })
                }

                {
                    !loading && upcomingAppointments.length === 0 && <div className='warning-appointment'>The upcoming or active appointment does not exist.</div>
                }

            </div>
        </div>
    )
}

export default Meetings
