import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute.jsx";
import CommonLayout from "../layouts/CommonLayout.jsx";
import LandingPage from "../pages/common/landing-page/LandingPage.jsx";
import UserMainPage from "../pages/auth/user-main-page/UserMainPage.jsx";
import UserLayout from "../layouts/UserLayout.jsx";
import SearchPage from "../pages/auth/search-page/SearchPage.jsx";
import DocDetailPage from "../pages/auth/doc-detail-page/DocDetailPage.jsx";
import MeetingPage from "../pages/auth/meeting-page/MeetingPage.jsx";
import PaymentPage from "../pages/auth/payment-page/PaymentPage.jsx";
import SupportPage from "../pages/auth/support-page/SupportPage.jsx";
import DiscoverPage from "../pages/auth/discover-page/DiscoverPage.jsx";
import DiscoverDetailPage from "../pages/auth/discover-page/DiscoverDetailPage.jsx";
import DiscoverContentDetailsPage from "../pages/auth/discover-page/DiscoverContentDetailsPage.jsx";
import DiscoverFullContentPage from "../pages/auth/discover-page/DiscoverFullContentPage.jsx";
import MessagePage from "../pages/auth/message-page/MessagePage.jsx";
import ChatBox from "../components/containers/message/chat-box/ChatBox.jsx";
import MeetingPageMain from "../pages/auth/meeting-page-main/MeetingPageMain.jsx";
import MeetingPageDetail from "../pages/auth/meeting-page-main/MeetingPageDetail.jsx";
import ProfilePage from "../pages/auth/profile-page/ProfilePage.jsx";
import PlansPage from "../pages/auth/profile-page/plans/PlansPage.jsx";
import CreditOptionsPage from "../pages/auth/profile-page/credit-options/CreditOptionsPage.jsx";
import CreditPaymentPage from "../pages/auth/profile-page/credit-payment/CreditPaymentPage.jsx";
import HealthProfilePage from "../pages/auth/profile-page/profile-link-pages/health-profile/HealthProfilePage.jsx"
import HealthInfoPage from "../pages/auth/profile-page/profile-link-pages/health-profile/health-info/HealthInfoPage.jsx";
import CommitmentStatusPage from "../pages/auth/profile-page/profile-link-pages/health-profile/commitment-status/CommitmentStatusPage.jsx";
import DisabilitySituations from "../pages/auth/profile-page/profile-link-pages/health-profile/disability-situation/DisabilitySituationsPage.jsx";
import DisabilitySituationsPage from "../pages/auth/profile-page/profile-link-pages/health-profile/disability-situation/DisabilitySituationsPage.jsx";
import HealthHistoryPage from "../pages/auth/profile-page/profile-link-pages/health-history/HealthHistoryPage.jsx";
import FamilyHistoryPage from "../pages/auth/profile-page/profile-link-pages/health-history/family-history/FamilyHistoryPage.jsx";
import HabitsPage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/HabitsPage.jsx";
import TabaccoPage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/TabaccoPage.jsx";
import AlcholPage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/AlcholPage.jsx";
import SubstancePage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/SubstancePage.jsx";
import ExercisePage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/ExercisePage.jsx";
import NutritionPage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/NutritionPage.jsx";
import SleepPage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/SleepPage.jsx";
import SexualActivityPage from "../pages/auth/profile-page/profile-link-pages/health-history/habits/SexualActivityPage.jsx";
import DiagnosisPage from "../pages/auth/profile-page/profile-link-pages/health-history/diagnosis/DiagnosisPage.jsx";
import AddDiagnosisPage from "../pages/auth/profile-page/profile-link-pages/health-history/diagnosis/AddDiagnosisPage.jsx";
import AllergiesPage from "../pages/auth/profile-page/profile-link-pages/health-history/allergies/AllergiesPage.jsx";
import AddAllergiesPage from "../pages/auth/profile-page/profile-link-pages/health-history/allergies/AddAllergiesPage.jsx";
import SurgeriesPage from "../pages/auth/profile-page/profile-link-pages/health-history/surgeries/SurgeriesPage.jsx";
import AddSurgeriesPage from "../pages/auth/profile-page/profile-link-pages/health-history/surgeries/AddSurgeriesPage.jsx";
import VaccinationsPage from "../pages/auth/profile-page/profile-link-pages/health-history/vaccinations/VaccinationsPage.jsx";
import AddVaccinationsPage from "../pages/auth/profile-page/profile-link-pages/health-history/vaccinations/AddVaccinationsPage.jsx";
import MedicinesPage from "../pages/auth/profile-page/profile-link-pages/medicines/MedicinesPage.jsx";
import AddMedicinesPage from "../pages/auth/profile-page/profile-link-pages/medicines/AddMedicinesPage.jsx";
import PrescriptionsPage from "../pages/auth/profile-page/profile-link-pages/prescriptions/PrescriptionsPage.jsx";
import AddPrescriptionsPage from "../pages/auth/profile-page/profile-link-pages/prescriptions/AddPrescriptionsPage.jsx";
import LaboratoryDocumentsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/LaboratoryDocumentsPage.jsx";
import RadiologyResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/RadiologyResultsPage.jsx";
import AddRadiologyResults from "../components/user/profile/link-pages/health-profile/laboratory-documents/radiology-results/add-radiology-results/AddRadiologyResults.jsx";
import AddRadiologyResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/AddRadiologyResultsPage.jsx";
import CardiologyResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/CardiologyResultsPage.jsx";
import AddCardiologyResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/AddCardiologyResultsPage.jsx";
import OtherResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/OtherResultsPage.jsx";
import AddOtherResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/AddOtherResultsPage.jsx";
import MedicalReportsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/MedicalReportsPage.jsx";
import AddMedicalReportsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/AddMedicalReportsPage.jsx";
import LaboratoryResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/LaboratoryResultsPage.jsx";
import AddLaboratoryResultsPage from "../pages/auth/profile-page/profile-link-pages/laboratory-documents/AddLaboratoryResultsPage.jsx";
import ExaminationHistoryPage from "../pages/auth/profile-page/profile-link-pages/examination-history/ExaminationHistoryPage.jsx";
import ProfileInfoPage from "../pages/auth/profile-page/profile-account-pages/profile-info/ProfileInfoPage.jsx";
import PersonalInfoPage from "../pages/auth/profile-page/profile-account-pages/profile-info/PersonalInfoPage.jsx";
import ContactInfoPage from "../pages/auth/profile-page/profile-account-pages/profile-info/ContactInfoPage.jsx";
import EmergencyInfoPage from "../pages/auth/profile-page/profile-account-pages/profile-info/EmergencyInfoPage.jsx";
import AddEmergencyPage from "../pages/auth/profile-page/profile-account-pages/profile-info/AddEmergencyPage.jsx";
import GeneralInformationsPage from "../pages/auth/profile-page/profile-account-pages/profile-info/GeneralInformationsPage.jsx";
import PaymentHistoryPage from "../pages/auth/profile-page/profile-account-pages/payment-history/PaymentHistoryPage.jsx";
import CouponAndRightsPage from "../pages/auth/profile-page/profile-account-pages/coupon-and-rights/CouponAndRightsPage.jsx";
import LegalInformationPage from "../pages/auth/profile-page/profile-account-pages/legal-informations/LegalInformationPage.jsx";
import SettingsPage from "../pages/auth/profile-page/profile-account-pages/settings-page/SettingsPage.jsx";
import ChangePasswordPage from "../pages/auth/profile-page/profile-account-pages/settings-page/ChangePasswordPage.jsx";
import NotificationSettingsPage from "../pages/auth/profile-page/profile-account-pages/settings-page/NotificationSettingsPage.jsx";
import DeleteAccountPage from "../pages/auth/profile-page/profile-account-pages/settings-page/DeleteAccountPage.jsx";
import SupportSSSPage from "../pages/auth/profile-page/profile-account-pages/support/SupportSSSPage.jsx";
import AboutAppPage from "../pages/auth/profile-page/profile-account-pages/about-app/AboutAppPage.jsx";
import Disclosure from "../components/user/profile/link-pages-account/legal-infos/legal-infos-pages/Disclosure.jsx";
import UserAgreement from "../components/user/profile/link-pages-account/legal-infos/legal-infos-pages/UserAgreement.jsx";
import SellingAgreement from "../components/user/profile/link-pages-account/legal-infos/legal-infos-pages/SellingAgreement.jsx";
import HealthServicesInfo from "../components/user/profile/link-pages-account/legal-infos/legal-infos-pages/HealthServicesInfo.jsx";
import Preliminary from "../components/user/profile/link-pages-account/legal-infos/legal-infos-pages/Preliminary.jsx";
import TransactionGuide from "../components/user/profile/link-pages-account/legal-infos/legal-infos-pages/TransactionGuide.jsx";
import MeetingStartPage from "../pages/auth/meeting-start-page/MeetingStartPage.jsx";
import PackagesPageDetailPage from "../pages/auth/packages-page/PackagesPageDetailPage.jsx";
import PackagesWhitePaper from "../pages/auth/packages-page/PackagesWhitePaper.jsx";
import NotFoundPage from "../pages/common/not-found-page/NotFoundPage.jsx";


export const router = createBrowserRouter([
    {
        path: "/",
        // element: <CommonLayout />,
        element: <NotFoundPage />,

        children: [
            {
                index: true,
                element: <LandingPage />
            }
        ]
    },
    {
        path: "/user",
        element: < ProtectedRoute element={<UserLayout />} />,
        children: [
            {
                index: true,
                element: <UserMainPage />
            },
            {
                path: "search/:id",
                element: <SearchPage />
            },
            {
                path: "doctor/:id",
                children: [
                    {
                        index: true,
                        element: <DocDetailPage />
                    },
                    {
                        path: "meetings/:id",
                        element: <MeetingPage />
                    },
                    {
                        path: "payment",
                        element: <PaymentPage />
                    }
                ]
            },
            {
                path: "supports",
                element: <SupportPage />
            },
            {
                path: "discover",
                children: [
                    {
                        index: true,
                        element: <DiscoverPage />
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                index: true,
                                element: <DiscoverDetailPage />
                            },
                            {
                                path: ":id",
                                element: <DiscoverContentDetailsPage />
                            },
                            {
                                path: "full/:id",
                                element: <DiscoverFullContentPage />
                            }
                        ]
                    }
                ]
            },
            {
                path: "messages",
                children: [
                    {
                        index: true,
                        element: <MessagePage />
                    },
                    {
                        path: ":id",
                        element: <ChatBox />
                    }
                ]
            },
            {
                path: "meetings-main",
                children: [
                    {
                        index: true,
                        element: <MeetingPageMain />
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                index: true,
                                element: <MeetingPageDetail />
                            },
                            {
                                path: "meeting-start",
                                element: <MeetingStartPage />
                            }
                        ]
                    },
                ]
            },
            {
                path: "profile",
                children: [
                    {
                        index: true,
                        element: <ProfilePage />
                    },
                    {
                        path: "plans",
                        children: [
                            {
                                index: true,
                                element: <PlansPage />
                            },
                            {
                                path: "credit-options",
                                element: <CreditOptionsPage />
                            },
                            {
                                path: "credit-payment",
                                element: <CreditPaymentPage />
                            }
                        ]
                    },
                    {
                        path: "health-profile",
                        children: [
                            {
                                index: true,
                                element: <HealthProfilePage />
                            },
                            {
                                path: "health-info",
                                element: <HealthInfoPage />
                            },
                            {
                                path: "commitment-status",
                                element: <CommitmentStatusPage />
                            },
                            {
                                path: "disability-situation",
                                element: <DisabilitySituationsPage />
                            }
                        ]
                    },
                    {
                        path: "health-history",
                        children: [
                            {
                                index: true,
                                element: <HealthHistoryPage />
                            },
                            {
                                path: "family-history",
                                element: <FamilyHistoryPage />
                            },
                            {
                                path: "habits",
                                children: [
                                    {
                                        index: true,
                                        element: <HabitsPage />
                                    },
                                    {
                                        path: "tabacco",
                                        element: <TabaccoPage />
                                    },
                                    {
                                        path: "alcohol",
                                        element: <AlcholPage />
                                    },
                                    {
                                        path: "drugs",
                                        element: <SubstancePage />
                                    },
                                    {
                                        path: "exercise",
                                        element: <ExercisePage />
                                    },
                                    {
                                        path: "nutrition",
                                        element: <NutritionPage />
                                    },
                                    {
                                        path: "sleep",
                                        element: <SleepPage />
                                    },
                                    {
                                        path: "sexual-activity",
                                        element: <SexualActivityPage />
                                    },

                                ]
                            },
                            {
                                path: "diagnosis",
                                children: [
                                    {
                                        index: true,
                                        element: <DiagnosisPage />
                                    },
                                    {
                                        path: "add-diagnosis",
                                        element: <AddDiagnosisPage />
                                    }
                                ]
                            },
                            {
                                path: "allergies",
                                children: [
                                    {
                                        index: true,
                                        element: <AllergiesPage />
                                    },
                                    {
                                        path: "add-allergies",
                                        element: <AddAllergiesPage />
                                    }
                                ]
                            },
                            {
                                path: "surgeries",
                                children: [
                                    {
                                        index: true,
                                        element: <SurgeriesPage />
                                    },
                                    {
                                        path: "add-surgeries",
                                        element: <AddSurgeriesPage />
                                    }
                                ]
                            },
                            {
                                path: "vaccinations",
                                children: [
                                    {
                                        index: true,
                                        element: <VaccinationsPage />
                                    },
                                    {
                                        path: "add-vaccinations",
                                        element: <AddVaccinationsPage />
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "medicines",
                        children: [
                            {
                                index: true,
                                element: <MedicinesPage />
                            },
                            {
                                path: "add-medicines",
                                element: <AddMedicinesPage />
                            }
                        ]
                    },
                    {
                        path: "prescriptions",
                        children: [
                            {
                                index: true,
                                element: <PrescriptionsPage />
                            },
                            {
                                path: "add-prescriptions",
                                element: <AddPrescriptionsPage />
                            }
                        ]
                    },
                    {
                        path: "laboratory-documents",
                        children: [
                            {
                                index: true,
                                element: <LaboratoryDocumentsPage />
                            },
                            {
                                path: "radiology-results",
                                children: [
                                    {
                                        index: true,
                                        element: <RadiologyResultsPage />
                                    },
                                    {
                                        path: "add-radiology-results",
                                        element: <AddRadiologyResultsPage />
                                    }
                                ]
                            },
                            {
                                path: "cardiology-results",
                                children: [
                                    {
                                        index: true,
                                        element: <CardiologyResultsPage />
                                    },
                                    {
                                        path: "add-cardiology-results",
                                        element: <AddCardiologyResultsPage />
                                    }
                                ]
                            },
                            {
                                path: "other-results",
                                children: [
                                    {
                                        index: true,
                                        element: <OtherResultsPage />
                                    },
                                    {
                                        path: "add-other-results",
                                        element: <AddOtherResultsPage />
                                    }
                                ]
                            },
                            {
                                path: "medical-reports",
                                children: [
                                    {
                                        index: true,
                                        element: <MedicalReportsPage />
                                    },
                                    {
                                        path: "add-medical-results",
                                        element: <AddMedicalReportsPage />
                                    }
                                ]
                            },
                            {
                                path: "laboratory-reports",
                                children: [
                                    {
                                        index: true,
                                        element: <LaboratoryResultsPage />
                                    },
                                    {
                                        path: "add-laboratory-results",
                                        element: <AddLaboratoryResultsPage />
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: "examination-history",
                        element: <ExaminationHistoryPage />
                    },
                    {
                        path: "profile-info",
                        children: [
                            {
                                index: true,
                                element: <ProfileInfoPage />
                            },
                            {
                                path: "personal-info",
                                element: <PersonalInfoPage />
                            },
                            {
                                path: "contact-info",
                                element: <ContactInfoPage />
                            },
                            {
                                path: "emergency-info",
                                children: [
                                    {
                                        index: true,
                                        element: <EmergencyInfoPage />
                                    },
                                    {
                                        path: "add-emergency",
                                        element: <AddEmergencyPage />
                                    }
                                ]
                            },
                            {
                                path: "general-info",
                                element: <GeneralInformationsPage />
                            }

                        ]
                    },
                    {
                        path: "payment-history",
                        element: <PaymentHistoryPage />
                    },
                    {
                        path: "coupon-rights",
                        element: <CouponAndRightsPage />
                    },
                    {
                        path: "legal-informations",
                        children: [
                            {
                                index: true,
                                element: <LegalInformationPage />
                            },
                            {
                                path: "disclosure",
                                element: <Disclosure />
                            },
                            {
                                path: "user-agreement",
                                element: <UserAgreement />
                            },
                            {
                                path: "selling-agreement",
                                element: <SellingAgreement />
                            },
                            {
                                path: "health-services-info",
                                element: <HealthServicesInfo />
                            },
                            {
                                path: "preliminary",
                                element: <Preliminary />
                            },
                            {
                                path: "transaction-guide",
                                element: <TransactionGuide />
                            }
                        ]
                    },
                    {
                        path: "settings",
                        children: [
                            {
                                index: true,
                                element: <SettingsPage />
                            },
                            {
                                path: "change-password",
                                element: <ChangePasswordPage />
                            },
                            {
                                path: "notification-settings",
                                element: <NotificationSettingsPage />
                            },
                            {
                                path: "delete-account",
                                element: <DeleteAccountPage />
                            }

                        ]
                    },
                    {
                        path: "support-sss",
                        children: [
                            {
                                index: true,
                                element: <SupportSSSPage />
                            }
                        ]
                    },
                    {
                        path: "about-app",
                        element: <AboutAppPage />
                    }

                ]
            },
            {
                path: "packages-detail/:id",
                children: [
                    {
                        index: true,
                        element: <PackagesWhitePaper />
                    },
                    {
                        path: ":id",
                        element: <PackagesPageDetailPage />
                    },

                ]
            },

        ]
    }
])


export default function AppRouter() {
    return <RouterProvider router={router} />
}