import React from 'react'
import "./emergency-list.scss"
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
const EmergencyList = ({ data }) => {
    return (
        <div id='emergency-list' className='box-style'>
            <h5>Emergency Contact List</h5>
            {
                data?.map((item) => (
                    <div className="emergency-person" key={item?.id}>
                        <div>
                            <span>Name : </span>
                            <span>{item?.name}</span>
                        </div>
                        <div>
                            <span>Lastname :</span>
                            <span>{item?.lastname}</span>
                        </div>
                        <div>
                            <span>Phone :</span>
                            <span> {item?.phone}</span>
                        </div>
                    </div>
                ))
            }
            <Link className="addBtn" to="add-emergency">
                <FaPlus />
            </Link>
        </div>
    )
}

export default EmergencyList
