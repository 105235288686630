import React, { useEffect, useState } from 'react'
import "./login-form.scss"
import { Button, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signIn } from '../../../api/user-services/user-services'
import countriesCode from "../../../countries/countriesCode.json"
import { swalToast } from '../../../swalMessages/swalMessages'
import Loading from '../../loading/Loading'
import { login } from '../../../store/auth-slice/authSlice'
import { encryptedLocalStorage, encryptedSessionStorage } from '../../../helpers/functions/encrypt-storage'
const LoginForm = ({ setIsPhoneAndPassword, setIsSignUpClicked, setIsLoginClicked, isForgotPasswordClicked, setIsForgotPasswordClicked }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userFullPhoneNumber, setUserFullPhoneNumber] = useState("")
    // const [isOTPcodeEntered, setIsOTPCodeEntered] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(null);


    const [countryCallingCode, setCountryCallingCode] = useState()

    const navigate = useNavigate()
    const dispatch = useDispatch()


    //ilk acilista ulke kodu alma islemi
    const loadData = async () => {
        try {
            const resp = await fetch("https://ipapi.co/json");
            const data = await resp.json();
            setCountryCallingCode(data.country_calling_code);
        } catch (error) {
            console.log("IP bilgileri alınırken hata oluştu:", error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);


    const [userLoginInfo, setUserLoginInfo] = useState({
        username: "",
        phonenumber: "",
        password: ""
    })

    //GENERATE OTP
    const handleSubmitOTP = async (e) => {
        e.preventDefault()

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

        }

        setValidated(true);

        setLoading(true)
        const fullPhoneNumber = `${countryCallingCode || countryCode}${phoneNumber}`
        setUserFullPhoneNumber(fullPhoneNumber)



        if (userLoginInfo.password.length !== 6 || isNaN(userLoginInfo.password) || !/^\d+$/.test(userLoginInfo.password)) {
            swalToast("Check Again!", "Password must be 6 digits and contain only numbers. It should not include special symbols.", "info")
            setLoading(false)
            return;
        }

        try {

            const resp = await signIn({ ...userLoginInfo, phonenumber: fullPhoneNumber })

            encryptedSessionStorage.setItem("accessToken", resp.data.accessToken)
            sessionStorage.setItem("refreshToken", resp.data.refreshToken)
            dispatch(login(resp.data.username))
            setLoading(false)
            swalToast("Login is Success!", "Enjoy your time!", "success")
            setIsPhoneAndPassword(true)
            navigate("/user")
        } catch (error) {
            swalToast("Error!", "Something went wrong", "error")
            setLoading(false)
            setUserLoginInfo({
                username: "",
                phonenumber: "",
                password: ""
            });
        }
        finally {
            setLoading(false)
        }
    }

    //GENERATE OTP


    const getUserInfo = (e) => {
        const { name, value } = e.target
        setUserLoginInfo({ ...userLoginInfo, [name]: value })
    }


    const handleSignUpClick = () => {
        setIsLoginClicked(false)
        setIsSignUpClicked(true)
    }


    const handlePasswordClick = () => {
        setIsForgotPasswordClicked(true)
    }


    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);

        const selectedCountryInfo = countriesCode.find(country => country.dial_code === e.target.value);
        setSelectedCountry(selectedCountryInfo);

    };

    const handlePhoneNumber = (e) => {
        const number = e.target.value
        if (number.startsWith("0")) {
            swalToast("Invalid Input", "Phone number cannot start with 0", "warning");
            return;
        }
        setPhoneNumber(number)
    }

    const defaultCountryFlag = countriesCode.filter((country) => country.dial_code === countryCallingCode ? country.flag : null)[0]?.flag


    return (
        <div id='login-form' className={isForgotPasswordClicked ? "d-none" : "d-block"}>

            <Form noValidate validated={validated} onSubmit={handleSubmitOTP}>
                <div>
                    <Form.Group>
                        <Form.Label>Mobile Phone</Form.Label>
                        <br />
                        <div className='d-flex align-items-center gap-2 mb-3  login-phone-info '>
                            <Form.Select
                                className='w-50'
                                name='countryCode'
                                onChange={handleCountryCodeChange}
                                value={countryCode}
                                required
                            >
                                <option value={countryCallingCode}>{countryCallingCode} {defaultCountryFlag}</option>
                                {
                                    countriesCode.map((countryDialCode, i) => (
                                        <option key={i} value={countryDialCode.dial_code}>{countryDialCode.dial_code} {countryDialCode.flag} </option>
                                    ))
                                }
                            </Form.Select>
                            <Form.Control
                                className='mb-0'
                                type='text'
                                placeholder='Phone Number*'
                                name='phoneNumber'
                                value={phoneNumber}
                                onChange={handlePhoneNumber}
                                required
                            />
                            <div className='country-flag'>{selectedCountry ? selectedCountry?.flag : defaultCountryFlag} </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={userLoginInfo.password}
                            onChange={(e) => getUserInfo(e)}
                            placeholder="Password"
                            required
                        />
                    </Form.Group>
                    <Link className='d-flex justify-content-end mt-4 ' onClick={handlePasswordClick}>Forgot Password</Link>
                    <Button type='submit' className='button'>
                        {
                            loading ? <div className='d-flex align-items-center gap-3'><Loading /> <span>Please wait while logging in.</span></div> : "Log In"
                        }
                    </Button>
                    <div className='acc-control'>
                        <span>Don't have an account?</span>
                        <Link onClick={handleSignUpClick}>Sign Up</Link>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default LoginForm
