import React from 'react'
import { IoChevronForwardSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import { CiLock } from "react-icons/ci";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineUserDelete } from "react-icons/ai";

const SettingsLinks = () => {
    return (
        <div id='settings-links' className='box-style'>
            <Link className="single-list-btn" to="change-password">
                <div>
                    <CiLock />
                    <span>Change Password
                    </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="notification-settings">
                <div>
                    <IoMdNotificationsOutline />
                    <span>Notification Settings
                    </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="delete-account">
                <div>
                    <AiOutlineUserDelete />
                    <span>Delete Account
                    </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
        </div>
    )
}

export default SettingsLinks
