import React from 'react'
import getYouTubeID from 'get-youtube-id';
import YouTube from 'react-youtube';
import "./youtube-video-box.scss"
const YoutubeVideo = ({ videoUrl }) => {

    const id = getYouTubeID(videoUrl)

    const opts = {
        playerVars: {

            autoplay: 1,
        },
    };


    return (
        <div className='youtube-video-box'>
            <YouTube videoId={id} opts={opts} />
        </div>
    )
}

export default YoutubeVideo
