import React, { useEffect, useState } from 'react'
import HealthInfo from '../../../../../../components/user/profile/link-pages/health-profile/health-info/HealthInfo'
import { getHealthInfo } from '../../../../../../api/user-services/healt-info-services'

const HealthInfoPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getHealthInfo()
            setData(resp.data)
        } catch (error) {
            console.log("hata aldiun healt info getirilirken");
        }
    }


    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div>
            <HealthInfo data={data} />
        </div>
    )
}

export default HealthInfoPage
