import { EncryptStorage } from "encrypt-storage";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const encryptedLocalStorage = new EncryptStorage(encryptionKey, {
    storageType: 'localStorage'
});

export const encryptedSessionStorage = new EncryptStorage(encryptionKey, {
    storageType: 'sessionStorage'
});

