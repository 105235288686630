import React, { useEffect, useState } from 'react'
import RadiologyResults from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/radiology-results/RadiologyResults'
import { getRadiologyResults } from '../../../../../api/user-services/healt-info-services'

const RadiologyResultsPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getRadiologyResults()
            setData(resp.data)
        } catch (error) {
            console.log("radiology cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div>
            <RadiologyResults data={data} />
        </div>
    )
}

export default RadiologyResultsPage
