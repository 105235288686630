import React, { useEffect, useState } from 'react'
import "./meeting-doc-card.scss"
import DoctorCardHead from '../../user/doctor-info/head-info/DoctorCardHead'

import { BsFillCameraVideoFill } from "react-icons/bs";
import { MdOutlineWatchLater } from "react-icons/md";
import Line from '../../user/line/Line';
import Spacer from '../../spacer/Spacer';
import MeetingDate from '../meeting-date/MeetingDate';
import { useSelector } from 'react-redux';
import { getAvailbleSlot } from '../../../api/user-services/appointment-services';
const MeetingDocCard = ({ id, username, meetingInfo, setMeetingInfo }) => {

    const [dateList, setDateList] = useState([])
    const [timeSlotList, setTimeSlotList] = useState([])
    const doctorList = useSelector(state => state.doctor.doctor)
    const selectedDocInfo = doctorList?.find(item => item?.firstname + " " + item?.lastname === id)
    const selectedDoctor = useSelector(state => state.doctor.selectedDoc)

    const selectedDocName = selectedDocInfo?.firstname + " " + selectedDocInfo?.lastname

    console.log(selectedDoctor);

    const userName = sessionStorage.getItem("user")
    const cleanedUserName = userName.replace(/"/g, "");
    const loadData = async () => {
        try {
            const resp = await getAvailbleSlot(selectedDoctor || selectedDocName || id, cleanedUserName);
            setDateList(resp.data.availableDateList);
            setTimeSlotList(resp.data.availableTimeslotList);
        } catch (error) {
            console.log("err aldin info avaibleslottan");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(dateList);
    console.log(timeSlotList);
    console.log(id);


    return (
        <div id='meeting-doc-card' className='box-style'>
            <DoctorCardHead />
            <div className="conversation-info">
                <span><BsFillCameraVideoFill />Online Conversation</span>
                <span><MdOutlineWatchLater />30 Minute</span>
            </div>
            <Line black={true} />
            <MeetingDate dateList={dateList} timeSlotList={timeSlotList} meetingInfo={meetingInfo} setMeetingInfo={setMeetingInfo} />
        </div>
    )
}

export default MeetingDocCard
