import React from 'react'
import "./search-container.scss"
import SearchCategory from '../../user/search-input/SearchCategory'
import MainDoctorCard from '../../user/main-doctor-card/MainDoctorCard'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setSelectedDoctor } from '../../../store/doctor-slice/doctor-slice'
const SearchContainer = ({ doctorData, noResult }) => {

    const dispatch = useDispatch()

    const selectDoctor = (docname) => {
        dispatch(setSelectedDoctor(docname))
    }


    return (
        <div id='search-container'>

            <span className='text-white fs-4'>Your Search Results...</span>

            <div className="doctor-cards">
                {
                    !noResult && doctorData && doctorData.length > 0
                        ? doctorData.map((doctor) => (
                            <Link
                                to={`/user/doctor/${doctor.firstname} ${doctor.lastname}`}
                                onClick={() => selectDoctor(`${doctor.firstname} ${doctor.lastname}`)}
                                key={doctor.id}
                            >
                                <MainDoctorCard doctor={doctor} />
                            </Link>
                        ))
                        : <span className='text-white  text-center' style={{ fontSize: ".8rem", marginTop: "5rem" }}>No Doctors Found</span>
                }
            </div>
        </div>
    )
}

export default SearchContainer
