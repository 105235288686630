import { useEffect } from "react";
import AppRouter from "./router/router";
import { signOut } from "./api/user-services/user-services";
import { getDiscoverCategories } from "./api/user-services/discover-services";


function App() {





  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
