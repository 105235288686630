import React, { useEffect, useState } from 'react'
import SearchContainer from '../../../components/containers/search/SearchContainer'
import "./serarch-page.scss"
import { useParams } from 'react-router-dom'
import { getAllDoctorsBySpecialities } from '../../../api/user-services/doctor-services'
import { useDispatch } from 'react-redux'
import { setDoctor } from '../../../store/doctor-slice/doctor-slice'
import Loading from '../../../components/loading/Loading'
const SearchPage = () => {

    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [doctorData, setDoctorData] = useState([])
    const [noResult, setNoResult] = useState(false)
    const dispatch = useDispatch()

    console.log(id);


    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            if (id) {
                try {
                    const resp = await getAllDoctorsBySpecialities(id);
                    setDoctorData(resp.data);
                    dispatch(setDoctor(resp.data));
                    setLoading(false);
                } catch (error) {
                    console.log("error");
                    setLoading(false);
                    setNoResult(true)
                }
            } else {
                setNoResult(true)
            }

        };

        loadData();
    }, [id, dispatch]);

    console.log(doctorData);


    return (
        <div id='search-page'>
            {
                loading ? <Loading /> : <SearchContainer doctorData={doctorData} noResult={noResult} />
            }

        </div>
    )
}

export default SearchPage
