import React, { useEffect, useState } from 'react'
import AddMedicalReports from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/medical-reports/add-medical-reports/AddMedicalReports'
import { getMedicalResults } from '../../../../../api/user-services/healt-info-services'

const AddMedicalReportsPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getMedicalResults()
            setData(resp.data)
        } catch (error) {
            console.log("medicalreports cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div>
            <AddMedicalReports data={data} />
        </div>
    )
}

export default AddMedicalReportsPage
