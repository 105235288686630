import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { swalToast } from '../../../../../../swalMessages/swalMessages';
import { postVaccinations } from '../../../../../../api/user-services/healt-info-services';
import { useNavigate } from 'react-router-dom';

const AddVaccinations = ({ data }) => {
    const navigate = useNavigate();
    const username = JSON.parse(sessionStorage.getItem("user"))
    const [info, setInfo] = useState({
        uuid: "",
        name: [],
        date: "",
        username: username
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "name") {
            const updatedVaccinations = checked ? [...info.name, value] : info.name.filter(item => item !== value);
            setInfo({ ...info, [name]: updatedVaccinations });
        } else {
            setInfo({ ...info, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await postVaccinations(info);
            swalToast("Saved", "success");
            navigate(-1);
        } catch (error) {
            swalToast("Oops..", "An unexpected error occurred", "warning");
        }
    };

    console.log(info);

    return (
        <div id='add-vaccinations' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group >
                    {data?.[0]?.vaccinationitems?.map((option, index) => (
                        <Form.Check
                            className='form-group-item'
                            key={index}
                            type='switch'
                            label={option}
                            id={`vaccination-item-${index}`}
                            name='name'
                            value={option}
                            onChange={handleChange}
                            checked={info.name.includes(option)}
                        />
                    ))}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type='date'
                        name='date'
                        onChange={handleChange}
                        value={info?.date}
                    />
                </Form.Group>
                <Button className='button' type='submit'>Save</Button>
            </Form>
        </div>
    );
};

export default AddVaccinations;
