import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export const addPaymentInfo = (firstname, lastname, payload) => {
    return axios.post(`${API_URL}iyzicopay/create/paymentbysubmerchant?contactname=${firstname}&contactsurname=${lastname}`, payload);
}
export const webhook = (payload) => {
    return axios.post(`https://apizupsie.site:9090/webhook/subscribe`, payload);
}
export const getCallbackId = () => {
    return axios.get(`${API_URL}callback/callbackidbyuser`);
}

//permissions


export const permissions = (payload) => {
    return axios.post(`${API_URL}consent/agreements`, payload);
}