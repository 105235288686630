import React from 'react'
import HealthHistoryMain from '../../../../../components/user/profile/link-pages/health-history/healt-history-main/HealthHistoryMain'

const HealthHistoryPage = () => {
    return (
        <div>
            <HealthHistoryMain />
        </div>
    )
}

export default HealthHistoryPage
