import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    content: []
}

export const contentItems = createSlice({
    name: "content",
    initialState,
    reducers: {
        setContent: (state, action) => {
            state.content = action.payload
        }
    }
})

export const { setContent } = contentItems.actions;
export default contentItems.reducer