import React from 'react'
import { CiUser } from 'react-icons/ci'
import { IoChevronForwardSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'

const LegalInfoLinks = () => {
    return (
        <div id='legal-info-links' className='box-style'>
            <Link className="single-list-btn" to="disclosure">
                <div>
                    <span>Information Disclosure Statement
                    </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="user-agreement">
                <div>
                    <span>User Agreement
                    </span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="selling-agreement">
                <div>
                    <span>Distance Selling Agreement</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="health-services-info">
                <div>
                    <span>Remote Health Services Information Form</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="preliminary">
                <div>
                    <span>Preliminary Information Form</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
            <div className="line"></div>
            <Link className="single-list-btn" to="transaction-guide">
                <div>
                    <span>Transaction Guide</span>
                </div>
                <IoChevronForwardSharp />
            </Link>
        </div>
    )
}

export default LegalInfoLinks
