import React from 'react'
import "./start-managing.scss"
import { Col, Row } from 'react-bootstrap'
import PackagesCampaignCard from '../packages-campaign/PackagesCampaignCard'
import PackageCard from '../../../packages-page/package-card/PackageCard'
import PackageCard2 from '../../../packages-page/package-card/PackageCard2'
import LandingPackageMain from '../../../landing-packages/landing-package-main/LandingPackageMain'
import LandingPackageAdv from '../../../landing-packages/landing-package-main/LandingPackageAdv'
const StartManaging = () => {



    return (
        <div id='start-managing' >
            <div className="top">
                <h3>Join Zupsie Health Platform now</h3>
                <p className='p-sm'>Take control of your cancer journey today! Get a second opinion from doctors worldwide, connect with others who understand, and strengthen your treatment plans. </p>
            </div>

            <div className="packages-cards container">
                <Row className='d-flex justify-content-center'>
                    <Col sm={12} lg={4} className='mb-5'>
                        {/* <PackageCard /> */}
                        <LandingPackageMain />
                    </Col>
                    <Col sm={12} lg={4} className='mb-5'>
                        {/* <PackageCard2 /> */}
                        <LandingPackageAdv />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default StartManaging
