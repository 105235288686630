import React from 'react'
import ExaminationHistory from '../../../../../components/user/profile/link-pages/health-profile/examination-history/ExaminationHistory'

const ExaminationHistoryPage = () => {
    return (
        <div>
            <ExaminationHistory />
        </div>
    )
}

export default ExaminationHistoryPage
