import React from 'react'
import NoRecord from '../../../../no-record/NoRecord'

const CouponAndRights = () => {
    return (
        <div id='coupon-and-rights' className='box-style'>
            <NoRecord title={"Coupon And Rights"} />
        </div>
    )
}

export default CouponAndRights
