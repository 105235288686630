import React from 'react'

const Preliminary = () => {
    return (
        <div id='preliminary' className='box-style'>

        </div>
    )
}

export default Preliminary
