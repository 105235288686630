import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Title from '../../../components/user/title/Title';
import SearchCategory from '../../../components/user/search-input/SearchCategory';
import Spacer from '../../../components/spacer/Spacer';
import Categories from '../../../components/containers/categories/Categories';
import Doctors from '../../../components/containers/doctors/Doctors';
import Meetings from '../../../components/containers/meeting/Meetings';
import CollectOpinion from '../../../components/containers/collect-opinion/CollectOpinion';
import SpaceWithLine from '../../../components/user/space-with-line/SpaceWithLine';
import ForYou from '../../../components/containers/for-you/ForYou';
import "./user-main-page.scss"
import { getPopularCategories, getPopularDoctors } from '../../../api/user-services/doctor-services';
import { setCategories } from '../../../store/categories/categories-slice';
import PackageMainPage from '../../../components/packages-page/package-main-page/PackageMainPage';
import CommonHealthConcerns from '../../../components/containers/common-healt-concerns/CommonHealthConcerns';


const UserMainPage = () => {

    const [data, setData] = useState([])
    const [popularDocs, setPopularDocs] = useState([])
    const dispatch = useDispatch()

    const loadData = async () => {
        try {
            const resp = await getPopularCategories()
            const popularDoctors = await getPopularDoctors()
            setPopularDocs(popularDoctors.data);
            setData(resp.data)
            dispatch(setCategories(resp.data))
        } catch (error) {
            console.log(error);
        }
    }




    useEffect(() => {
        window.scrollTo(0, 0)
        loadData()
    }, [])




    return (
        <div id='user-main-page'>
            <Title
                title={"Find a doctor, schedule an appointment, have an online"}
                titleColourful={"consultation!"}
            />
            <Spacer />
            <SearchCategory data={data} />
            <SpaceWithLine />
            <Categories data={data} />
            <SpaceWithLine />
            <CommonHealthConcerns />
            <SpaceWithLine />
            <Doctors popularDocs={popularDocs} />
            <SpaceWithLine />
            <Meetings />
            <SpaceWithLine />
            {/* <CollectOpinion /> */}
            <PackageMainPage />
            <SpaceWithLine />
            <ForYou />
            <SpaceWithLine />
        </div>
    )
}

export default UserMainPage
