
import React, { useEffect } from 'react'
import DiscoverFullContent from '../../../components/containers/discover/dicover-full-content/DiscoverFullContent'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const DiscoverFullContentPage = () => {

    const id = useParams().id
    const currentContent = useSelector(state => state.content.content)



    const content = currentContent?.find((item) => item.uuid === id)

    console.log(content);


    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <DiscoverFullContent content={content} />
        </div>
    )
}

export default DiscoverFullContentPage
