import React, { useEffect, useState } from 'react'
import "./add-medical-reports.scss"

import { Button, Form } from 'react-bootstrap'


import { PiUploadSimple } from "react-icons/pi";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { swalToast } from '../../../../../../../../swalMessages/swalMessages';
import Loading from '../../../../../../../loading/Loading';
import { useNavigate } from 'react-router-dom';
import { addMedicalResultsImage, postMedicalResults, updateMedicalResults } from '../../../../../../../../api/user-services/healt-info-services';

import { MdDeleteOutline, MdFileDownload } from "react-icons/md";

import { TiTick } from "react-icons/ti";

import filePlaceholder from "../../../../../../../../assets/fileplaceholder.png";
const AddMedicalReports = ({ data }) => {

    const username = JSON.parse(sessionStorage.getItem("user"));
    const navigate = useNavigate()
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [imageIds, setImageIds] = useState([])
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(

        {
            id: "",
            date: "",
            explanation: "",
            fileuuid: [],
            username: username
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;

        setInfo({ ...info, [name]: value });
    }

    const handleUpload = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*,.pdf,.txt';
        input.multiple = true;
        input.onchange = async (e) => {
            const selectedFiles = e.target.files;
            if (selectedFiles.length > 0) {
                setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
                setLoading(true);

                for (const file of selectedFiles) {
                    const formData = new FormData();
                    formData.append("file", file);

                    try {
                        const respImage = await addMedicalResultsImage(file.name, username, formData);
                        const imageId = respImage.data.message.split(":")[1].trim();
                        setInfo(prevInfo => ({
                            ...prevInfo,
                            fileuuid: [...prevInfo.fileuuid, imageId]
                        }));
                        setLoading(false);
                    } catch (error) {
                        console.log("file eklenemedi");
                        setLoading(false);
                    }
                }
            }
        };
        input.click();
    };

    const handleDelete = (index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const handleDownload = (file) => {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
    };

    useEffect(() => {
        setInfo({ ...info, fileuuid: imageIds });
    }, [imageIds]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (data?.username === username) {
                await updateMedicalResults(info);

                swalToast("Success", "Updated");
                navigate(-1);

            } else {
                await postMedicalResults(info);
                swalToast("Success", "Saved");
                navigate(-1);
                console.log("post basarili");
                console.log(info);

            }
        } catch (error) {
            swalToast("Error", "Something went wrong", "error");
        } finally {
            setLoading(false);
        }
    };






    console.log(images);
    console.log(imageIds);
    console.log(info);


    return (
        <div id='add-medical-reports' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type='date'
                        name='date'
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Explanation</Form.Label>
                    <Form.Control
                        type='text'
                        name='explanation'
                        onChange={handleChange}
                    />
                </Form.Group>
                <div className="top-btn">
                    <div onClick={handleUpload}>
                        <PiUploadSimple />
                        {
                            loading ? <span className='image-uploading'>Image Uploading...</span> : <span>Upload</span>
                        }
                    </div>
                </div>
                <Button className='button' type='submit' disabled={loading}>
                    Save
                </Button>
            </Form>
            <div className="uploaded-images">
                {files.length > 0 && (
                    <div className='reports-wrapper'>
                        {files.map((file, index) => (
                            <div key={index}>
                                {file.type.startsWith("image/") ? (
                                    <img src={URL.createObjectURL(file)} alt={`Uploaded ${index}`} />
                                ) : (
                                    <img src={filePlaceholder} alt={`Uploaded ${index}`} />
                                )}
                                <span>{file.name}</span>
                                <div className="icons">
                                    {
                                        loading ? <Loading /> : <TiTick />
                                    }
                                    <MdFileDownload onClick={() => handleDownload(file)} />
                                    <MdDeleteOutline onClick={() => handleDelete(index)} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddMedicalReports
