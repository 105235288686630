import React, { useEffect, useState } from 'react'
import DiscoverDetails from '../../../components/containers/discover/discover-detail/DiscoverDetails'
import { getDiscoverCategories } from '../../../api/user-services/discover-services'
import { useParams } from 'react-router-dom'
import { current } from '@reduxjs/toolkit'

const DiscoverDetailPage = () => {


    const categoryId = useParams().id
    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getDiscoverCategories()
            setData(resp.data)
        } catch (error) {
            console.log("discover cekilemedi");
        }
    }

    const currentCategory = data?.filter((item) => item.uuid === categoryId)




    useEffect(() => {
        window.scrollTo(0, 0)
        loadData()
    }, [])


    return (
        <div id='discover-detail-page'>
            <DiscoverDetails currentCategory={currentCategory} />
        </div>
    )
}

export default DiscoverDetailPage
