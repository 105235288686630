import React from 'react'
import "./package-card.scss"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showModalSignUp } from '../../../store/sign-up/signupSlice'
const PackageCard2 = () => {

    const isUserLogin = useSelector(state => state.auth.isAuthenticated)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const path = useLocation().pathname

    const planName = "advanced-plan"

    const handleSignUp = () => {
        if (isUserLogin) {
            if (path === "/") {
                navigate(`/user/packages-detail/${planName}`)
            } else if (path === "/user") {
                navigate(`packages-detail/${planName}`)
            }
        } else {
            dispatch(showModalSignUp())
        }
    }

    return (
        <div id='package-card' className='box-style'>
            <div className="pack-image">
                <img src={require("../../../assets/payment/p2.jpeg")} alt="" />
            </div>
            <div className="pack-info">
                <span>Advanced Plan</span>
                <div className='pack-info-text'>
                    <h4>Advanced Package Description</h4>
                    <h5>Advanced Package Contents:</h5>
                    <p>Our advanced package offers comprehensive services to best support the diagnosis and treatment processes of oncology patients. This package, which is more extensive than the basic package, provides our patients with more expert evaluations, personalized treatment plans, and continuous support. The contents of the package are as follows:</p>

                    <h6>Comprehensive Report Evaluation:</h6>
                    <p>Your diagnosis reports are thoroughly examined by five expert doctors and their teams, who are recognized both in Turkey and internationally.</p>
                    <p>Each doctor carefully evaluates your reports and provides personalized comments and treatment recommendations.</p>

                    <h6>Expert Opinions and Recommendations:</h6>
                    <p>Comprehensive evaluations and diagnostic comments from five different doctors are presented to you through written reports and visual materials.</p>
                    <p>Experts provide detailed recommendations not only related to the diagnosis but also concerning treatment options, lifestyle suggestions, and disease management.</p>

                    <h6>Second Opinion and Treatment Planning:</h6>
                    <p>You have the opportunity to have one-on-one consultations with two doctors of your choice from the five evaluations.</p>
                    <p>During these consultations, you receive comprehensive information to confirm the accuracy of your diagnosis, plan your treatment process, and address all your questions.</p>

                    <h6>Continuous Support and Follow-Up:</h6>
                    <p>One of the doctors you choose will provide regular consultation and support throughout your treatment process.</p>
                    <p>Your treatment progress is monitored with monthly online consultations, and adjustments to the treatment plan are made as necessary.</p>

                    <h6>Emergency Consultation Service:</h6>
                    <p>An expert support line available 24/7 is provided for any sudden health issues or urgent consultation needs.</p>

                    <h6>Educational and Informational Resources:</h6>
                    <p>You gain access to continuously updated educational materials about your disease, treatment methods, and lifestyle recommendations.</p>
                    <p>You can keep your knowledge up-to-date with webinars, educational videos, and interactive content.</p>

                    <h5>Benefits and Value of the Package:</h5>

                    <h6>1. Multi-Faceted Expert Evaluation:</h6>
                    <p><span>Increases Confidence:</span>Evaluations from different experts highly secure the accuracy of your diagnosis.</p>
                    <p><span>Treatment Strategy: </span> Recommendations from multiple experts help you determine the most suitable treatment strategy.</p>

                    <h6>2. Personalized Treatment Plan:</h6>
                    <p><span>Customized Care: </span> Each patient’s condition is unique; therefore, personalized treatment plans ensure the best outcomes.</p>
                    <p><span>Quality of Life: </span> Lifestyle recommendations and advice on disease management improve your quality of life.</p>

                    <h6>3. Continuous Support and Follow-Up:</h6>
                    <p><span>Long-Term Support: </span> Ongoing support throughout your treatment process ensures quick intervention in case of any setbacks.
                    </p>
                    <p><span>Progress Monitoring:</span> Regular check-ups and follow-up consultations enhance the effectiveness of the treatment process and detect potential issues early..</p>

                    <h6>4. Emergency Consultation Service:</h6>
                    <p><span>Immediate Response:  </span> Expert support for sudden health issues provides quick and effective solutions.</p>
                    <p><span>Peace of Mind: </span> The 24/7 accessible support line offers reassurance, knowing that an expert is available in emergencies.</p>

                    <h6>5. Education and Information:</h6>
                    <p><span>Informed Decisions: </span>  Educational materials and resources enable you to make informed decisions about your disease and treatment options.</p>
                    <p><span>Up-to-Date Information: </span> With continuously updated information and webinars, you stay informed about the latest treatment methods and research.</p>
                </div>
            </div>
            <div className="pack-buy">
                <div className="pack-buy-top">
                    <span>Very Good 8.3</span>
                    <span>(132 comment)</span>
                </div>
                <div className="pack-buy-bottom">
                    <span>299$</span>
                    <button className="button" onClick={handleSignUp}>Buy</button>
                </div>
            </div>
        </div>
    )
}

export default PackageCard2
