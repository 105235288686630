import React, { useState } from 'react'
import "./notification-settings.scss"




const NotificatonSettings = () => {

    const [isOpenSms, setIsOpenSms] = useState(false)
    const [isOpenEmail, setIsOpenEmail] = useState(false)

    const handleActiveSms = () => {
        setIsOpenSms(true)
    }
    const handleDeactiveSms = () => {
        setIsOpenSms(false)
    }
    const handleActiveMail = () => {
        setIsOpenEmail(true)
    }
    const handleDeactiveMail = () => {
        setIsOpenEmail(false)
    }


    return (
        <div id='notification-settings' className='box-style'>
            <div className="top notification-settings-box">
                <span>SMS Notification</span>
                <div>
                    <span className={`${isOpenSms ? "active" : ""}`} onClick={handleActiveSms}>Open</span>
                    <span className={`${!isOpenSms ? "active" : ""}`} onClick={handleDeactiveSms}>Close</span>
                </div>
            </div>
            <div className="line"></div>
            <div className="bottom notification-settings-box">
                <span>E-mail Notification</span>
                <div>
                    <span className={`${isOpenEmail ? "active" : ""}`} onClick={handleActiveMail}>Open</span>
                    <span className={`${!isOpenEmail ? "active" : ""}`} onClick={handleDeactiveMail}>Close</span>
                </div>
            </div>
        </div>
    )
}

export default NotificatonSettings
