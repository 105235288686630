import React from 'react'
import notFoundPageImage from "../../../assets/pagenotfound.jpg"
import "./NotFound-page.scss"
const NotFoundPage = () => {
    return (
        <div id='notFoundPage'>
            <img src={notFoundPageImage} className='img' />
        </div>
    )
}

export default NotFoundPage
