import { createSlice } from "@reduxjs/toolkit";
import { encryptedSessionStorage } from "../../helpers/functions/encrypt-storage";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: encryptedSessionStorage.getItem("accessToken") ? true : false,
        user: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : []
    },
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.user = sessionStorage.setItem("user", JSON.stringify(action.payload))
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = {}
        }

    }

})

export const { login, logout } = authSlice.actions;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;


export default authSlice.reducer;