
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    service: sessionStorage.getItem("service") ? JSON.parse(sessionStorage.getItem("service")) : false,
    date: sessionStorage.getItem("date") ? JSON.parse(sessionStorage.getItem("date")) : false,
    hour: sessionStorage.getItem("hour") ? JSON.parse(sessionStorage.getItem("hour")) : false
}

export const serviceAndDate = createSlice({
    name: "serviceAndDate",
    initialState,
    reducers: {
        setService: (state, action) => {
            state.service = action.payload;
            sessionStorage.setItem("service", JSON.stringify(action.payload));
        },
        setDate: (state, action) => {
            state.date = action.payload;
            sessionStorage.setItem("date", JSON.stringify(action.payload));
        },
        setHour: (state, action) => {
            state.hour = action.payload;
            sessionStorage.setItem("hour", JSON.stringify(action.payload));
        }
    }
})

export const { setService, setDate, setHour } = serviceAndDate.actions;
export default serviceAndDate.reducer;