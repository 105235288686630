import React from 'react'
import ChangePassword from '../../../../../components/user/profile/link-pages-account/settings-page/change-password/ChangePassword'

const ChangePasswordPage = () => {
    return (
        <div id='change-password-page'>
            <ChangePassword />
        </div>
    )
}

export default ChangePasswordPage
