

import React, { useEffect, useState } from 'react'
import DisabilitySituations from '../../../../../../components/user/profile/link-pages/health-profile/disability-situations/DisabilitySituations'
import { getDisabilitySituations } from '../../../../../../api/user-services/healt-info-services'

const DisabilitySituationsPage = () => {

    const [data, setData] = useState()


    const loadData = async () => {
        try {
            const resp = await getDisabilitySituations()
            setData(resp.data)
        } catch (error) {
            console.log("disability situations cekilemedi");
        }
    }


    useEffect(() => {
        loadData()
    }, [])

    console.log(data);
    return (
        <div>
            <DisabilitySituations data={data} />
        </div>
    )
}

export default DisabilitySituationsPage
