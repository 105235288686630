import React, { useEffect, useState } from 'react'
import SleepForm from '../../../../../../components/user/profile/link-pages/health-history/habits/sleep-form/SleepForm'
import { getSleepHabit } from '../../../../../../api/user-services/healt-info-services'

const SleepPage = () => {


    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const rsp = await getSleepHabit()
            setData(rsp.data)
        } catch (error) {
            console.log("sleep habit cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    console.log(data);

    return (
        <div>
            <SleepForm data={data} />
        </div>
    )
}

export default SleepPage
