import React from 'react'
import PaymentContainer from '../../../components/containers/payment/PaymentContainer'

const PaymentPage = () => {
    return (
        <div id='payment-page'>
            <PaymentContainer />
        </div>
    )
}

export default PaymentPage
