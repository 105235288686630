import React, { useEffect, useState } from 'react'
import "./allergies.scss"
import NoRecord from '../../../../../no-record/NoRecord'
import { Link } from 'react-router-dom'
import { getAllergies } from '../../../../../../api/user-services/healt-info-services'
const Allergies = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getAllergies()
            setData(resp.data)
        } catch (error) {
            console.log("allergies cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div id='allergies' className='box-style'>

            {
                (data && data?.length > 0) ? (
                    data?.map((item) => (
                        <div className="allergies" key={item.uuid}>
                            <div>
                                <span>Alergen</span>
                                <p>{item?.allergen}</p>
                            </div>
                            <div>
                                <span>Symptomps</span>
                                <p>{item?.symptomps || "This part was not filled in."}</p>
                            </div>
                            <div>
                                <span>Severity</span>
                                <p>{item?.severity}</p>
                            </div>
                        </div>
                    ))
                ) : (<NoRecord title={"Allergies"} />)
            }






            <Link to="add-allergies" className='button'>
                Add Allergies
            </Link>
        </div>
    )
}

export default Allergies
