import React, { useState } from 'react'
import "./password-form.scss"
import { Button, Form } from 'react-bootstrap'
import { generateOTP, resetPasswordServ } from '../../../api/user-services/user-services';
import countriesCode from "../../../countries/countriesCode.json"
import { swalToast } from '../../../swalMessages/swalMessages';
import Loading from '../../loading/Loading';
const PasswordForm = ({ setIsLoginClicked, isForgotPasswordClicked, setIsForgotPasswordClicked }) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [isOTPcodeEntered, setIsOTPCodeEntered] = useState(false)
    const [userFullPhoneNumber, setUserFullPhoneNumber] = useState("")
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false)
    //
    const [isPhoneEnter, setIsPhoneEnter] = useState(false)
    // const [isCodeEnter, setIsCodeEnter] = useState(false)


    //GENERATEB OTP

    const [signUpOtp, setSignUpOtp] = useState(
        {
            id: "",
            phoneNumber: "",
            email: "",
            username: "",
            otp: "",
            status: ""
        }
    )

    const handleSubmitOTP = async (e) => {
        e.preventDefault()
        setLoading(true)
        const fullPhoneNumber = `${countryCode}${phoneNumber}`
        setUserFullPhoneNumber(fullPhoneNumber)
        try {
            await generateOTP({ ...signUpOtp, phoneNumber: fullPhoneNumber }, fullPhoneNumber)
            setCountryCode("")
            setPhoneNumber("")
            setLoading(false)
            setIsPhoneEnter(true)
            swalToast("OTP sent successfully!", "Please check your phone", "success")
        } catch (error) {
            console.log("hata aldin");
            swalToast("Error!", "An unexpected error occurred.", "error")
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    //GENERATE OTP

    //VERIFY OTP
    // const [verifyOTP, setVerifyOTP] = useState({

    //     id: "string",
    //     phoneNumber: "string",
    //     email: "string",
    //     username: "string",
    //     otp: "",
    //     status: "string"

    // })

    // console.log(userFullPhoneNumber + " userfullphonenumber");
    // const handleVerifyOTP = async (e) => {
    //     e.preventDefault()
    //     setLoading(true)
    //     console.log(userFullPhoneNumber + " userfullphonenumber verify submit icindeki");
    //     try {
    //         const resp = await verifyOTPService({ ...verifyOTP, phoneNumber: userFullPhoneNumber }, userFullPhoneNumber)
    //         console.log(resp.data);
    //         console.log("basarili");
    //         setIsOTPCodeEntered(true)
    //         setIsCodeEnter(true)
    //         setLoading(false)
    //         swalToast("OTP verified successfully!", "Now you can reset your password", "success")
    //     } catch (error) {
    //         console.log("verifydan hata aldin");
    //         setLoading(false)
    //         swalToast("Invalid OTP!", "Please enter correct OTP", "error")
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    //VERIFY OTP


    // HANDLE RESET PASSWORD

    const [resetPassword, setResetPassword] = useState({
        username: "",
        newpassword: "",
        phonenumber: "",
        otp: "",
        confirmpassword: ""
    })

    const handleResetPassword = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

        }
        setValidated(true);

        if (resetPassword.newpassword !== resetPassword.confirmpassword) {
            swalToast("Passwords do not match!", "Please re-enter your password.", "warning")
            return
        }

        if (resetPassword.newpassword.length !== 6 || isNaN(resetPassword.newpassword) || !/^\d+$/.test(resetPassword.newpassword)) {
            setIsPasswordInvalid(true)
            swalToast("Check Again!", "Password must be 6 digits and contain only numbers. It should not include special symbols.", "warning")
            return;
        }

        if (resetPassword.username === "" || resetPassword.otp === "") {
            swalToast("", "Username and OTP information cannot be empty.", "warning")
            return
        }

        try {
            const resp = await resetPasswordServ({ ...resetPassword, phonenumber: userFullPhoneNumber })
            console.log(resp.data);
            swalToast("Password changed successfully!", "Now you can login", "success")
            setPhoneNumber("");
            setCountryCode("");
            setUserFullPhoneNumber("");
            setIsPhoneEnter(false)
            setIsForgotPasswordClicked(false)
            setIsLoginClicked(true)
            setResetPassword({
                username: "",
                newpassword: "",
                phonenumber: "",
                otp: "",
                confirmpassword: ""
            })

        } catch (error) {
            //swalToast("Wait!", "An unexpected error occurred.", "error")

        }

    }


    // HANDLE RESET PASSWORD


    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);

        const selectedCountryInfo = countriesCode.find(country => country.dial_code === e.target.value);
        setSelectedCountry(selectedCountryInfo);
    };

    const handlePhoneNumber = (e) => {
        const number = e.target.value
        setPhoneNumber(number)
    }


    const passwordInfo = (e) => {
        const { name, value } = e.target
        setResetPassword({ ...resetPassword, [name]: value })
    }



    return (
        <div id='password-form' className={!isForgotPasswordClicked ? "d-none" : "d-block"}>



            <div className={isPhoneEnter ? "d-none" : "d-block"}>
                <Form noValidate validated={validated} onSubmit={handleSubmitOTP} >

                    <div className='enter-phone'>
                        <Form.Group>
                            <Form.Label>Mobile Phone</Form.Label>
                            <br />
                            <div className='d-flex align-items-center gap-2 mb-3 login-phone-info '>
                                <Form.Select
                                    className='w-50'
                                    name='countryCode'
                                    onChange={handleCountryCodeChange}
                                    value={countryCode}
                                    required
                                >
                                    <option>Select Country Code</option>
                                    {
                                        countriesCode.map((countryDialCode, i) => (
                                            <option key={i} value={countryDialCode.dial_code}>{countryDialCode.dial_code} {countryDialCode.flag}</option>
                                        ))
                                    }
                                </Form.Select>
                                <Form.Control
                                    className='mb-0'
                                    type='text'
                                    placeholder='Phone Number*'
                                    name='phoneNumber'
                                    value={phoneNumber}
                                    onChange={handlePhoneNumber}
                                    required
                                />
                                <div className='country-flag'>{selectedCountry && selectedCountry.flag}</div>
                            </div>
                        </Form.Group>
                        <Button type='submit' className='button'>
                            {
                                loading ? <div className='d-flex align-items-center gap-3'><Loading /> <span>Code Sending...</span></div> : "Next"
                            }
                        </Button>
                    </div>
                </Form>
            </div>

            {
                isPhoneEnter && <Form noValidate validated={validated} onSubmit={handleResetPassword}>
                    <div className='verify-phone'>
                        <Form.Group>
                            <Form.Label>Mobile Phone Verification</Form.Label>
                            <br />
                            <span>Please enter username</span>
                            <Form.Control
                                type="text"
                                name="username"
                                value={resetPassword.username}
                                onChange={(e) => passwordInfo(e)}
                                placeholder="Enter username"
                                required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Mobile Phone Verification</Form.Label>
                            <br />
                            <span>Please enter the verification code sent to your phone.</span>
                            <Form.Control
                                type="text"
                                name="otp"
                                value={resetPassword.otp}
                                onChange={(e) => passwordInfo(e)}
                                placeholder="Code"
                                required />

                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Reset Password</Form.Label>
                            <br />
                            <span>Password must be 6 digits and contain only numbers.</span>
                            <Form.Control
                                type="password"
                                name="newpassword"
                                value={resetPassword.newpassword}
                                onChange={(e) => passwordInfo(e)}
                                placeholder="New Password"
                                required
                            />

                            <span>Please confirm password</span>
                            <Form.Control
                                type="password"
                                name="confirmpassword"
                                value={resetPassword.confirmpassword}
                                onChange={(e) => passwordInfo(e)}
                                placeholder="Confirm Password"
                                required
                            />
                        </Form.Group>
                        <Button type='submit' className='button'>Verify</Button>
                    </div>
                </Form>
            }

        </div>
    )
}

export default PasswordForm



