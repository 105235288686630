import React from 'react'
import PaymentHistory from '../../../../../components/user/profile/link-pages-account/payment-history/PaymentHistory'

const PaymentHistoryPage = () => {
    return (
        <div id='payment-history-page'>
            <PaymentHistory />
        </div>
    )
}

export default PaymentHistoryPage
