import React, { useState } from 'react'
import "./white-paper-detail.scss"
import { useNavigate, useParams } from 'react-router-dom'
const PackageWhitePaperDetail = () => {

    const { id } = useParams()
    const navigate = useNavigate()


    const buyPlan = (planName) => {
        if (planName === "advanced-plan") {
            navigate("/user/packages-detail/advanced-plan/buy-advanced-plan")
        }
        if (planName === "main-plan") {
            navigate("/user/packages-detail/main-plan/buy-main-plan")
        }
    }




    return (
        <div id='package-white-paper-main-plan'>

            {
                id === "main-plan" && <>
                    <div className="pack-image">
                        <img src={require("../../../assets/payment/p1.jpeg")} alt="" />
                    </div>
                    <button className='button' onClick={() => buyPlan(id)}>BUY PLAN - <span className='package-price'>$199</span></button>
                    <div className="pack-info">
                        <span>Main Plan</span>
                        <div className='pack-info-text' >
                            <h4 >Basic Package Description</h4>
                            <h5>Basic Package Contents:</h5>
                            <p>Our basic package is designed to provide you with a comprehensive evaluation of your medical reports and facilitate obtaining a second opinion regarding your diagnosis. The contents of the package are as follows:</p>
                            <h6>Report Evaluation:</h6>
                            <p>Your diagnosis reports are meticulously reviewed by three highly successful specialist doctors in their respective fields in Turkey, along with their teams. Each doctor carefully evaluates your reports and provides personalized comments.</p>
                            <h6>Expert Opinions:</h6>
                            <p>Detailed evaluations and diagnostic comments from three different doctors are presented to you in written form and, if necessary, accompanied by visual materials.</p>
                            <h6>Second Opinion:</h6>
                            <p>You are given the opportunity to have a one-on-one consultation with one of the three doctors whose evaluations you prefer. This consultation is aimed at confirming the accuracy of your diagnosis and addressing any questions you may have about your treatment process.</p>

                            <h5>Benefits and Value of the Package:</h5>

                            <h6>1. Opportunity to Get a Second Opinion:</h6>
                            <p><span>Provides Assurance:</span> By verifying the accuracy of your diagnosis with different experts, you can proceed with your treatment process with confidence.</p>
                            <p><span>Treatment Strategy: </span> Obtaining a second opinion helps you make more informed and secure decisions about your treatment options.</p>

                            <h6>2. Expert Opinions:</h6>
                            <p><span>Quality Evaluation:</span> Detailed examinations by successful doctors in their fields ensure the most accurate assessment of your diagnosis.</p>
                            <p><span>Personalized Comments:</span> Each doctor’s personalized comments help you understand the unique aspects of your condition and treatment options.</p>

                            <h6>3. Information and Education:</h6>
                            <p><span>Education: </span> By gaining more information about your condition, you can take an active role in your treatment process.</p>
                            <p><span>Personalized Comments:</span> The provided evaluation reports and second opinion offer in-depth knowledge about your condition and treatment options.</p>

                            <h6>4. Reducing Stress and Anxiety:</h6>
                            <p><span>Relief: </span> Common views from different specialists reduce uncertainties about your condition and boost your morale.</p>
                            <p><span>Support:</span> The second opinion consultation with the chosen doctor allows you to get answers to your questions and alleviates your concerns.</p>

                            <h6>5. Security and Privacy:</h6>
                            <p><span>Data Protection: </span> All your medical information is processed and protected according to privacy and security standards.</p>
                            <p><span>Reliable Service: </span> Evaluations by specialist doctors ensure you receive accurate and reliable information.</p>
                        </div>
                        <button className='button' onClick={() => buyPlan(id)}>BUY PLAN  -  <span className='package-price'>$199</span></button>
                    </div>
                </>

            }

            {
                id === "advanced-plan" && <>
                    <div className="pack-image">
                        <img src={require("../../../assets/payment/p2.jpeg")} alt="" />
                    </div>
                    <button className='button' onClick={() => buyPlan(id)}>BUY PLAN - <span className='package-price'>$299</span></button>
                    <div className="pack-info">
                        <span>Advanced Plan</span>
                        <div className='pack-info-text'>
                            <h4>Advanced Package Description</h4>
                            <h5>Advanced Package Contents:</h5>
                            <p>Our advanced package offers comprehensive services to best support the diagnosis and treatment processes of oncology patients. This package, which is more extensive than the basic package, provides our patients with more expert evaluations, personalized treatment plans, and continuous support. The contents of the package are as follows:</p>

                            <h6>Comprehensive Report Evaluation:</h6>
                            <p>Your diagnosis reports are thoroughly examined by five expert doctors and their teams, who are recognized both in Turkey and internationally.</p>
                            <p>Each doctor carefully evaluates your reports and provides personalized comments and treatment recommendations.</p>

                            <h6>Expert Opinions and Recommendations:</h6>
                            <p>Comprehensive evaluations and diagnostic comments from five different doctors are presented to you through written reports and visual materials.</p>
                            <p>Experts provide detailed recommendations not only related to the diagnosis but also concerning treatment options, lifestyle suggestions, and disease management.</p>

                            <h6>Second Opinion and Treatment Planning:</h6>
                            <p>You have the opportunity to have one-on-one consultations with two doctors of your choice from the five evaluations.</p>
                            <p>During these consultations, you receive comprehensive information to confirm the accuracy of your diagnosis, plan your treatment process, and address all your questions.</p>

                            <h6>Continuous Support and Follow-Up:</h6>
                            <p>One of the doctors you choose will provide regular consultation and support throughout your treatment process.</p>
                            <p>Your treatment progress is monitored with monthly online consultations, and adjustments to the treatment plan are made as necessary.</p>

                            <h6>Emergency Consultation Service:</h6>
                            <p>An expert support line available 24/7 is provided for any sudden health issues or urgent consultation needs.</p>

                            <h6>Educational and Informational Resources:</h6>
                            <p>You gain access to continuously updated educational materials about your disease, treatment methods, and lifestyle recommendations.</p>
                            <p>You can keep your knowledge up-to-date with webinars, educational videos, and interactive content.</p>

                            <h5>Benefits and Value of the Package:</h5>

                            <h6>1. Multi-Faceted Expert Evaluation:</h6>
                            <p><span>Increases Confidence:</span>Evaluations from different experts highly secure the accuracy of your diagnosis.</p>
                            <p><span>Treatment Strategy: </span> Recommendations from multiple experts help you determine the most suitable treatment strategy.</p>

                            <h6>2. Personalized Treatment Plan:</h6>
                            <p><span>Customized Care: </span> Each patient’s condition is unique; therefore, personalized treatment plans ensure the best outcomes.</p>
                            <p><span>Quality of Life: </span> Lifestyle recommendations and advice on disease management improve your quality of life.</p>

                            <h6>3. Continuous Support and Follow-Up:</h6>
                            <p><span>Long-Term Support: </span> Ongoing support throughout your treatment process ensures quick intervention in case of any setbacks.
                            </p>
                            <p><span>Progress Monitoring:</span> Regular check-ups and follow-up consultations enhance the effectiveness of the treatment process and detect potential issues early..</p>

                            <h6>4. Emergency Consultation Service:</h6>
                            <p><span>Immediate Response:  </span> Expert support for sudden health issues provides quick and effective solutions.</p>
                            <p><span>Peace of Mind: </span> The 24/7 accessible support line offers reassurance, knowing that an expert is available in emergencies.</p>

                            <h6>5. Education and Information:</h6>
                            <p><span>Informed Decisions: </span>  Educational materials and resources enable you to make informed decisions about your disease and treatment options.</p>
                            <p><span>Up-to-Date Information: </span> With continuously updated information and webinars, you stay informed about the latest treatment methods and research.</p>
                        </div>
                        <button className='button' onClick={() => buyPlan(id)}>BUY PLAN - <span className='package-price'>$299</span></button>
                    </div>
                </>
            }






        </div>
    )
}

export default PackageWhitePaperDetail
