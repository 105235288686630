import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMicOn: true,
    isCamOn: true

}


export const mediaControllerSlice = createSlice({
    name: "media",
    initialState,
    reducers: {
        setIsMicOn: (state) => {
            state.isMicOn = !state.isMicOn;
        },
        setIsCamOn: (state) => {
            state.isCamOn = !state.isCamOn;
        }
    }
})

export const { setIsMicOn, setIsCamOn } = mediaControllerSlice.actions;
export default mediaControllerSlice.reducer;