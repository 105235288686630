import React, { useState } from 'react'
import "./sign-up-main.scss"
import { Col, Container, Row } from 'react-bootstrap'
import { GrClose } from "react-icons/gr"
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../main-page/common/logo/Logo'
import SignUpForm from '../sign-up-form/SignUpForm'
import { hideModalSignUp } from '../../../store/sign-up/signupSlice'
const SignUpMain = ({ isSignUpClicked, setIsSignUpClicked, isLoginClicked, setIsLoginClicked }) => {
    const [isPhoneEntered, setIsPhoneEntered] = useState(false)
    const isSignUpModal = useSelector(state => state.signUpModal.isSignUpModal);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(hideModalSignUp())
        setIsPhoneEntered(false)
        setIsSignUpClicked(false)
    }


    return (
        <>
            {
                (!isLoginClicked || isSignUpClicked) && (
                    <div id='sign-up-main' style={{ display: isSignUpClicked || isSignUpModal ? "flex" : "none" }}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="left">
                                        <div>
                                            <Logo />
                                            {/* <div className="line"></div>
                                <h6>Şimdi Al Sonra Öde</h6> */}
                                        </div>
                                        <div className="login-form">
                                            <SignUpForm
                                                isPhoneEntered={isPhoneEntered}
                                                setIsPhoneEntered={setIsPhoneEntered}
                                                isLoginClicked={isLoginClicked}
                                                setIsLoginClicked={setIsLoginClicked}
                                                setIsSignUpClicked={setIsSignUpClicked}
                                                isSignUpClicked={isSignUpClicked}
                                            />
                                        </div>
                                        <div>

                                            <GrClose onClick={closeModal} />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="right">
                                        <img src="" alt="" />
                                        <GrClose onClick={closeModal} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )
            }
        </>
    )
}

export default SignUpMain
