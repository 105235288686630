import React, { useEffect, useState } from 'react'
import OtherResults from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/other-results/OtherResults'
import { getOtherResults } from '../../../../../api/user-services/healt-info-services'

const OtherResultsPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getOtherResults()
            setData(resp.data)
        } catch (error) {
            console.log("other cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);

    return (
        <div>
            <OtherResults data={data} />
        </div>
    )
}

export default OtherResultsPage
