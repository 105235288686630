import React from 'react'
import { Form } from 'react-bootstrap'

const ChangePassword = () => {
    return (
        <div id='change-password' className='box-style habits-styles'>
            <Form>
                <Form.Group>
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control type='text' />
                </Form.Group>
                <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type='text' />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type='text' />
                </Form.Group>
                <button className="button">Change Password</button>
            </Form>
        </div>
    )
}

export default ChangePassword
