import React, { useEffect, useState } from 'react'
import CardiologyResults from '../../../../../components/user/profile/link-pages/health-profile/laboratory-documents/cardiology-results/CardiologyResults'
import { getCardiologyResults } from '../../../../../api/user-services/healt-info-services'

const CardiologyResultsPage = () => {

    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getCardiologyResults()
            setData(resp.data)
        } catch (error) {
            console.log("radiology cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    console.log(data);


    return (
        <div>
            <CardiologyResults data={data} />
        </div>
    )
}

export default CardiologyResultsPage
