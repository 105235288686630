import React from 'react'

const TransactionGuide = () => {
    return (
        <div id='transaction-guide' className='box-style'>

        </div>
    )
}

export default TransactionGuide
