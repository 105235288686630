import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { swalToast } from '../../../../../../../swalMessages/swalMessages'
import { useNavigate } from 'react-router-dom'
import { getAllergies, postAllergies } from '../../../../../../../api/user-services/healt-info-services'

const AddAllergies = () => {

    const navigate = useNavigate()
    const [info, setInfo] = useState(
        {
            uuid: "",
            allergen: "",
            symptomps: "",
            severity: "",
            username: JSON.parse(sessionStorage.getItem("user"))
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target
        setInfo({ ...info, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await postAllergies(info)
            swalToast("Saved", "success")
            navigate(-1)
        } catch (error) {
            swalToast("Opps..", "An unexpected error occurred", "warning")
        }
    }



    const [data, setData] = useState()

    const loadData = async () => {
        try {
            const resp = await getAllergies()
            setData(resp.data)
        } catch (error) {
            console.log("allergies cekilemedi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])



    return (
        <div id='add-allergies' className='box-style habits-styles'>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Allergen</Form.Label>
                    <Form.Control
                        type='text'
                        name='allergen'
                        onChange={handleChange}
                        placeholder={data?.allergen || "type a allergen"}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Symptoms</Form.Label>
                    <Form.Control
                        type='text'
                        name='symptomps'
                        onChange={handleChange}
                        placeholder={data?.symptomps || "type a symptomps"}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Severity</Form.Label>
                    <Form.Select
                        name='severity'
                        onChange={handleChange}
                    >
                        <option value="">{data?.severity || "choose severity"}</option>
                        <option value="none">none</option>
                        <option value="Low impact">Low impact</option>
                        <option value="Moderate impact">Moderate impact</option>
                        <option value="High impact">High impact</option>
                        <option value="Critical impact">Critical impact</option>
                    </Form.Select>
                </Form.Group>

                <Button className='button' type='submit'>Save</Button>
            </Form>
        </div>
    )
}

export default AddAllergies
