import React from 'react'
import "./discover-content-card.scss"

import { Link, useParams } from 'react-router-dom';

//icons
import { BsCameraReelsFill } from "react-icons/bs";
import { FaBook } from "react-icons/fa6";
import { SiGooglepodcasts } from "react-icons/si";

const DiscoverContentCard = ({ content }) => {

    const id = useParams().id




    return (
        <div id='discover-content-card'>
            <h5>{content?.title}</h5>

            <div className='content-info'>
                {
                    content?.textUrl && (
                        <>
                            <FaBook />
                            <span>Read - 30 minute</span>
                        </>
                    )
                }
                {
                    content?.videoUrl && (
                        <>
                            <BsCameraReelsFill />
                            <span>Watch - 30 minute</span>
                        </>
                    )

                }
                {
                    content?.podcastUrl && (
                        <>
                            <SiGooglepodcasts />
                            <span>Listen - 30 minute</span>
                        </>
                    )
                }

            </div>

            <div className="content">
                <p>{content?.description}</p>
            </div>

            <Link to={`/user/discover/${id}/full/${content?.uuid}`} className='button mt-5'>
                {
                    content?.textUrl && (
                        <>
                            <span>Read</span>
                        </>
                    )
                }
                {
                    content?.videoUrl && (
                        <>
                            <span>Watch</span>
                        </>
                    )

                }
                {
                    content?.podcastUrl && (
                        <>
                            <span>Listen</span>
                        </>
                    )
                }
            </Link>
        </div>
    )
}

export default DiscoverContentCard
