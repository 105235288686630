import React from 'react'
import Habits from '../../../../../../components/user/profile/link-pages/health-history/habits/Habits'

const HabitsPage = () => {
    return (
        <div>
            <Habits />
        </div>
    )
}

export default HabitsPage
