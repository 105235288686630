import React, { useEffect, useState } from 'react'
import "./package-report-form.scss"
import { Form } from 'react-bootstrap'
import { PiSealWarningThin, PiUploadSimple } from 'react-icons/pi'
import Loading from '../../../loading/Loading'
import { TiTick } from 'react-icons/ti'
import { MdDeleteOutline, MdFileDownload } from 'react-icons/md'
import { getReportType } from '../../../../api/user-services/appointment-services'
import { cardiologyImage, laboratoryImage, medicalImage, medicineImage, otherResultImage, prescriptionImage, radiologyImage } from '../../../../api/user-services/laboratuary-services';
import { formatDate } from '../../../../utils/formatDate'

const PackageReportForm = ({ packageReportForm, setPackageReportForm }) => {

    const [reportTypes, setReportTypes] = useState([])
    const [selectedReportType, setSelectedReportType] = useState(null);
    const [isPurposeEntered, setIsPurposeEntered] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isImageUploaded, setImageUploaded] = useState(false)
    const [images, setImages] = useState([]);
    const [reportuuid, setreportuuid] = useState([]);
    const username = JSON.parse(sessionStorage.getItem("user"));



    const handleChange = (e) => {
        const { name, value } = e.target;

        const formattedValue = name === "date" ? formatDate(value) : value;

        setPackageReportForm(prevPackageReportForm => ({
            ...prevPackageReportForm,
            [name]: formattedValue
        }));

        if (name === 'serviceType') {
            setSelectedReportType(value);
        }

    }

    const loadData = async () => {
        try {
            const resp = await getReportType();
            setReportTypes(resp.data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpload = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;
        input.onchange = async (e) => {
            const selectedFiles = e.target.files;
            if (selectedFiles.length > 0) {
                const fileNames = Array.from(selectedFiles).map(file => file.name);
                setImages(prevImages => [...prevImages, ...selectedFiles]);
                setLoading(true)
                try {
                    await handleSubmit(selectedFiles);
                    setLoading(false)
                } catch (error) {
                    console.log("hata aldin");
                    setLoading(false)
                }
            }
        };
        input.click();
    };

    const handleDelete = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const handleDownload = (file) => {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
    };

    console.log(reportuuid);

    useEffect(() => {
        loadData()
    }, [])

    const handleSubmit = async (selectedFiles) => {
        setLoading(true);
        for (const image of selectedFiles) {
            const imagename = selectedReportType;
            const formData = new FormData();
            formData.append("file", image);
            switch (selectedReportType) {
                case 'radiology':
                    await radiologyImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading radiology image:`, error));
                    break;
                case 'medical':
                    await medicalImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading medical image:`, error));
                    break;
                case 'laboratory':
                    await laboratoryImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading laboratory image:`, error));
                    break;
                case 'cardiology':
                    await cardiologyImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading cardiology image:`, error));
                    break;
                case 'medicine':
                    await medicineImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading cardiology image:`, error));
                    break;
                case 'prescription':
                    await prescriptionImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading cardiology image:`, error));
                    break;
                case 'other':
                    await otherResultImage(formData, imagename, username)
                        .then(response => setreportuuid(prevState => [...prevState, response?.data?.message?.split(":")[1]]))
                        .catch(error => console.error(`Error uploading other result image:`, error));
                    break;
                default:
                    console.error(`Unknown report type:`, selectedReportType);
            }
        }
        setLoading(false);
    };

    const handlePurpose = (e) => {
        setPackageReportForm({
            ...packageReportForm,
            appointmentPurpose: e.target.value
        });
        setIsPurposeEntered(true)
    };




    useEffect(() => {
        setPackageReportForm(prevpackageReportForm => ({
            ...prevpackageReportForm,
            reportuuidlist: reportuuid
        }));

    }, [reportuuid])

    return (
        <div id='package-report-form'>
            <div id='meeting-details' className='box-style'>
                <div className="top">
                    <h6>Healthcare Professional Note</h6>
                    <div className='text-box'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. At impedit odit nemo excepturi earum possimus corporis! Doloribus ea eligendi doloremque. Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam culpa mollitia quibusdam, neque debitis error unde odit repudiandae necessitatibus molestiae.
                    </div>
                </div>
                <div className="top">
                    <h6>Appointment Purpose</h6>
                    {
                        isPurposeEntered === false || packageReportForm.appointmentPurpose === "" ? <span className="warning-purpose">Please enter the appointment purpose before continuing!</span> : null
                    }
                    <Form className='text-box'>
                        <Form.Control as={"textarea"} type='text' placeholder='Write here appointment purposes' rows={3} style={{ minHeight: "150px", maxHeight: "150px" }} onChange={handlePurpose} />
                    </Form>
                    <div className="info my-3">
                        <PiSealWarningThin />
                        <span>If available, please upload the report results.</span>
                    </div>
                    <div className="service-type box-style">
                        <span>Report Type</span>
                        <Form className='d-flex flex-column gap-4'>
                            <Form.Group>
                                <Form.Select name="serviceType" onChange={handleChange}>
                                    <option value="">Chose Report Type</option>
                                    {reportTypes && reportTypes?.map((item) => (
                                        <option key={item.id} value={item.type}>
                                            {item.type || ""}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Control
                                    type='date'
                                    name='date'
                                    onChange={handleChange}
                                />
                            </Form.Group> */}
                        </Form>
                    </div>
                    <div className="top-btn">
                        <div onClick={handleUpload}>
                            <PiUploadSimple />
                            {
                                loading ? <span className='image-uploading'>Image Uploading...</span> : <span>Upload</span>
                            }
                        </div>
                    </div>
                    <div className="uploaded-images">
                        {images.length > 0 && (
                            <div className='reports-wrapper'>
                                {images.map((file, index) => (
                                    <div key={index}>
                                        <img src={URL.createObjectURL(file)} alt={`Uploaded ${index}`} />
                                        <span>{file.name}</span>
                                        <div className="icons">
                                            {
                                                loading ? <Loading size={"sm"} /> : <TiTick />
                                            }
                                            <MdFileDownload onClick={() => handleDownload(file)} />
                                            <MdDeleteOutline onClick={() => handleDelete(index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageReportForm

