import React from 'react';
import "./categories.scss";
import CategoryCard from '../../user/category-card/CategoryCard';
import { Link } from 'react-router-dom';

const Categories = ({ data }) => {
    const shownTitles = new Set();

    return (
        <div id='categories'>
            <span>Popular Branches</span>
            <div className="categories-content">
                {data?.map((category) => {
                    if (!shownTitles.has(category?.title)) {
                        shownTitles.add(category?.title);
                        return (
                            <Link to={`search/${category?.title}`} key={category?.uuid}>
                                <CategoryCard category={category} />
                            </Link>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

export default Categories;
