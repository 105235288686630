import React, { useEffect, useState } from 'react'
import NutritionForm from '../../../../../../components/user/profile/link-pages/health-history/habits/nutrition-form/NutritionForm'
import { getNutrition, getTabaccoAlcholDrugs } from '../../../../../../api/user-services/healt-info-services'

const NutritionPage = () => {

    const [data, setData] = useState()


    const loadData = async () => {
        try {
            const resp = await getNutrition()
            setData(resp.data)
        } catch (error) {
            console.log("nutrition data hata verdi");
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    console.log(data);


    return (
        <div>
            <NutritionForm data={data} />
        </div>
    )
}

export default NutritionPage
