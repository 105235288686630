import axios from "axios";
import authHeader from "../../helpers/functions/authHeader";
const API_URL = process.env.REACT_APP_API_URL;

export const listAgoraAdminRooms = (payload) => {
    return axios.get(`${API_URL}agoralive/availableAgoraLiveRoomsForDoctor?doctorName=${payload}`);
}

export const generateAgoraTokenForAdmin = (params, payload) => {
    return axios.post(`${API_URL}agoratoken/generate?doctorname=${params.doctorname}&userName=${params.userName}`, payload);

}

export const retrieveUserInfos = (payload) => {
    return axios.get(`${API_URL}users/all?userName=${payload}`);
}


// agora avaible live room
export const AgoraAvaibleLiveRoom = (doctorname, username) => {
    return axios.get(`${API_URL}agoralive/availableAgoraLiveRooms?doctorName=${doctorname}&userName=${username}`);
}

//generate token for user
export const generateAgoraTokenForUser = (doctorname, username, payload) => {
    return axios.post(`${API_URL}agoratoken/generate?doctorname=${doctorname}&userName=${username}`, payload);

}