import React from 'react'
import MessageList from '../../../components/containers/message/MessageList'

const MessagePage = () => {
    return (
        <div>
            <MessageList />
        </div>
    )
}

export default MessagePage
