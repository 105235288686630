import React from 'react'
import CreditOptions from '../../../../components/user/profile/credit-options/CreditOptions'

const CreditOptionsPage = () => {
    return (
        <div>
            <CreditOptions />
        </div>
    )
}

export default CreditOptionsPage
