import React from 'react'
import "./worldwide-opinions.scss"
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { showModalSignUp } from '../../../../store/sign-up/signupSlice'
const WorldwideOpinions = ({ title1, title2, text1, text2, text3, image }) => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isUserLogin = useSelector(state => state.auth.isAuthenticated)

    const handleSignIn = () => {

        isUserLogin ? navigate("/user") : dispatch(showModalSignUp())



    }

    return (
        <Container>
            <div id='worldwide-opinions'>
                <div className="top">
                    <h3>{title1} <br />
                        <span className='title-2'>{title2}</span>
                    </h3>
                    <p className='p-sm'>{text1}</p>
                    <p className='p-sm'>{text2}</p>
                    <p className='p-sm'>{text3}</p>
                </div>
                <div className="worldwide-image">
                    <img src={image} alt="" />
                </div>
                <button className="landing-btn-lg hover-dark" onClick={handleSignIn}>Join Now</button>
            </div>
        </Container>
    )
}

export default WorldwideOpinions
