import React from 'react'
import "./common-health-card.scss"

import { IoChevronForwardSharp } from "react-icons/io5";
const CommonHealthCard = ({ item }) => {
    return (
        <div id='common-health-card'>
            <div className="top">
                <img src={require(`../../../../assets/health-concern-img/${item.imgUrl}`)} alt="" />
            </div>
            <div className="card-info">
                <span className="title">{item.title}</span>
                <div className='prices'>
                    <img className='price-bg' src={require("../../../../assets/health-concern-img/price-bg.png")} alt="" />
                    <div className="old-price">${item.oldPrice}</div>
                    <div className='price'>${item.currentPrice} </div>
                </div>
                <div className='btn-consult'>consult now  <IoChevronForwardSharp /></div>
            </div>
        </div>
    )
}

export default CommonHealthCard
