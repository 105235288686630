import React from 'react'
import "./description-card.scss"

const DescriptionCard = (props) => {

    const { title, text1, text2, icon: IconComponent } = props

    return (
        <div id='description-card'>
            <div className="top">
                <div className="icons-svg">
                    {IconComponent && <img src={IconComponent} alt="icon" className="icon icon-svg" />}
                </div>
                <h4>{title}</h4>
            </div>
            <div className="bottom">
                <p className='p-sm'>{text1}</p>
                <p className='p-sm'>{text2} </p>
            </div>
        </div>
    )
}

export default DescriptionCard
