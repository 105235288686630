import React, { useState } from 'react'
import "./profile-container.scss"
import BalanceCard from '../../user/profile/balance-card/BalanceCard'
import ProfileLinks from '../../user/profile/links/ProfileLinks'
import LogOut from '../../user/profile/link-pages-account/logout/LogOut'





const ProfileContainer = () => {

    const [isLogoutClicked, setIsLogoutClicked] = useState(false)

    console.log(isLogoutClicked);
    return (
        <div id='profile-container'>
            {
                isLogoutClicked && <div className="bg"></div>
            }
            {/* <BalanceCard /> */}
            <ProfileLinks setIsLogoutClicked={setIsLogoutClicked} />
            <LogOut isLogoutClicked={isLogoutClicked} setIsLogoutClicked={setIsLogoutClicked} />
        </div>
    )
}

export default ProfileContainer
