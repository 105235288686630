import React from 'react'
import SupportSSS from '../../../../../components/user/profile/link-pages-account/support/support-sss-main/SupportSSS'

const SupportSSSPage = () => {
    return (
        <div id='support-page'>
            <SupportSSS />
        </div>
    )
}

export default SupportSSSPage
