import React from 'react'
import MeetingDetailInfo from '../../../components/containers/meeting-main-page/meeting-detail-info/MeetingDetailInfo'

const MeetingPageDetail = () => {
    return (
        <div>
            <MeetingDetailInfo />
        </div>
    )
}

export default MeetingPageDetail
